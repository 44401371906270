import {FC, useContext, useState, useRef} from 'react';
import {observer} from "mobx-react-lite";

import {Context} from "../../index";
import { Link } from 'react-router-dom';
import { Button,  CardTitle, Col, Container, Form,  Row, Tab, Tabs } from 'react-bootstrap';
import LeagalForm1 from './legalCompanyForms/legalForm1';
import LeagalForm2 from './legalCompanyForms/legalForm2';
import LeagalForm3 from './legalCompanyForms/legalForm3';
import LeagalForm4 from './legalCompanyForms/legalForm4';
import Modal from 'react-bootstrap/Modal';
import envelope from "../../img/icons/Envelope.svg";
import OrgAccountSubMenu from './parts/OrgAccountSubMenu';

const AccountCompanyForm: FC = () => {
    const {store} = useContext(Context);
    const [validated, set_Validated] = useState(false);
    const [allFiles, setAllFiles] = useState(new Map());
    const [allPasportFiles, setallPasportFiles] = useState(new Map());
    const [allRegistrationFiles, setallRegistrationFiles] = useState(new Map());
    let stepActiveItem:string = 'step1'
    let step2ActiveBtn:boolean = true;
    let step3ActiveBtn:boolean = true;
    let step4ActiveBtn:boolean = true;
    const handleNameChange = (value:any, allFiles:any, allPasportFiles:any, allRegistrationFiles:any) => {
        set_Validated(value);
        setAllFiles(allFiles);
        setallPasportFiles(allPasportFiles);
        setallRegistrationFiles(allRegistrationFiles);
    }
    if (store.bank.email) {
        stepActiveItem = 'step1'
        step4ActiveBtn = false;
        step3ActiveBtn = false;
        step2ActiveBtn = false;
    } else if (store.owner.email) {
        stepActiveItem = 'step4'
        step4ActiveBtn = false;
        step3ActiveBtn = false;
        step2ActiveBtn = false;
    } else if (store.license.email) {
        stepActiveItem = 'step3'
        step3ActiveBtn = false;
        step2ActiveBtn = false;
    } else if (store.company.email) {
        stepActiveItem = 'step2'
        step2ActiveBtn = false;
    }

    const [key, setKey] = useState<any>(stepActiveItem);
    const [disableBtn2, setActive2] = useState<boolean>(step2ActiveBtn);
    const [disableBtn3, setActive3] = useState<boolean>(step3ActiveBtn);
    const [disableBtn4, setActive4] = useState<boolean>(step4ActiveBtn);

    const [show, setShow] = useState(false);
    const modalCompleateHendle = useRef<HTMLAnchorElement>(null);
    const handleClose = () => setShow(false);
    const handleShow = (e: any) => {e.preventDefault(); setShow(true)};

    const submitFn = (e: any) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            e.stopPropagation();
            const currentBankAccount = form.elements.currentBankAccount.value;
            const bankName = form.elements.bankName.value;
            const bankBIK = form.elements.bankBIK.value;
            const corrAccount = form.elements.corrAccount.value;
            const bankInnCode = form.elements.bankInnCode.value;
            const bankKPP = form.elements.bankKPP.value;
            const platformRole = form.elements.platformRole.value;
            const uploadGosReg = form.elements.uploadGosReg.files[0];
            const uploadFounderDoc = form.elements.uploadFounderDoc.files[0];
            const ownerMoney = form.elements.ownerMoney.checked;
            const mainJobMoney = form.elements.mainJobMoney.checked;
            const otherMoney = form.elements.otherMoney.checked;
            const otherMoneyInfo = form.elements.otherMoneyInfo.value;
            const otherIncomeMoney = form.elements.otherIncomeMoney.checked;
            const investorMoney = form.elements.investorMoney.checked;
            const gorvementMoney = form.elements.gorvementMoney.checked;

            const platformBuy = form.elements.platformBuy.checked;
            const platformRedeem = form.elements.platformRedeem.checked;
            const platformSellBuy = form.elements.platformSellBuy.checked;
            const platformChange = form.elements.platformChange.checked;
            const platformOther = form.elements.platformOther.checked;
            const platformGift = form.elements.platformGift.checked;
            const otherPlatformInfo = form.elements.otherPlatformInfo.value;

            const mainRoleIncome = form.elements.mainRoleIncome.checked;
            const mainRoleNotIncome = form.elements.mainRoleNotIncome.checked;
            const mainRoleProject = form.elements.mainRoleProject.checked;
            const mainRoleCharity = form.elements.mainRoleCharity.checked;

            const developPlatformRole = form.elements.developPlatformRole.checked;
            const traderPlatformRole = form.elements.traderPlatformRole.checked;
            const investerPlatformRole = form.elements.investerPlatformRole.checked;
            
            store.saveBank(store.user.email, store.user.id, currentBankAccount, bankName, bankBIK, corrAccount, bankInnCode, bankKPP, platformRole, uploadGosReg, uploadFounderDoc, ownerMoney, mainJobMoney, otherMoney, otherMoneyInfo, otherIncomeMoney, investorMoney, gorvementMoney, platformBuy, platformRedeem, platformSellBuy, platformChange, platformOther, platformGift, otherPlatformInfo, mainRoleIncome, mainRoleNotIncome, mainRoleProject, mainRoleCharity, developPlatformRole, traderPlatformRole, investerPlatformRole);
            if (modalCompleateHendle.current) {
                modalCompleateHendle.current.click();
            }
        }
        set_Validated(true);
        
    };

    const NextStep = (e: any) => {
        const form = e.currentTarget.closest('form');
        if (form.checkValidity() === true) {
            setKey(e.target.id);
            if (e.target.id === 'step2') {
                const organizationalLegalFor = (form.elements.organizationalLegalFor && form.elements.organizationalLegalFor.value) ?  form.elements.organizationalLegalFor.value : '';
                const fullCompanyName = (form.elements.fullCompanyName && form.elements.fullCompanyName.value) ?  form.elements.fullCompanyName.value : '';
                const fullCompanyNameEng = (form.elements.fullCompanyNameEng && form.elements.fullCompanyNameEng.value) ?  form.elements.fullCompanyNameEng.value : '';
                const shortCompanyNamEng = (form.elements.shortCompanyNamEng && form.elements.shortCompanyNamEng.value) ?  form.elements.shortCompanyNamEng.value : '';
                const abbreviation = (form.elements.abbreviation && form.elements.abbreviation.value) ?  form.elements.abbreviation.value : '';
                const kioName = (form.elements.kioName && form.elements.kioName.value) ?  form.elements.kioName.value : '';
                const regCountry = (form.elements.regCountry && form.elements.regCountry.value) ?  form.elements.regCountry.value : '';
                const codeForeignerStructure = (form.elements.codeForeignerStructure && form.elements.codeForeignerStructure.value) ?  form.elements.codeForeignerStructure.value : '';
                const gosRegNumber = (form.elements.gosRegNumber && form.elements.gosRegNumber.value) ?  form.elements.gosRegNumber.value : '';
                const numberRecordAcredetetion = (form.elements.numberRecordAcredetetion && form.elements.numberRecordAcredetetion.value) ?  form.elements.numberRecordAcredetetion.value : '';
                const regNumberWithoutLegal = (form.elements.regNumberWithoutLegal && form.elements.regNumberWithoutLegal.value) ?  form.elements.regNumberWithoutLegal.value : '';
                const isPlaceLegalTheSame = (form.elements.isPlaceLegalTheSame && form.elements.isPlaceLegalTheSame.value) ?  form.elements.isPlaceLegalTheSame.value : '';
                const placeOfTheLegal = (form.elements.placeOfTheLegal && form.elements.placeOfTheLegal.value) ?  form.elements.placeOfTheLegal.value : '';
                const shortCompanyName = form.elements.shortCompanyName.value;
                const companyPhone = form.elements.companyPhone.value;
                const companyEmail = form.elements.companyEmail.value;
                const companyWebSite = form.elements.companyWebSite.value;
                const ogrnName = (form.elements.ogrnName && form.elements.ogrnName.value) ?  form.elements.ogrnName.value : '';
                const innName = form.elements.innName.value;
                const uploadOgrn = form.elements.uploadOgrn.files[0];
                const uploadInn = form.elements.uploadInn.files[0];
                const kppName = (form.elements.kppName && form.elements.kppName.value) ?  form.elements.kppName.value : '';
                const okpoName = (form.elements.okpoName && form.elements.okpoName.value) ?  form.elements.okpoName.value : '';
                const okatoName = (form.elements.okatoName && form.elements.okatoName.value) ?  form.elements.okatoName.value : '';
                const okvedName = (form.elements.okvedName && form.elements.okvedName.value) ?  form.elements.okvedName.value : '';
                const regDate = form.elements.regDate.value;
                const nameRegAuthority = form.elements.nameRegAuthority.value;
                const gosRegRegion = form.elements.gosRegRegion.value;
                const gosRegCity = form.elements.gosRegCity.value;
                const gosRegStreet = form.elements.gosRegStreet.value;
                const gosRegHouse = form.elements.gosRegHouse.value;
                const gosRegCorpus = form.elements.gosRegCorpus.value;
                const gosRegApp = form.elements.gosRegApp.value;
                const gosPostalCode = form.elements.gosPostalCode.value;
                const isLegalAddSameAsGos = form.elements.isLegalAddSameAsGos.value;
                const isPostAddSameAsGos = form.elements.isPostAddSameAsGos.value;
                const legalRegRegion = form.elements.legalRegRegion.value;
                const legalRegCity = form.elements.legalRegCity.value;
                const postRegRegion = form.elements.postRegRegion.value;
                const postRegCity = form.elements.postRegCity.value;
                const legalRegStreet = form.elements.legalRegStreet.value;
                const legalRegHouse = form.elements.legalRegHouse.value;
                const legalRegCorpus = form.elements.legalRegCorpus.value;
                const legalRegApp = form.elements.legalRegApp.value;
                const legalPostalCode = form.elements.legalPostalCode.value;
                const postRegStreet = form.elements.postRegStreet.value;
                const postRegHouse = form.elements.postRegHouse.value;
                const postRegCorpus = form.elements.postRegCorpus.value;
                const postRegApp = form.elements.postRegApp.value;
                const postPostalCode = form.elements.postPostalCode.value;
                const okvedPartLine = (form.elements.okvedPartLine && form.elements.okvedPartLine.value) ?  form.elements.okvedPartLine.value : '';
                
                store.saveCompany(store.user.email, store.user.id, organizationalLegalFor, shortCompanyName, companyPhone, companyEmail, companyWebSite, ogrnName, innName, uploadOgrn, uploadInn, kppName, okpoName, okatoName, okvedName, regDate, nameRegAuthority, gosRegRegion, gosRegCity, gosRegStreet, gosRegHouse, gosRegCorpus, gosRegApp, gosPostalCode, isLegalAddSameAsGos, isPostAddSameAsGos, legalRegRegion, legalRegCity, postRegRegion, postRegCity, legalRegStreet, legalRegHouse, legalRegCorpus, legalRegApp, legalPostalCode, postRegStreet, postRegHouse, postRegCorpus, postRegApp, postPostalCode, fullCompanyName, fullCompanyNameEng, shortCompanyNamEng, abbreviation, kioName, regCountry, codeForeignerStructure, gosRegNumber, numberRecordAcredetetion, regNumberWithoutLegal, isPlaceLegalTheSame, placeOfTheLegal, okvedPartLine);
                setActive2(false);
                window.scrollTo(0, 0);
            }
            else if (e.target.id === 'step3') {
                const typeActivity = form.elements.typeActivity.value;
                const numberAct = form.elements.numberAct.value;
                const dateAct = form.elements.dateAct.value;
                const officeAct = form.elements.officeAct.value;
                const dateEnd = form.elements.dateEnd.value;
                const uploadCopyScan = form.elements.uploadCopyScan.files[0];
                const isBankruptcy = form.elements.isBankruptcy.value;
                const isDoneBankruptcy = form.elements.isDoneBankruptcy.value;
                const isLiquidation = form.elements.isLiquidation.value;
                const isFailureObligation = form.elements.isFailureObligation.value;
                const isDebt = form.elements.isDebt.value;
                const financialInfo = form.elements.financialInfo.value;
                const businessReputation = form.elements.businessReputation.value;
                const uploadFinanceDoc = form.elements.uploadFinanceDoc.files;
                const uploadFinSituation = form.elements.uploadFinSituation.files;
                const uploadFinReputation = form.elements.uploadFinReputation.files;
                const amountRegistred = (form.elements.amountRegistred && form.elements.amountRegistred.value) ?  form.elements.amountRegistred.value : '';
                const amountPaid= (form.elements.amountPaid && form.elements.amountPaid.value) ?  form.elements.amountPaid.value : '';
                const isOwnerForeignerCompany= (form.elements.isOwnerForeignerCompany && form.elements.isOwnerForeignerCompany.value) ?  form.elements.isOwnerForeignerCompany.value : '';
                const nameOfOwnerForeigner= (form.elements.nameOfOwnerForeigner && form.elements.nameOfOwnerForeigner.value) ?  form.elements.nameOfOwnerForeigner.value : '';
                const formOfOwnerForeigner= (form.elements.formOfOwnerForeigner && form.elements.formOfOwnerForeigner.value) ?  form.elements.formOfOwnerForeigner.value : '';
                const isProtector= (form.elements.isProtector && form.elements.isProtector.value) ?  form.elements.isProtector.value : '';
                const nameOfProtector= (form.elements.nameOfProtector && form.elements.nameOfProtector.value) ?  form.elements.nameOfProtector.value : '';
                const protectorOrgForm= (form.elements.protectorOrgForm && form.elements.protectorOrgForm.value) ?  form.elements.protectorOrgForm.value : '';
                const protectorCountProperties= (form.elements.protectorCountProperties && form.elements.protectorCountProperties.value) ?  form.elements.protectorCountProperties.value : '';
                const protectorFIOOwner= (form.elements.protectorFIOOwner && form.elements.protectorFIOOwner.value) ?  form.elements.protectorFIOOwner.value : '';
                const protectorAddress= (form.elements.protectorAddress && form.elements.protectorAddress.value) ?  form.elements.protectorAddress.value : '';
                
                store.saveLicense(store.user.email, store.user.id, typeActivity, numberAct, dateAct, officeAct, dateEnd, uploadCopyScan, isBankruptcy, isDoneBankruptcy, isLiquidation, isFailureObligation, isDebt, financialInfo, businessReputation, uploadFinanceDoc, uploadFinSituation, uploadFinReputation, amountRegistred, amountPaid, isOwnerForeignerCompany, nameOfOwnerForeigner, formOfOwnerForeigner, isProtector, nameOfProtector, protectorOrgForm, protectorCountProperties, protectorFIOOwner, protectorAddress);
                
                setActive3(false);
                window.scrollTo(0, 0);
            } else if (e.target.id === 'step4') {
                const organizationalStructureLegalEntity = form.elements.organizationalStructureLegalEntity.value;
                const beneficialOwner = form.elements.beneficialOwner.value;
                const partControlOwner = form.elements.partControlOwner.checked;
                const fullCOntrolOwner = form.elements.fullCOntrolOwner.checked;
                const ownerSurname = form.elements.ownerSurname.value;
                const ownerName = form.elements.ownerName.value;
                const ownerPart = form.elements.ownerPart.value;
                const haveIncome = form.elements.haveIncome.value;
                const ownerPartLine = form.elements.ownerPartLine.value;
                const benStatus = (form.elements.benStatus && form.elements.benStatus.value) ?  form.elements.benStatus.value : '';
                const benConfirmDoc = (form.elements.benConfirmDoc && form.elements.benConfirmDoc.value) ?  form.elements.benConfirmDoc.value : '';
                //const uploadBenConfirm = (form.elements.uploadBenConfirm && form.elements.uploadBenConfirm.files) ?  form.elements.uploadBenConfirm.files[0] : '';
                const uploadBenConfirm = Array.from(allFiles.values());
                const reprFio = (form.elements.reprFio && form.elements.reprFio.value) ?  form.elements.reprFio.value : '';
                const repName = (form.elements.repName && form.elements.repName.value) ?  form.elements.repName.value : '';
                const reprPatronimic = (form.elements.reprPatronimic && form.elements.reprPatronimic.value) ?  form.elements.reprPatronimic.value : '';
                const dateDateBirthday = (form.elements.dateDateBirthday && form.elements.dateDateBirthday.value) ?  form.elements.dateDateBirthday.value : '';
                const reprEmail = (form.elements.reprEmail && form.elements.reprEmail.value) ?  form.elements.reprEmail.value : '';
                const reprPhone = (form.elements.reprPhone && form.elements.reprPhone.value) ?  form.elements.reprPhone.value : '';
                const reprBirthdayPlace = (form.elements.reprBirthdayPlace && form.elements.reprBirthdayPlace.value) ?  form.elements.reprBirthdayPlace.value : '';
                const reprSerial = (form.elements.reprSerial && form.elements.reprSerial.value) ?  form.elements.reprSerial.value : '';
                const reprPasportNumber = (form.elements.reprPasportNumber && form.elements.reprPasportNumber.value) ?  form.elements.reprPasportNumber.value : '';
                const pasportDate = (form.elements.pasportDate && form.elements.pasportDate.value) ?  form.elements.pasportDate.value : '';
                const reprCodeDepartment = (form.elements.reprCodeDepartment && form.elements.reprCodeDepartment.value) ?  form.elements.reprCodeDepartment.value : '';
                const reprIssuedBy = (form.elements.reprIssuedBy && form.elements.reprIssuedBy.value) ?  form.elements.reprIssuedBy.value : '';
                //const uploadPasportDoc = (form.elements.uploadPasportDocBen && form.elements.uploadPasportDocBen.files) ?  form.elements.uploadPasportDocBen.files[0] : '';
                const uploadPasportDoc = Array.from(allPasportFiles.values());
                const reprInn = (form.elements.reprInn && form.elements.reprInn.value) ?  form.elements.reprInn.value : '';
                const reprRegRegion = (form.elements.reprRegRegion && form.elements.reprRegRegion.value) ?  form.elements.reprRegRegion.value : '';
                const reprRegCity = (form.elements.reprRegCity && form.elements.reprRegCity.value) ?  form.elements.reprRegCity.value : '';
                const reprRegStreet = (form.elements.reprRegStreet && form.elements.reprRegStreet.value) ?  form.elements.reprRegStreet.value : '';
                const reprRegHouse = (form.elements.reprRegHouse && form.elements.reprRegHouse.value) ?  form.elements.reprRegHouse.value : '';
                const reprRegCorpus = (form.elements.reprRegCorpus && form.elements.reprRegCorpus.value) ?  form.elements.reprRegCorpus.value : '';
                const reprRegApp = (form.elements.reprRegApp && form.elements.reprRegApp.value) ?  form.elements.reprRegApp.value : '';
                const reprPostalCode = (form.elements.reprPostalCode && form.elements.reprPostalCode.value) ?  form.elements.reprPostalCode.value : '';
                //const uploadRegistrationDoc = (form.elements.uploadRegistrationDocBen && form.elements.uploadRegistrationDocBen.files) ?  form.elements.uploadRegistrationDocBen.files[0] : '';
                const uploadRegistrationDoc = Array.from(allRegistrationFiles.values());
                const isLiveAddSameAsReg = (form.elements.isLiveAddSameAsReg && form.elements.isLiveAddSameAsReg.value) ?  form.elements.isLiveAddSameAsReg.value : '';
                const isPostAddSameAsReg = (form.elements.isPostAddSameAsReg && form.elements.isPostAddSameAsReg.value) ?  form.elements.isPostAddSameAsReg.value : '';
                const addrLiveRegion = (form.elements.addrLiveRegion && form.elements.addrLiveRegion.value) ?  form.elements.addrLiveRegion.value : '';
                const addrLiveCity = (form.elements.addrLiveCity && form.elements.addrLiveCity.value) ?  form.elements.addrLiveCity.value : '';
                const addrLiveStreet = (form.elements.addrLiveStreet && form.elements.addrLiveStreet.value) ?  form.elements.addrLiveStreet.value : '';
                const addrLiveHouse = (form.elements.addrLiveHouse && form.elements.addrLiveHouse.value) ?  form.elements.addrLiveHouse.value : '';
                const addrLiveCorpus = (form.elements.addrLiveCorpus && form.elements.addrLiveCorpus.value) ?  form.elements.addrLiveCorpus.value : '';
                const addrLiveApp = (form.elements.addrLiveApp && form.elements.addrLiveApp.value) ?  form.elements.addrLiveApp.value : '';
                const addrLivePostalCode = (form.elements.addrLivePostalCode && form.elements.addrLivePostalCode.value) ?  form.elements.addrLivePostalCode.value : '';
                const postLiveRegion = (form.elements.postLiveRegion && form.elements.postLiveRegion.value) ?  form.elements.postLiveRegion.value : '';
                const postLiveCity = (form.elements.postLiveCity && form.elements.postLiveCity.value) ?  form.elements.postLiveCity.value : '';
                const postLiveStreet = (form.elements.postLiveStreet && form.elements.postLiveStreet.value) ?  form.elements.postLiveStreet.value : '';
                const postLiveHouse = (form.elements.postLiveHouse && form.elements.postLiveHouse.value) ?  form.elements.postLiveHouse.value : '';
                const postLiveCorpus = (form.elements.postLiveCorpus && form.elements.postLiveCorpus.value) ?  form.elements.postLiveCorpus.value : '';
                const postLiveApp = (form.elements.postLiveApp && form.elements.postLiveApp.value) ?  form.elements.postLiveApp.value : '';
                const postLivePostalCode = (form.elements.postLivePostalCode && form.elements.postLivePostalCode.value) ?  form.elements.postLivePostalCode.value : '';
                const benPartLine = (form.elements.benPartLine && form.elements.benPartLine.value) ?  form.elements.benPartLine.value : '';
                store.saveOwner(store.user.email, store.user.id, organizationalStructureLegalEntity, beneficialOwner, partControlOwner, fullCOntrolOwner, ownerSurname, ownerName, ownerPart, haveIncome, ownerPartLine, benStatus, benConfirmDoc, uploadBenConfirm, reprFio, repName, reprPatronimic, dateDateBirthday, reprEmail, reprPhone, reprBirthdayPlace, reprSerial, reprPasportNumber, pasportDate, reprCodeDepartment, reprIssuedBy, uploadPasportDoc, reprInn, reprRegRegion, reprRegCity, reprRegStreet, reprRegHouse, reprRegCorpus, reprRegApp, reprPostalCode, uploadRegistrationDoc, isLiveAddSameAsReg, isPostAddSameAsReg, addrLiveRegion, addrLiveCity, addrLiveStreet, addrLiveHouse, addrLiveCorpus, addrLiveApp, addrLivePostalCode, postLiveRegion, postLiveCity, postLiveStreet, postLiveHouse, postLiveCorpus, postLiveApp, postLivePostalCode, benPartLine);

                setActive4(false);
                window.scrollTo(0, 0);
            }
            set_Validated(false);
        } else {
            set_Validated(true);
        }
    }

    return (<>
        {store.isAuth ? (<>
        <OrgAccountSubMenu class1="organization-link px-4 active" class2="organization-link px-4"/>
        <Container className='mb-5'>
            <CardTitle className='mt-5'>Анкета пользователя Юридического лица</CardTitle>
            <Tabs 
                defaultActiveKey={stepActiveItem}
                variant="underline"
                id="uncontrolled-tab-example" className="mb-3" justify
                activeKey={key}
                onSelect={(k) => setKey(k)}
                >
                <Tab eventKey="step1" title="Шаг">
                    <Form id="step-1" noValidate validated={validated}>
                        <LeagalForm1/>
                        <Button className='mt-4' variant="secondary" id="step2" onClick={NextStep}>Далее</Button>
                    </Form>
                </Tab>
                <Tab eventKey="step2" title="Шаг" disabled={disableBtn2}>
                    <Form noValidate validated={validated}>
                        <LeagalForm2/>
                        <Button className='mt-5' variant="secondary" id="step3" onClick={NextStep}>Далее</Button>
                    </Form>
                </Tab>
                <Tab eventKey="step3" title="Шаг" disabled={disableBtn3}>
                    <Form noValidate validated={validated}>
                        <LeagalForm3 onChange={handleNameChange}/>
                        <Button className='mt-5' variant="secondary" id="step4" onClick={NextStep}>Далее</Button>
                    </Form>
                </Tab>
                <Tab eventKey="step4" title="Шаг" disabled={disableBtn4}>
                    <Form noValidate validated={validated} onSubmit={submitFn}>
                        <LeagalForm4/>
                        <Row className='mt-5'><Col><Button variant="primary" id="form-submit" type="submit">Отправить на проверку</Button></Col></Row>
                    </Form>
                    <Link to="/" ref={modalCompleateHendle} onClick={handleShow}></Link>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <h4>Форма отправлена на проверку и будет обработана в ближайшее время</h4>
                            <Link to="/account" className="btn btn-secondary mt-4">Перейти на главную страницу</Link>
                        </Modal.Body>
                        <Modal.Footer>
                            <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                            <div className='btn-outline-wrap-green mt-3 mb-4'>
                                <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </Tab>
            </Tabs>
        </Container>
        </>) : <></>}
    </>);
}
 
export default observer(AccountCompanyForm);