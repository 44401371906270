import React, { FC, useContext, useState} from "react";
import {Context} from "../../../../index";
import { observer } from "mobx-react-lite";
import {Col, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";

import Carousel from "react-multi-carousel";

import selectFile from "../../../../img/icons/fileUpload.svg";
import infoCount from "../../../../img/icons/infoCount.svg";
import modalImage from "../../../../img/icons/settings-alt.svg";
import defaultImage from "../../../../img/main/card-non.svg";

import URL from "../../../../config/default.json"
import ModalGos from "../../../modals/ModalGos";

export const API_URL = URL.SERVER_URL_BASE;

const StorageDetail: FC = () => {
    const {store} = useContext(Context);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [itemNumber, setItemNumber] = useState(0);

    const showDetailModal = (id: number) => {
        setItemNumber(id);
        setShow(true);
    }
    const storageReverse = store.storageInfo.storage.slice(-store.storageInfo.storage.length).reverse();
    const images = storageReverse[itemNumber]?.uploadStoragePhotoPath || [];
    
    function showStorageItem(count:any, variant = '') {
        var storage:any = store.storageInfo.storage
        var index = 1;
        const lastItems = storage.slice(-storage.length).reverse();
        return (<>
            <div className="harvest-data p-0 pt-2">
                <Row className="harvest-data-bottom">
                    <Col xs={{ order: 2 }} lg={10}><Row>
                        {lastItems.map(function(data:any, idx:any){
                            if (index > count) {
                                return false;
                            }
                            const uploadStoragePhotoPathElem = data.uploadStoragePhotoPath[0];
                            const uploadStoragePhotoPath = uploadStoragePhotoPathElem ? uploadStoragePhotoPathElem.split('uploads/').pop() : '';

                            const imgUrl = uploadStoragePhotoPath ? API_URL + uploadStoragePhotoPath : defaultImage;

                            let typeOfStorage = 'Напольный склад';
                            if (data.typeOfStorage === 'elevator') 
                                typeOfStorage = 'Элеватор';
                            else if (data.typeOfStorage === 'bigsklad')
                                typeOfStorage = 'Склад для бигбегов/мешкотары';

                            if (data.typeOfStorage === variant || variant === '') {
                                index++;
                                return (<Col lg={4}>
                                    <div className="harvest-data-bottom-item-info">
                                    {data.uploadStoragePhotoPath ? (<>
                                        <div className="photo-production-img loaded info d-flex position-relative"> <img src={imgUrl} alt={data.nameOfStorage} />
                                            <div className="develop-technique px-3 cursor-pointer" onClick={e => showDetailModal(idx)} >
                                                <div className="develop-technique-title">{typeOfStorage}</div>
                                                <div className="develop-technique-year mb-2">{data.volumenOfCorn}л объем хранимого зерна</div>
                                            </div>
                                            <ModalGos classImg="img" class="develop-settings" name="" image={modalImage}/>
                                        </div>
                                    </>): (<></>)}
                                    </div>
                                    </Col>)
                            } else {
                                return (<></>)
                            }
                        }) 
                    }
                    </Row></Col>
                    <Col order="1" lg={2} ><div className="harvest-data-bottom-item info"><span><img className="me-2" src={infoCount} alt="="/>{index - 1}</span><span>eдиниц</span></div></Col>
                </Row>
            </div>
        </>);
    }
    return ( <>
     {store.isAuth && store.storageInfo && store.storageInfo.email ? (<>
        <Col className="mb-3" lg={12} ><Link className="card-link-container-title" to="/storage"><img className="me-3" src={selectFile} alt="Земля"/>Хранилище</Link></Col>
        <Tabs defaultActiveKey="step1" variant="tabs" id="info-tab-example" className="mb-3" >
            <Tab eventKey="step1" title="Все">
                {showStorageItem(3)}
            </Tab>
            <Tab eventKey="step2" title="Напольный склад">
                {showStorageItem(3, 'sklad')}
            </Tab>
            <Tab eventKey="step3" title="Элеватор">
                {showStorageItem(3, 'elevator')}
            </Tab>
            <Tab eventKey="step4" title="Склад для бигбегов/мешкотары">
                {showStorageItem(3, 'bigsklad')}
            </Tab>
        </Tabs>
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} className="cfa-modal">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <h4 className="mb-4">
                    {storageReverse[itemNumber].typeOfStorage === 'sklad' ? 'Напольный склад' : ''}
                    {storageReverse[itemNumber].typeOfStorage === 'elevator' ? 'Элеватор' : ''}
                    {storageReverse[itemNumber].typeOfStorage === 'bigsklad' ? 'Склад для бигбегов/мешкотары' : ''}
                </h4>
                <Col lg={12}>
                    <Carousel additionalTransfrom={0} arrows autoPlaySpeed={3000} centerMode={false} containerClass="container-with-dots" draggable focusOnSelect={false} infinite keyBoardControl
                        responsive={{
                            desktop: {
                                breakpoint: {
                                    max: 3000,
                                    min: 1024
                                },
                                items: 3,
                                partialVisibilityGutter: 40
                            },
                            tablet: {
                                breakpoint: {
                                    max: 1024,
                                    min: 464
                                },
                                items: 2,
                                partialVisibilityGutter: 30
                            },
                            mobile: {
                                breakpoint: {
                                    max: 464,
                                    min: 0
                                },
                                items: 1,
                                partialVisibilityGutter: 30
                            }
                        }}
                        slidesToSlide={1}
                        swipeable
                        >
                            {images.map((photo: any, idx: number) => {
                                const uploadTechniquePhotoPath = photo ? photo.split("uploads/").pop() : "";
                                return (
                                    <div className="carousel-images mx-3"><img
                                    key={idx}
                                    src={API_URL + uploadTechniquePhotoPath}
                                    alt={storageReverse[itemNumber].typeOfStorage}
                                    className="img-fluid mx-1"
                                /></div>
                                );
                            })}
                    </Carousel>
                </Col>
                <div className="harvest-data px-0">
                    <Row className="harvest-data-bottom">
                        <Col lg={4} ><div className="harvest-data-bottom-item info"><span>{storageReverse[itemNumber].volumenOfStorage} л</span><span>полезный объем</span></div></Col>
                        <Col lg={4}><div className="harvest-data-bottom-item info"><span>{storageReverse[itemNumber].volumenOfCorn} л</span><span>объем хранимого зерна</span></div></Col>
                        {storageReverse[itemNumber].elevatorAddress ? <Col lg={4}><div className="harvest-data-bottom-item"><span>{storageReverse[itemNumber].elevatorAddress}</span><span>местоположение</span></div></Col> : <></>}
                        <Col lg={12}>
                        <div className="harvest-data-bottom-item detail-carousel-height mt-4">
                            <span>Описание производства</span>
                            {storageReverse[itemNumber].storageConditions}
                        </div>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
        
    </>) : <></>}
    </> );
}
 
export default observer(StorageDetail);