import React, { FC, useContext, useRef, useState } from "react";
import AccountMenu from "../parts/AccountMenu";
import {Context} from "../../../index";
import { observer } from "mobx-react-lite";
import { Form, Card, CardBody, CardTitle, Container, FormLabel, Col, Button, Row, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModalDefault from "../../modals/ModalDefault";
import modalImage from "../../../img/icons/Info-circle.svg"
import remove from "../../../img/icons/Trash.svg";
import selectFile from "../../../img/icons/fileUpload.svg";

import Modal from 'react-bootstrap/Modal';
import envelope from "../../../img/icons/Envelope.svg";
import OrgAccountSubMenu from "../parts/OrgAccountSubMenu";
import ModalGos from "../../modals/ModalGos";

import URL from "../../../config/default.json";
import DropdownTagInput from "../parts/DropdownTagInput";
export const API_URL = URL.SERVER_URL_BASE;


const ProductionForm: FC = () => {
    const {store} = useContext(Context);
    const maxLength = 7000;
    const [validated, set_Validated] = useState(false);
    const filePickerProduction = useRef<HTMLInputElement>(null);
    const handleModal = useRef<HTMLAnchorElement>(null);

    // Confirm modal
    const [deleteId, setDeleteId] = useState('');
    const [deletePath, setDeletePath] = useState('');
    const [showDeleteModal, setDeleteModal] = useState(false);
    const handleCloseDeleteModal = () => setDeleteModal(false);

    const [textAreaCount, setCounterData] = useState<number>(0);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e: any) => {e.preventDefault(); setShow(true)};

    const [form_Data, set_Form_Data] = useState({
        powerOfProduction: '',
        productionDescription: '',
        sortOfProduction: '',
    })

    const [resetTrigger, setResetTrigger] = useState<number>(0);

    const submitForm = (e: any) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            const typeOfProduction = form.elements.typeOfProduction.value;
            const powerOfProduction = form.elements.powerOfProduction.value;
            const sortOfProduction = form.elements.sortOfProduction.value;
            const productionDescription = form.elements.productionDescription.value;
            const dateAdded = form.elements.dateAdded.value;
            const uploadProductionPhotos = form.elements.uploadProductionPhotos.files;

            store.saveProductionInfo(store.user.email, store.user.id, typeOfProduction, powerOfProduction, sortOfProduction, productionDescription, dateAdded, uploadProductionPhotos);
            set_Validated(false);
            set_Form_Data({
                powerOfProduction: '',
                productionDescription: '',
                sortOfProduction: ''
            });

            setResetTrigger(prev => prev + 1);

            setFilenameConfirmDoc(['Загрузить фотографию']);
            setImageProductionURL('');

            if (filePickerProduction.current) {
                filePickerProduction.current.value = "";
            }
            if (handleModal.current) {
                handleModal.current.click();
            }
        } else {
            set_Validated(true);
        }
        
    };

    const removeItem = () => {
        store.removeProductionItem(store.user.email, deleteId, deletePath);
        setDeleteModal(false);
    }

    const removeItemSetData = (id:any, path: any) => {
        setDeleteId(id);
        setDeletePath(path);
        setDeleteModal(true);
    }

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
        if (name === 'qualityDescription') {
            setCounterData(e.target.value.length);
        }
    };

    const [form_Raio, set_Form_Radio] = useState({
        isTypeOfProduction1: true,
        isTypeOfProduction2: false,
        isTypeOfProduction3: false,
        isTypeOfProduction4: false,
    });

    const radioChange = function(par1:boolean, par2:boolean, par3:boolean, par4:boolean) {
        set_Form_Radio({
            isTypeOfProduction1: par1,
            isTypeOfProduction2: par2,
            isTypeOfProduction3: par3,
            isTypeOfProduction4: par4,
        })
    }

    const fileLimitPhotoConfig = URL.FILE_LIMIT_PHOTO;
    const fileLimitPhoto = fileLimitPhotoConfig;
    const photoAmountLimit = 5;
    const filePickerProductionPhoto = useRef<HTMLInputElement>(null);
    const [filenameProductionPhoto, setFilenameConfirmDoc] = useState<any>(['Загрузить фотографию']);
    const [errorFileSizeConfirmDoc, setErrorConfirmDoc] = useState<string>('');
    const [errorLimitCount, setErrorLimitCount] = useState<boolean>(false);
    const [imageProductionURL, setImageProductionURL] = useState<any>([]);

    function readFileAsText(file:any){
        return new Promise(function(resolve,reject){
            let fr = new FileReader();
            fr.onload = function(){
                resolve(fr.result);
            };
            fr.onerror = function(){
                reject(fr);
            };
            fr.readAsDataURL(file);
        });
    }

    const uploadHandleChnageProductionPhoto = (e: any) => {
        let photos = e.target.files;
        var photoArr: any[] = [];
        var photoName: any[] = [];
        var errOneOfFile:string = '';
        if (photos.length > photoAmountLimit) {
            setErrorLimitCount(true);
            if (filePickerProductionPhoto.current) {
                filePickerProductionPhoto.current.value = "";
            }
        } else {
            setErrorLimitCount(false);
            Array.from(photos).forEach((photo: any) => {
                if (photo.size > fileLimitPhoto) {
                    errOneOfFile = photo.name;
                } else {
                    photoName.push(photo.name);
                    photoArr.push(readFileAsText(photo));

                    setFilenameConfirmDoc(photoName);
                    Promise.all(photoArr).then((values) => {
                        setImageProductionURL(values);
                    });
                }
            });
            setErrorConfirmDoc(errOneOfFile);
        }
    }

    const handlePickProductionPhoto = () => {
        if (filePickerProductionPhoto.current) {
            filePickerProductionPhoto.current.click();
        }
    }
    

    return ( <>
     {store.isAuth ? (<>
        <OrgAccountSubMenu class1="organization-link px-4" class2="organization-link px-4" class3="organization-link px-4 active"/>
        <Container className='mb-5'>
            <AccountMenu/>
            <Form noValidate validated={validated} onSubmit={submitForm}>
                <div className="load-harvest-doc my-5 text-center d-flex justify-content-center align-items-baseline">
                    <ModalDefault classButton="text-center" name="Заполнить автоматически через Росреестр" variant="primary"/>
                    <ModalGos classImg="btn btn-link p-2 border-link ms-3" image={modalImage}/>
                </div>
                <CardTitle>Добавить производство</CardTitle>
                <Card className="mb-3">
                    <CardBody>
                        <Form.Group as={Col} className="mb-3" controlId="validationplantasName">
                            <FormLabel>Тип производства</FormLabel>
                            <Form.Check
                                type="radio"
                                name="typeOfProduction"
                                value="krupa"
                                label="Крупяное производство"
                                id="isTypeOfProduction1"
                                className="radio"
                                checked={form_Raio.isTypeOfProduction1}
                                onChange={e => radioChange(true, false, false, false)}
                            />
                            <Form.Check
                                type="radio"
                                name="typeOfProduction"
                                value="maslo"
                                label="Масличное производство"
                                id="isTypeOfProduction2"
                                className="radio"
                                checked={form_Raio.isTypeOfProduction2}
                                onChange={e => radioChange(false, true, false, false)}
                            />
                            <Form.Check
                                type="radio"
                                name="typeOfProduction"
                                value="myka"
                                label="Мукомольное производство"
                                id="isTypeOfProduction3"
                                className="radio"
                                checked={form_Raio.isTypeOfProduction3}
                                onChange={e => radioChange(false, false, true, false)}
                            />
                            <Form.Check
                                type="radio"
                                name="typeOfProduction"
                                value="korm"
                                label="Кормовое производство"
                                id="isTypeOfProduction4"
                                className="radio"
                                checked={form_Raio.isTypeOfProduction4}
                                onChange={e => radioChange(false, false, false, true)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" controlId="validationreprvolumen">
                            <FormLabel className='required'>Мощность</FormLabel>
                            <Form.Control
                                type="number"
                                step="0.01"
                                name="powerOfProduction"
                                className='w-100 legal-form-1'
                                value={form_Data.powerOfProduction}
                                onChange={chngFn}
                                required
                                placeholder='Укажите производственную мощность тонн в сутки'
                                onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>

                        <DropdownTagInput resetTrigger={resetTrigger}/>
                        <Form.Group as={Col} className="mb-3 position-relative" controlId="validationqualityDescription">
                            <FormLabel  className='required'>Описание производства</FormLabel>
                            <Form.Control
                                as="textarea"
                                name="productionDescription"
                                rows={6}
                                className='w-100 legal-form-1'
                                value={form_Data.productionDescription}
                                onChange={chngFn}
                                placeholder='Введите основные сведения о производстве'
                                maxLength={maxLength}
                                required
                            />
                            <div className="text-counter">{textAreaCount} / {maxLength}</div>
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" controlId="validationLicenseConfirmDoc">
                            <Form.Control 
                                type="file"
                                className="hidden"
                                multiple
                                onChange={uploadHandleChnageProductionPhoto}
                                accept=".jpg, .jpeg, .png, .heic"
                                name="uploadProductionPhotos"
                                ref={filePickerProductionPhoto}
                            />
                            
                            <div className="filePicker d-flex align-items-center">
                                <div className="me-4 fileIcon" onClick={handlePickProductionPhoto}><img src={selectFile} alt="Select file"/></div>
                                <div className="fileLable">{filenameProductionPhoto.map(function (data:string, idx:any){return (<div key={idx} className="me-4">{data}</div>)})}
                                    <div className="small-txt">Формат файла должен соответствовать [.jpg, .jpeg, .png, .heic]</div>
                                </div>
                                
                                {errorFileSizeConfirmDoc !== '' ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла {errorFileSizeConfirmDoc} больше лимита в {fileLimitPhoto / URL.FILE_LIMIT_PHOTO} Mb</Alert> : ''}
                                {errorLimitCount ? <Alert className="d-flex w-100 mt-3" variant="danger">Количество файлов больше лимита в {photoAmountLimit} штук</Alert> : ''}
                            </div>
                            {imageProductionURL ? (
                                imageProductionURL.map(function(data:any){
                                    return (<>
                                        <div className="d-inline-block me-4"><div className="photo-land-img"><img src={data} alt=''/></div></div>
                                    </>);
                                })
                            ) : (<></>)}
                        </Form.Group>
                        <Form.Control
                            type="text"
                            name="dateAdded"
                            value={new Date().toISOString().slice(0, 10).split('-').reverse().join('/')}
                            className='hidden'
                            readOnly
                        />
                        <Button className="mt-2" variant="secondary" id="form-submit" type="submit">Сохранить</Button>
                    </CardBody>
                </Card>
                <Link to="/" onClick={handleShow} ref={handleModal}></Link>
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                    <h4>Даные добавлены</h4>
                    <button className='btn btn-primary d-flex align-item-center mt-4' onClick={handleClose}>Закрыть</button>
                    </Modal.Body>
                    <Modal.Footer>
                    <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                    <div className='btn-outline-wrap-green mt-3 mb-4'>
                    <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Form>
            {store.productionInfo && store.productionInfo.production && store.productionInfo.production.length > 0 ? (<>
                <CardTitle className="mt-5">Добавленные хранилища</CardTitle>
                <Card className="mb-3">
                    <CardBody>
                        {store.productionInfo.production.map(function(data, idx){

                            let typeOfProduction = 'Крупяное производство';
                            if (data.typeOfProduction === 'maslo') 
                                typeOfProduction = 'Масличное производство';
                            else if (data.typeOfProduction === 'myka')
                                typeOfProduction = 'Мукомольное производство';
                            else if (data.typeOfProduction === 'korm')
                                typeOfProduction = 'Кормовое производство';

                            return (<div className="harvest-data" key={idx}>
                                    <div className="harvest-data-top mb-3 d-flex justify-content-between align-items-center">{typeOfProduction} <Link to="" onClick={e => removeItemSetData(data._id, data.uploadProductionPhotoPath)}><img src={remove} alt="remove"/></Link></div>
                                    <Row className="harvest-data-bottom">
                                        <Col lg={2} ><div className="harvest-data-bottom-item"><span>{data.powerOfProduction}</span><span>мощность</span></div></Col>
                                        <Col lg={2}><div className="harvest-data-bottom-item"><span>{data.sortOfProduction}</span><span>сорт</span></div></Col>
                                        <Col lg={5}><div className="harvest-data-bottom-item"><span>Описание производства</span><span>{data.productionDescription}</span></div></Col>
                                        
                                        <Col lg={3}><div className="photo-storage-img loaded d-flex">
                                            {data.uploadProductionPhotoPath.map(function (photo:any){
                                                const uploadProductionPhotoPath = photo ? photo.split('uploads/').pop() : '';
                                                return (<img src={API_URL + uploadProductionPhotoPath} alt={data.typeOfProduction} />);
                                            })}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>)
                        }) }
                    </CardBody>
                    <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} backdrop="static" keyboard={false} >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                        <h4>Подвердите удаление данных</h4>
                        </Modal.Body>
                        <Modal.Footer>
                        <p className="mb-4">Данные будут удалены и востановлению не подлежат</p>
                        <Row className="w-100">
                            <Col className="ps-0" lg={3}>
                                <button className='btn btn-primary mt-3 mb-4 w-100 text-center' onClick={removeItem}>Да</button>
                            </Col>
                            <Col className="ps-0" lg={3}>
                            <div className='btn-outline-wrap-green mt-3 mb-4 w-100 text-center'> 
                                <button className='btn btn-outline' onClick={handleCloseDeleteModal}>Нет</button>
                            </div>
                            </Col>
                        </Row>
                        </Modal.Footer>
                    </Modal>
                </Card>
            </>) : <></>}
        </Container>
    </>) : <></>}
    </> );
}
 
export default observer(ProductionForm);