import React, { FC, useContext} from "react";
import {Context} from "../../../../index";
import { observer } from "mobx-react-lite";
import { Col, Row} from "react-bootstrap";
import { Link } from "react-router-dom";

import selectFile from "../../../../img/icons/fileUpload.svg";
import infoCount from "../../../../img/icons/infoCount.svg";
import flaskAlt from "../../../../img/icons/flask-alt.svg";
import flaskAltGray from "../../../../img/icons/flask-alt-gray.svg";

import URL from "../../../../config/default.json"

export const API_URL = URL.SERVER_URL_BASE;

const ReceiveDetail: FC = () => {
    const {store} = useContext(Context);
    function showReceiveItem(count:any, variant = '') {
        var receive:any = store.receiveInfo.receive;
        return (<>
            <div className="harvest-data p-0 pt-2 w-100">
                <Row className="harvest-data-bottom">
                    <Col xs={{ order: 2 }} lg={12}><Row>
                        {receive.map(function(data:any, idx:any){
                            let typeOfReceive = 'Автомобильный транспорт';
                            if (data.typeOfReceive === 'train') 
                                typeOfReceive = 'Железнодорожный транспорт';
                            else if (data.typeOfReceive === 'port')
                                typeOfReceive = 'Портовый комплекс';

                            
                            return (
                                <div className="harvest-data p-0 pt-2" key={data._id + '-' + idx}>
                                    <Row className="harvest-data-bottom align-items-center">
                                        <Col lg={4} ><div className="harvest-data-bottom-item info"><span><img className="me-2" src={infoCount} alt="="/>{data.powerOfReceive} мощность</span><span>{typeOfReceive}</span></div></Col>
                                        <Col lg={6}></Col>
                                        <Col lg={2}><div className="harvest-data-bottom-item-info text-center">
                                            {data.laboratories === 'yes' ? <>
                                                <span><img className="me-2" src={flaskAlt} alt="лаборатории"/></span>
                                                <span>Есть лаборатория</span>
                                            </> : <>
                                                <span><img className="me-2" src={flaskAltGray} alt="лаборатории"/></span>
                                                <span>Нет лаборатории</span>
                                            </>}
                                        </div></Col>
                                    </Row>
                            </div>)
                        }) 
                    }
                    </Row></Col>
                </Row>
            </div>
        </>);
    }
    return ( <>
     {store.isAuth && store.receiveInfo && store.receiveInfo.email ? (<>
        <Col className="mb-3" lg={12} ><Link className="card-link-container-title" to="/receive"><img className="me-3" src={selectFile} alt="Земля"/>Мощности по приемке</Link></Col>
        {showReceiveItem(3)}
        
    </>) : <></>}
    </> );
}
 
export default observer(ReceiveDetail);