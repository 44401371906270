import React, { FC, useContext} from "react";
import {Context} from "../../../../index";
import { observer } from "mobx-react-lite";
import { Col, Row} from "react-bootstrap";
import { Link } from "react-router-dom";

import selectFile from "../../../../img/icons/fileUpload.svg";
import infoCount from "../../../../img/icons/infoCount.svg";
import mark from "../../../../img/icons/wallet.svg";
import markGray from "../../../../img/icons/wallet-gray.svg";
import userImage from "../../../../img/main/user-image.png";


import URL from "../../../../config/default.json";

export const API_URL = URL.SERVER_URL_BASE;

const SuppliesDetail: FC = () => {
    const {store} = useContext(Context);
    function showSuppliesItem(count:any, variant = '') {
        var supplies:any = store.suppliesInfo.supplies;
        return (<>
            <Col lg={12} className="harvest-data p-0 pt-2">
                <Row className="harvest-data-bottom">
                    <Col xs={{ order: 2 }} lg={12}><Row>
                        {supplies.map(function(data:any, idx:any){
                            
                            return (
                                <div className="harvest-data p-0 pt-2" key={data._id + '-' + idx}>
                                    <Row className="harvest-data-bottom align-items-center">
                                        <Col lg={1} ><div className="supplies-data-bottom-item"><img className="me-2" src={userImage} alt="="/></div></Col>
                                        <Col lg={3} ><div className="harvest-data-bottom-item info"><span><img className="me-2" src={infoCount} alt="="/>{data.volumenOfSupplies} объем</span><span>{data.typeOfCorn}</span></div></Col>
                                        <Col lg={6} ><div className="harvest-data-bottom-item-info"><span>Качество</span></div>
                                        <span className="harvest-data-bottom-item-info"><span></span><span>{data.suppliesConditions}</span></span></Col>
                                        <Col lg={2}><div className="harvest-data-bottom-item-info text-center">
                                            {data.readyToSell ? <>
                                                <span><img className="me-2" src={mark} alt="Готово к реализации"/></span>
                                                <span>Готово к реализации</span>
                                            </> : <>
                                                <span><img className="me-2" src={markGray} alt="Не готово к реализации"/></span>
                                                <span>Не готово к реализации</span>
                                            </>}
                                        </div></Col>
                                    </Row>
                            </div>)
                        }) 
                    }
                    </Row></Col>
                </Row>
            </Col>
        </>);
    }
    return ( <>
     {store.isAuth && store.suppliesInfo && store.suppliesInfo.email ? (<>
        <Col className="mb-3" lg={12} ><Link className="card-link-container-title" to="/supplies"><img className="me-3" src={selectFile} alt="Земля"/>Складские запасы</Link></Col>
        {showSuppliesItem(3)}
        
    </>) : <></>}
    </> );
}
 
export default observer(SuppliesDetail);