import $api from "../http";
import {AxiosResponse} from 'axios';
import {UnloadingResponse} from '../models/response/UnloadingResponse';

export default class UnloadingInfoService {
    static async saveUnloadingInfoService(
        email: string,
        user: string,
        typeOfUnloading:string,
        powerOfUnloading:string,
        scalesOfUpload:string,
        dateAdded:string,
        typeOfContainer1:boolean,
        typeOfContainer2:boolean,
        typeOfContainer3:boolean,
        typeOfContainer4:boolean,
        typeOfContainer5:boolean
        ): Promise<AxiosResponse<UnloadingResponse>> {

        return $api.post<UnloadingResponse>('/saveUnloading',{email, user, typeOfUnloading, powerOfUnloading, scalesOfUpload, dateAdded, typeOfContainer1, typeOfContainer2, typeOfContainer3, typeOfContainer4, typeOfContainer5})
    }
    static async removeUnloadingItem(
        email: string,
        itemId: string,
        ): Promise<AxiosResponse<UnloadingResponse>> {

        return $api.post<UnloadingResponse>('/removeUnloadingItem', {email, itemId})
    }
}