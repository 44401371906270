import $api from "../http";
import {AxiosResponse} from 'axios';
import {InfoTraderResponse} from '../models/response/InfoTraderResponse';

export default class InfoTraderService {
    static async saveInfoTrader(
        email: string,
        user: string,
        infoDescription: string,
        infoDistrict: string,
        uploadTraderMain: File,
        zlak1:boolean,
        zlak2:boolean,
        zlak3:boolean,
        zlak4:boolean,
        zlak5:boolean,
        zlak6:boolean,
        zerno1:boolean,
        zerno2:boolean,
        zerno3:boolean,
        zerno4:boolean,
        zerno5:boolean,
        maslo1:boolean,
        maslo2:boolean,
        maslo3:boolean,
        maslo4:boolean,
        maslo5:boolean,
        maslo6:boolean
        ): Promise<AxiosResponse<InfoTraderResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('infoDescription', infoDescription);
        formData.append('infoDistrict', infoDistrict);
        formData.append('uploadTraderMain', uploadTraderMain);

        formData.append('zlak1', zlak1.toString());
        formData.append('zlak2', zlak2.toString());
        formData.append('zlak3', zlak3.toString());
        formData.append('zlak4', zlak4.toString());
        formData.append('zlak5', zlak5.toString());
        formData.append('zlak6', zlak6.toString());

        formData.append('zerno1', zerno1.toString());
        formData.append('zerno2', zerno2.toString());
        formData.append('zerno3', zerno3.toString());
        formData.append('zerno4', zerno4.toString());
        formData.append('zerno5', zerno5.toString());

        formData.append('maslo1', maslo1.toString());
        formData.append('maslo2', maslo2.toString());
        formData.append('maslo3', maslo3.toString());
        formData.append('maslo4', maslo4.toString());
        formData.append('maslo5', maslo5.toString());
        formData.append('maslo6', maslo6.toString());

        return $api.post<InfoTraderResponse>('/saveTraderInfo', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    }
}