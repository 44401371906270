import React, { useEffect } from "react";
import { Form, Col, FormLabel } from "react-bootstrap";

interface SelectProps {
    allItems: Record<string, boolean>;
    allNames: Record<string, string>;
    selectedValue: string;
    setSelectedValue: (value: string) => void;
    resetTrigger: boolean; // Флаг для сброса
}

const TypeOfCornSelect: React.FC<SelectProps> = ({ 
    allItems, 
    allNames, 
    selectedValue, 
    setSelectedValue,
    resetTrigger
}) => {
    useEffect(() => {
        // Когда resetTrigger меняется, сбрасываем select
        setSelectedValue("");
    }, [resetTrigger]);

    return (
        <Form.Group as={Col} className="mb-3" controlId="validationTypeOfCorn">
            <FormLabel className="required">Укажите название культуры</FormLabel>
            <Form.Select
                name="typeOfCorn"
                className="w-100 legal-form-1"
                required
                value={selectedValue} 
                onChange={(e) => setSelectedValue(e.target.value)}
            >
                <option value="">Выберите необходимое</option>
                {Object.keys(allItems)
                    .filter((key) => allItems[key])
                    .map((key) => (
                        <option key={key} value={allNames[key]}>
                            {allNames[key]}
                        </option>
                    ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                Введите корректные данные
            </Form.Control.Feedback>
        </Form.Group>
    );
};

export default TypeOfCornSelect;
