import React, { FC, useContext, useRef, useState } from "react";
import AccountMenu from "../parts/AccountMenu";
import {Context} from "../../../index";
import { observer } from "mobx-react-lite";
import { Form, Card, CardBody, CardTitle, Container, FormLabel, Col, Button, Row, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModalDefault from "../../modals/ModalDefault";
import infoDocument from "../../../img/icons/infoDocument.svg";
import selectFile from "../../../img/icons/fileUpload.svg";
import URL from "../../../config/default.json"

import remove from "../../../img/icons/Trash.svg";
import Modal from 'react-bootstrap/Modal';
import envelope from "../../../img/icons/Envelope.svg";
import OrgAccountSubMenu from "../parts/OrgAccountSubMenu";

export const API_URL = URL.SERVER_URL_BASE;

const LandForm: FC = () => {
    const {store} = useContext(Context);
    const [validated, set_Validated] = useState(false);
    const filePickerLand = useRef<HTMLInputElement>(null);
    const handleModal = useRef<HTMLAnchorElement>(null);
    const [filenameLand, setFilenameLand] = useState<string>('');
    const [errorFileSizeLand, setErrorLand] = useState<boolean>(false);
    const [radioOwnershipStatus, setRadioOwnershipStatus] = useState<string>(store.landInfo.isOwnership);

    const [imageLandURL, setImageLandURL] = useState<any>();
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
        setImageLandURL(fileReader.result);
    }

    const [deleteId, setDeleteId] = useState('');
    const [deletePath, setDeletePath] = useState('');
    const [deletePhotoPath, setDeletePhotoPath] = useState('');
    const [showDeleteModal, setDeleteModal] = useState(false);
    const handleCloseDeleteModal = () => setDeleteModal(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e: any) => {e.preventDefault(); setShow(true)};

    const fileLimit = URL.FILE_LIMIT;
    const fileLimitPhoto = URL.FILE_LIMIT_PHOTO;
    const filePickerLandPhoto = useRef<HTMLInputElement>(null);
    const [errorFileSizeConfirmDoc, setErrorConfirmDoc] = useState<boolean>(false);
    const [filenameLandPhoto, setFilenameConfirmDoc] = useState<string>('Загрузить фотографию');

    const [form_Data, set_Form_Data] = useState({
        cadastralNumber: '',
        area: '',
        isOwnership: '',
        uploadConfirmDoc: '',
        uploadConfirmDocPath: '',
    })

    const submitForm = (e: any) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            const cadastralNumber = form.elements.cadastralNumber.value;
            const area = form.elements.area.value;
            const isOwnership = form.elements.isOwnership.value;
            const dateAdded = form.elements.dateAdded.value;
            const uploadLand = form.elements.uploadLand.files[0];
            const uploadLandPhoto = form.elements.uploadLandPhoto.files[0];

            store.saveLandInfo(store.user.email, store.user.id, cadastralNumber, area, isOwnership, dateAdded, uploadLand, uploadLandPhoto);
            set_Validated(false);
            set_Form_Data({
                cadastralNumber: '',
                area: '',
                isOwnership: '',
                uploadConfirmDoc: '',
                uploadConfirmDocPath: '',
            });
            setFilenameLand('');
            setFilenameConfirmDoc('Загрузить фотографию');
            setImageLandURL('');
            if (handleModal.current) {
                handleModal.current.click();
            }
        } else {
            set_Validated(true);
        }
        
    };

    const handlePickLand = () => {
        if (filePickerLand.current) {
            filePickerLand.current.click();
        }
   }

   const uploadHandleChnageLand = (e: any) => {
        if (e.target.files[0].size > fileLimit) {
            setErrorLand(true);
            
            if (filePickerLand.current) {
                filePickerLand.current.value = "";
            }
        } else {
            setFilenameLand(e.target.files[0].name);
            setErrorLand(false);
        }
    }

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };

    const handleCheckboxChange = (score: any, inputName: string) => {
        setRadioOwnershipStatus(score)
        set_Form_Data({
            ...form_Data,
            [inputName]: score,
        });
    };

    const uploadHandleChnageLandPhoto = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimitPhoto) {
            setErrorConfirmDoc(true);
            
            if (filePickerLandPhoto.current) {
                filePickerLandPhoto.current.value = "";
            }
        } else {
            setFilenameConfirmDoc(e.target.files[0].name);
            setErrorConfirmDoc(false);
            const fileLand = e.target.files[0];
            fileReader.readAsDataURL(fileLand);
        }
    }

    const handlePickLandPhoto = () => {
        if (filePickerLandPhoto.current) {
            filePickerLandPhoto.current.click();
        }
    }

    const removeItem = () => {
        store.removeLandItem(store.user.email, deleteId, deletePath, deletePhotoPath);
        setDeleteModal(false);
    }

    const removeItemSetData = (id:any, path:string, photoPath: string) => {
        setDeleteId(id);
        setDeletePath(path);
        setDeletePhotoPath(photoPath);
        setDeleteModal(true);
    }

    return ( <>
     {store.isAuth ? (<>
        <OrgAccountSubMenu class1="organization-link px-4" class2="organization-link px-4 active"/>
        <Container className='mb-5'>
            <AccountMenu/>
            <Form noValidate validated={validated} onSubmit={submitForm}>
                <div className="load-harvest-doc">
                    <Form.Control 
                        type="file"
                        className="hidden"
                        accept=".pdf, .jpg, .jpeg, .png, .heic"
                        name="uploadLand"
                        onChange={uploadHandleChnageLand}
                        ref={filePickerLand}
                    />
                    <div className="filePicker px-0 py-4">
                        <div className="d-flex align-items-center justify-content-center gap-3">
                            <Button onClick={handlePickLand}>Загрузить файл</Button>
                            <ModalDefault name="Заполнить автоматически через Росреестр" class="ms-5 btn-outline-wrap-gold"/>
                            <img src={infoDocument} alt="File Information"/>
                        </div>
                        <Row className="d-flex align-items-center justify-content-center">
                            <Col lg={12}>
                                <div className="fileLable mt-3 text-center">{filenameLand}
                                    <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                </div>
                            </Col>
                            {errorFileSizeLand  ? <Col lg={12}><Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert></Col> : ''}
                        </Row>
                    </div>
                </div>
                <CardTitle>Добавить землю</CardTitle>
                <Card className="mb-3">
                    <CardBody>
                        <Form.Group as={Col} className="mb-3" controlId="validationCadastralNumber">
                            <FormLabel className='required'>Укажите кадастровый номер участка</FormLabel>
                            <Form.Control
                                type="text"
                                name="cadastralNumber"
                                className='w-100 legal-form-1'
                                value={form_Data.cadastralNumber}
                                onChange={chngFn}
                                required
                                placeholder='Пример'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" controlId="validationarea">
                            <FormLabel className='required'>Укажите площадь земли (га)</FormLabel>
                            <Form.Control
                                type="number"
                                step="0.01"
                                name="area"
                                className='w-100 legal-form-1'
                                value={form_Data.area}
                                onChange={chngFn}
                                required
                                placeholder='xx.xx'
                                onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                            <FormLabel>Ведется производство по делу о несостоятельности (банкротстве)</FormLabel>
                            <Form.Check
                                type="radio"
                                name="isOwnership"
                                value="property"
                                checked={radioOwnershipStatus === 'property' ? true : false}
                                onChange={e => handleCheckboxChange('property', 'isOwnership')}
                                label="В собственности"
                                id="isOwnershipTrue"
                                className="radio"
                            />
                            <Form.Check
                                type="radio"
                                name="isOwnership"
                                value="rent"
                                checked={radioOwnershipStatus === 'property' ? false : true}
                                onChange={e => handleCheckboxChange('rent', 'isOwnership')}
                                label="В аренде"
                                id="isOwnershipFalse"
                                className="radio"
                            />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" controlId="validationLicenseConfirmDoc">
                        <Form.Control 
                            type="file"
                            className="hidden"
                            onChange={uploadHandleChnageLandPhoto}
                            accept=".jpg, .jpeg, .png, .heic"
                            name="uploadLandPhoto"
                            ref={filePickerLandPhoto}
                        />
                            
                            <div className="filePicker d-flex align-items-center">
                                <div className="me-4 fileIcon" onClick={handlePickLandPhoto}><img src={selectFile} alt="Select file"/></div>
                                <div className="fileLable">{filenameLandPhoto}
                                    <div className="small-txt">Формат файла должен соответствовать [.jpg, .jpeg, .png, .heic]</div>
                                </div>
                                
                                {errorFileSizeConfirmDoc  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита в {fileLimitPhoto / URL.FILE_LIMIT_PHOTO} Mb</Alert> : ''}
                            </div>
                            {imageLandURL ? (<div className="photo-land-img"><img src={imageLandURL ? imageLandURL : ''} alt=''/></div>) : (<></>)}
                            
                            <Form.Control.Feedback type="invalid">
                                Файл обязателен к загрузке
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Control
                            type="text"
                            name="dateAdded"
                            value={new Date().toISOString().slice(0, 10).split('-').reverse().join('/')}
                            className='hidden'
                        />
                        <Button className="mt-2" variant="secondary" id="form-submit" type="submit">Сохранить</Button>
                    </CardBody>
                </Card>
                <Link to="/" onClick={handleShow} ref={handleModal}></Link>
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                    <h4>Даные добавлены</h4>
                    <button className='btn btn-primary d-flex align-item-center mt-4' onClick={handleClose}>Закрыть</button>
                    </Modal.Body>
                    <Modal.Footer>
                    <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                    <div className='btn-outline-wrap-green mt-3 mb-4'>
                    <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Form>
            {store.landInfo && store.landInfo.land  && store.landInfo.land.length > 0 ? (<>
                <CardTitle className="mt-5">Добавленная земля</CardTitle>
                <Card className="mb-3">
                    <CardBody>
                        {store.landInfo.land.map(function(data, idx){
                            const uploadLandDBelemnt = data.uploadLandPath;
                            const fileNameCopyScaDB = uploadLandDBelemnt ? uploadLandDBelemnt.split('-f-n-').pop() : '';
                            const typeofOwnership = data.isOwnership === 'property' ? 'В собственности' : 'В аренде'
                            const uploadLandPhotoPathElem = data.uploadLandPhotoPath;
                            const uploadLandPhotoPath = uploadLandPhotoPathElem ? uploadLandPhotoPathElem.split('uploads/').pop() : '';
                            
                            return (<div className="harvest-data" key={idx}>
                                    <div className="harvest-data-top mb-3">{data.cadastralNumber}</div>
                                    <Row className="harvest-data-bottom">
                                        <Col lg={2} ><div className="harvest-data-bottom-item"><span>{data.area} га</span><span>площадь</span></div></Col>
                                        <Col lg={fileNameCopyScaDB ? 5 : 7}><div className="harvest-data-bottom-item"><span>{typeofOwnership}</span><span>Тип принадлежности</span></div></Col>
                                        <Col lg={2}>
                                            {data.uploadLandPhotoPath ? (<>
                                                <div className="photo-technique-img loaded d-flex"> <img src={API_URL + uploadLandPhotoPath} alt={data.cadastralNumber} /></div>
                                             </>): (<></>)}
                                        </Col>
                                        {fileNameCopyScaDB ? <Col lg={2}><div className="harvest-data-bottom-item"><span>Загруженные файлы</span><span>{fileNameCopyScaDB}</span></div></Col> : ''}
                                        <Col lg={1}><div className="harvest-data-bottom-item remove">
                                        <Link to="" onClick={e => removeItemSetData(data._id, data.uploadLandPath, data.uploadLandPhotoPath)}><img src={remove} alt="remove"/></Link></div></Col>
                                    </Row>
                                </div>)
                        }) }
                    </CardBody>
                    <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} backdrop="static" keyboard={false} >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                        <h4>Подвердите удаление данных</h4>
                        </Modal.Body>
                        <Modal.Footer>
                        <p className="mb-4">Данные будут удалены и востановлению не подлежат</p>
                        <Row className="w-100">
                            <Col className="ps-0" lg={3}>
                                <button className='btn btn-primary mt-3 mb-4 w-100 text-center' onClick={removeItem}>Да</button>
                            </Col>
                            <Col className="ps-0" lg={3}>
                            <div className='btn-outline-wrap-green mt-3 mb-4 w-100 text-center'> 
                                <button className='btn btn-outline' onClick={handleCloseDeleteModal}>Нет</button>
                            </div>
                            </Col>
                        </Row>
                        </Modal.Footer>
                    </Modal>
                </Card>
            </>) : <></>}
        </Container>
    </>) : <></>}
    </> );
}
 
export default observer(LandForm);