import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Context } from "../../..";


import URL from "../../../config/default.json"

export const env = URL.ENVIRONMENT;

const OrgAccountSubMenu = ({
    class1,
    class2,
    class3 = 'organization-link px-4',
}: { class1?: string; class2?: string; class3?: string }) => {
    const {store} = useContext(Context);
    const developPlatformRole = store.bank && store.bank.developPlatformRole ? store.bank.developPlatformRole : false;
    const traderPlatformRole = store.bank && store.bank.traderPlatformRole ? store.bank.traderPlatformRole : false;
    const validForm = store?.user.isTokeniqueUserApprove || (store.bank && store.bank.isFormValidated && store.bank.isFormValid);
    
    return (
        <div className='organization-links'>
            <Container>
                <div className='organization-link-box'>
                    <Link className={class1} to="/account">Документы</Link>
                    {validForm ? (<Link className={class2} to="/information">Информация Производитель</Link>) : ''}
                    {validForm ? (<Link className={class3} to="/trader">Информация Трейдер</Link>) : ''}
                </div>
            </Container>
        </div>
        
    );
};

 
export default OrgAccountSubMenu;