import $api from "../http";
import {AxiosResponse} from 'axios';
import {ReceiveResponse} from '../models/response/ReceiveResponse';

export default class ReceiveInfoService {
    static async saveReceiveInfoService(
        email: string,
        user: string,
        typeOfReceive: string,
        powerOfReceive: string,
        laboratories: string,
        laboratoriesDescription: string,
        dateAdded: string,
        ): Promise<AxiosResponse<ReceiveResponse>> {

        return $api.post<ReceiveResponse>('/saveReceive',{email, user, typeOfReceive, powerOfReceive, laboratories, laboratoriesDescription, dateAdded})
    }
    static async removeReceiveItem(
        email: string,
        itemId: string,
        ): Promise<AxiosResponse<ReceiveResponse>> {

        return $api.post<ReceiveResponse>('/removeReceiveItem', {email, itemId})
    }
}