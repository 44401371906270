import $api from "../http";
import {AxiosResponse} from 'axios';
import {StorageResponse} from '../models/response/StorageResponse';

export default class StorageInfoService {
    static async saveStorageInfoService(
        email: string,
        user: string,
        typeOfStorage: string,
        volumenOfStorage: string,
        volumenOfCorn: string,
        storageConditions: string,
        elevatorAddress: string,
        dateAdded: string,
        uploadStoragePhoto: any
        ): Promise<AxiosResponse<StorageResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('typeOfStorage', typeOfStorage);
        formData.append('volumenOfStorage', volumenOfStorage);
        formData.append('volumenOfCorn', volumenOfCorn);
        formData.append('storageConditions', storageConditions);
        formData.append('elevatorAddress', elevatorAddress);
        formData.append('dateAdded', dateAdded);

        for (let index = 0; index < uploadStoragePhoto.length; index++) {
            const photo = uploadStoragePhoto[index];
            formData.append('uploadStoragePhoto', photo);
        }
        

        return $api.post<StorageResponse>('/saveStorage', formData, {
            headers: {
                'enctype': 'multipart/form-data'
            }
        })
    }
    static async removeStorageItem(
        email: string,
        itemId: string,
        uploadStoragePhotoPath: [],
        ): Promise<AxiosResponse<StorageResponse>> {

        return $api.post<StorageResponse>('/removeStorageItem', {email, itemId, uploadStoragePhotoPath})
    }
}