import {FC, useContext} from 'react';
import {observer} from "mobx-react-lite";

import {Context} from "../../index";
import CfaAccountSubMenu from '../account/parts/CfaAccountSubMenu';
import { Card, CardBody, CardTitle, Col, Container, Row } from 'react-bootstrap';

import emitImage from "../../img/icons/emiter-default.svg"
import lock from "../../img/icons/lock.svg";
import { Link } from 'react-router-dom';
import transactionList from '../../helpers/transactionList';
import myCfaList from '../../helpers/myCfaList';
import { useAllMyCFA } from '../queries/useAllMyCFA';

const MyCfa:FC = () => {
    const {store} = useContext(Context);
    let myCfa = store.myCfa;
    const {data} = useAllMyCFA();
    let newTransactionList:any = {};
    let newCfaList:any = []
    if (data && data.myCfaInfo) {
        newTransactionList = transactionList(data.myCfaInfo);
        newCfaList = myCfaList(data.myCfaInfo);
    }
    
    return (<>
            {store.isAuth && store.user.isActivated ? (<>
                <CfaAccountSubMenu class1="organization-link px-4 active" class2="organization-link px-4" class3="organization-link px-4"/>
                {myCfa && newCfaList ? (<>
                <Container className='mb-5'><h2 className='mt-5'>Мои ЦФА</h2>
                    <Row className="cfa-table-header mt-5 me-0 ms-0">
                        <Col lg={4}>Наименование</Col>
                        <Col lg={3}>Эмитент</Col>
                        <Col className="text-center" lg={1}>Количество</Col>
                        <Col className="text-end" lg={1}>Дата погашения</Col>
                        <Col className="text-end pe-4" lg={1}>Цена погашения</Col>
                        <Col className="text-center" lg={2}>Сумма</Col>
                    </Row>
                    
                    {newCfaList && newCfaList.length > 0 ? newCfaList.map(function(data:any, idx:any){
                        return (<>
                            {data.statusOfTransaction === 'buy' ? (<>
                            <Row key={data._id} className="align-items-center cfa-table-body mt-4 me-0 ms-0">
                                <Col className="ps-0" lg={4}>
                                    <Link className="detail-cfa-link" to={`/myCfaDetail/`+ myCfa.id + `/` + data._id}>
                                        <div className='cfa-table-title'>
                                            <div className='cfa-table-sub-title'>Digital-Grain_{data.ticker}</div>
                                            <div className='cfa-table-name'>{data.releaseName}</div>
                                        </div>
                                    </Link>
                                </Col>
                                <Col lg={3} className='align-items-center'><img className="me-3" src={emitImage} alt="Эмитент"/><span>{data.emitName}</span></Col>
                                <Col className="text-center" lg={1}>{data.sendAmount} 
                                {data.secondarySellAmount ? <>
                                        <img className="ms-2" src={lock} alt="amount"/> <span className='color-gray'>{data.secondarySellAmount}</span>
                                    </> : <></>}
                                </Col>
                                <Col className="text-end text-center" lg={1}>{data.dateOfEmition}</Col>
                                <Col className="text-center" lg={1}>{data.emitPrice} &#8381;</Col>
                                <Col className="text-center cmpleate-price-cfa primary-border" lg={2}>{data.totalEmitPrice} &#8381;</Col>
                            </Row>
                            </>) : (<></>)}
                        </>);
                    }) : (<></>)
                    }

                    <CardTitle className='mt-5'>Последние операции с ЦФА</CardTitle>
                    <Card className="mb-3">
                        <CardBody>
                            <div className='cfa-operation-day'>
                                <div className='cfa-operation-title'>
                                    <Row className="cfa-table-header me-0 ms-0">
                                        <Col className="text-end" lg={9}></Col>
                                        <Col className="text-center" lg={1}>Количество</Col>
                                        <Col className="text-center" lg={2}>Сумма сделки</Col>
                                    </Row>
                                </div>
                                <div className='cfa-operation-day-item'>
                                    {Object.keys(newTransactionList).map((keyName, i) => (<>
                                        {newTransactionList[keyName].length > 0 ? (<div key={keyName} className='cfa-operation-day-item-data mt-4'>{keyName}</div>) : (<></>)}
                                        {newTransactionList[keyName].length > 0 ? newTransactionList[keyName].map(function(data:any, idx:any){
                                            let statusTransaction = data.statusOfTransaction;
                                            // let dateOfEmition = data.dateOfEmition ? new Date(data.dateOfEmition.split('.')[1]+'/'+data.dateOfEmition.split('.')[0]+'/'+data.dateOfEmition.split('.')[2]) : new Date();
                                            // let dateEndCollection = data.dateEndCollection ? new Date(data.dateEndCollection.split('.')[1]+'/'+data.dateEndCollection.split('.')[0]+'/'+data.dateEndCollection.split('.')[2]) : new Date();
                                            // let dateStartRelease = data.dateStartRelease ? new Date(data.dateStartRelease.split('.')[1]+'/'+data.dateStartRelease.split('.')[0]+'/'+data.dateStartRelease.split('.')[2]) : new Date();

                                            let satausCFA = 'Средства заблокированы до окончания срока сбора заявок';
                                            let compleateStatus = 'inprogress'
                                            let topTitle = 'Продажа';
                                            let topIcon = '+';
                                            if (statusTransaction === 'buy') {
                                                topTitle = 'Первичное приобретение';
                                                topIcon = '-';
                                            }
                                            if (data.status === 'released') {
                                                compleateStatus = 'released';
                                                satausCFA = 'ЦФА выпущен и доступен для вторичного обращения. Транзакция успешно завершена';
                                            } else if ( data.status === 'active') {
                                                satausCFA = 'ЦФА активен, и готов ко вторичному обращению';
                                                compleateStatus = 'active';
                                            } else if (data.status === 'not_executed' ){
                                                satausCFA = 'Не выполнены условия выпуска'
                                                compleateStatus = 'not_executed'
                                            } else if (data.status === 'refused' ){
                                                satausCFA = 'ОИС отклонил заявку'
                                                compleateStatus = 'refused'
                                            } else if (data.status === 'redeem_started' ){
                                                satausCFA = 'Начат процесс погашения ЦФА. Средства будуд зачислены на Ваш счет в ближайшее время.'
                                                compleateStatus = 'active'
                                                topTitle = 'Начат процесс погашения ЦФА';
                                            }

                                            if (data.status === 'redeemed' ) {
                                                topTitle = 'ЦФА погашен';
                                                topIcon = '+';
                                                satausCFA = 'ЦФА погашен. Средства зачислены на Ваш счет.';
                                                compleateStatus = 'active';
                                            }

                                            return (<>
                                                <Row key={data._id}  className={statusTransaction === 'buy' ? 'align-items-center cfa-table-body mt-4 me-0 ms-0' : 'align-items-center cfa-table-body mt-4 me-0 ms-0 sell'}>
                                                    <Col className="ps-0" lg={3}>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='cfa-table-sub-time me-3'>{new Date(data.dateAdded).toISOString().slice(11, 16).split('-').join(':')}</div>
                                                            <img className="me-3 field-img" src={emitImage} alt="Эмитент"/>
                                                            <div>
                                                                <div className='cfa-table-sub-title'>Digital-Grain_{data.ticker}</div>
                                                                <div className='cfa-table-name'>{data.releaseName}</div>
                                                            </div>
                                                        </div>
                                                </Col>
                                                    <Col lg={6} className={compleateStatus + ` cfa-table-title`}><span>{topTitle}</span>
                                                        <span className="text-small d-block">{satausCFA}</span>
                                                    </Col>
                                                    <Col className="text-end pe-4" lg={1}>{data.sendAmount} шт.</Col>
                                                    <Col className={compleateStatus + ` text-center sell-price`} lg={2}>{topIcon} {data.sendAmount * data.price} &#8381;</Col>
                                                </Row>
                                            </>);
                                        }) : (<></>)}
                                    </>))}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
                </>) : (<Container className='text-center'>
                            <h3 className='my-5'>{store.user.name} {store.user.patronymic}, у вас еще нету операций связанных с покупкой/продажей ЦФА. <br/><br/></h3>
                            <p>Для покупки ЦФА Перейдите по ссылке <Link to="/dashboard-cfa">Витрина ЦФА</Link></p>
                        </Container>)}

            </>) : (<Container className='text-center'>
                        <h3 className='my-5'>{store.user.name}, добро пожаловать на платформу. <br/><br/>
                        Для продолжения регистрации перейдите, пожалуйста, по ссылке из письма, отправленного на указанный Вами адрес электронной почты.</h3>
                    </Container>)}
        </>
    );
}
 
export default observer(MyCfa);