import React, { useState, forwardRef, useEffect } from 'react';
import { Dropdown, CloseButton, FormLabel } from 'react-bootstrap';

interface CustomToggleProps {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

interface DropdownSelectBoxProps {
  resetTrigger: number;
}

const CustomToggle = forwardRef<HTMLDivElement, CustomToggleProps>(
  ({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="form-control d-flex align-items-center justify-content-between"
      style={{ cursor: 'pointer' }}
    >
      <div className="d-flex flex-wrap align-items-center">
        {children}
      </div>
      <span className="dropdown-arrow">&#9662;</span>
    </div>
  )
);
CustomToggle.displayName = 'CustomToggle';

const DropdownSelectBox: React.FC<DropdownSelectBoxProps> = ({ resetTrigger }) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const dropdownItems: string[] = ['Высший сорт', 'Первый сорт', 'Второй сорт'];

  const handleSelect = (item: string): void => {
    if (!selectedItems.includes(item)) {
      setSelectedItems(prevItems => [...prevItems, item]);
    }
  };

  const removeItem = (itemToRemove: string): void => {
    setSelectedItems(prevItems => prevItems.filter(item => item !== itemToRemove));
  };
  useEffect(() => {
    setSelectedItems([]);
  }, [resetTrigger]);
  return (
    <div className="position-relative mb-3">
      <FormLabel>Сорт</FormLabel>
      <Dropdown 
        show={showDropdown} 
        onToggle={(isOpen) => setShowDropdown(isOpen)}
      >
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-toggle">
          {selectedItems.length > 0 ? (
            selectedItems.map((item, index) => (
              <div 
                key={index} 
                className="badge bg-secondary me-2 mb-1 d-flex align-items-center"
              >
                <span>{item}</span>
                <CloseButton 
                  onClick={(e) => {
                    e.stopPropagation();
                    removeItem(item);
                  }} 
                  variant="white" 
                  className="ms-1" 
                />
              </div>
            ))
          ) : (
            <span className="text-muted">Выберите необходимое</span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ width: '100%' }}>
          {dropdownItems.map((item, index) => (
            <Dropdown.Item 
              key={index} 
              onClick={() => {
                handleSelect(item);
                setShowDropdown(false);
              }}
            >
              {item}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      
      <input 
        type="hidden" 
        name="sortOfProduction" 
        value={selectedItems.join(', ')}
      />
    </div>
  );
};

export default DropdownSelectBox;
