import React, { useRef, useState } from "react";
import { FC, useContext } from "react";
import { Accordion, Alert, Button, Card, CardBody, CardTitle, Col, Form, FormLabel, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

import {Context} from "../../index";
import AccountMenu from "./parts/AccountMenu";
import LoadData from "./parts/LoadData";
import ModalLink from "../modals/ModalLink";
import URL from "../../config/default.json"

import infoDocument from "../../img/icons/infoDocument.svg";
import selectFile from "../../img/icons/fileUpload.svg";
import envelope from "../../img/icons/Envelope.svg";
import StorageDetail from "./traderForms/mainPageDetail/storageDetail";
import ProductionDetail from "./traderForms/mainPageDetail/productionDetail";
import ReceiveDetail from "./traderForms/mainPageDetail/receiveDetail";
import UnloadingDetail from "./traderForms/mainPageDetail/unloadingDetail";
import SuppliesDetail from "./traderForms/mainPageDetail/suppliesDetail";

const AccountTraderInformation: FC = () => {
    const {store} = useContext(Context);
    const [validated, set_Validated] = useState(false);
    const fileLimit = URL.FILE_LIMIT;
    const maxLength = 7000;

    // Сconfirmation message
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e: any) => {e.preventDefault(); setShow(true)};
    const handleModal = useRef<HTMLAnchorElement>(null);

    const [textAreaCount, setCounterData] = useState<number>(0);

    const uploadInformationDocDBelemnt = store.infoTrader.uploadTraderMainPath || '';
    const [errorFileSizeInformationDoc, setErrorInformationDoc] = useState<boolean>(false);
    const filePickerLicenseInformationDoc = useRef<HTMLInputElement>(null);
    const [filenameInformationDoc, setFilenameInformationDoc] = useState<string>('');

    const [form_Data, set_Form_Data] = useState({
        email: store.infoTrader.email,
        infoDescription: store.infoTrader.infoDescription,
        infoDistrict: store.infoTrader.infoDistrict,
        uploadTraderMainPath: uploadInformationDocDBelemnt
    });
    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
        if (name === 'infoDescription') {
            setCounterData(e.target.value.length);
        }
    };

    const submitForm = (e: any) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            const infoDescription = form.elements.infoDescription.value;
            const infoDistrict = form.elements.infoDistrict.value;
            const uploadInfoMain = form.elements.uploadInfoMain.files[0];
            const zlak1 = form.elements.zlak1.checked;
            const zlak2 = form.elements.zlak2.checked;
            const zlak3 = form.elements.zlak3.checked;
            const zlak4 = form.elements.zlak4.checked;
            const zlak5 = form.elements.zlak5.checked;
            const zlak6 = form.elements.zlak6.checked;

            const zerno1 = form.elements.zerno1.checked;
            const zerno2 = form.elements.zerno2.checked;
            const zerno3 = form.elements.zerno3.checked;
            const zerno4 = form.elements.zerno4.checked;
            const zerno5 = form.elements.zerno5.checked;

            const maslo1 = form.elements.maslo1.checked;
            const maslo2 = form.elements.maslo2.checked;
            const maslo3 = form.elements.maslo3.checked;
            const maslo4 = form.elements.maslo4.checked;
            const maslo5 = form.elements.maslo5.checked;
            const maslo6 = form.elements.maslo6.checked;

            store.saveInfoTrader(store.user.email, store.user.id, infoDescription, infoDistrict, uploadInfoMain, zlak1, zlak2, zlak3, zlak4, zlak5, zlak6, zerno1, zerno2, zerno3, zerno4, zerno5, maslo1, maslo2, maslo3, maslo4, maslo5, maslo6);
            setFilenameInformationDoc('');
            if (handleModal.current) {
                handleModal.current.click();
            }
            if (filePickerLicenseInformationDoc.current) {
                filePickerLicenseInformationDoc.current.value = "";
            }
        }
        set_Validated(true);
        
    };

    const uploadHandleChnageInformationDoc = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorInformationDoc(true);
            
            if (filePickerLicenseInformationDoc.current) {
                filePickerLicenseInformationDoc.current.value = "";
            }
        } else {
            setFilenameInformationDoc(e.target.files[0].name);
            setErrorInformationDoc(false);
        }
    }

    const handlePickInformationDoc = () => {
        if (filePickerLicenseInformationDoc.current) {
            filePickerLicenseInformationDoc.current.click();
        }
    }

    const [form_DataChecbox, set_Form_DataChecbox] = useState({
        zlak1: store.infoTrader.zlak1 || false,
        zlak2: store.infoTrader.zlak2 || false,
        zlak3: store.infoTrader.zlak3 || false,
        zlak4: store.infoTrader.zlak4 || false,
        zlak5: store.infoTrader.zlak5 || false,
        zlak6: store.infoTrader.zlak6 || false,

        zerno1: store.infoTrader.zerno1 || false,
        zerno2: store.infoTrader.zerno2 || false,
        zerno3: store.infoTrader.zerno3 || false,
        zerno4: store.infoTrader.zerno4 || false,
        zerno5: store.infoTrader.zerno5 || false,

        maslo1: store.infoTrader.maslo1 || false,
        maslo2: store.infoTrader.maslo2 || false,
        maslo3: store.infoTrader.maslo3 || false,
        maslo4: store.infoTrader.maslo4 || false,
        maslo5: store.infoTrader.maslo5 || false,
        maslo6: store.infoTrader.maslo6 || false

    });

    const handleCheckboxMoney = (score: any, inputName: string) => {
        set_Form_DataChecbox({
            ...form_DataChecbox,
            [inputName]: score,
        });
    };

    return ( <>
        <AccountMenu/>
        <Form noValidate validated={validated} onSubmit={submitForm}>
            <Row>
                <Col lg={8}>
                    <CardTitle>Общая информация</CardTitle>
                    <Card className="mb-3 small-inputs">
                        <CardBody>
                            <Form.Group as={Col} className="mb-3 position-relative" controlId="validationReprDescriptio">
                                <FormLabel>Описание</FormLabel>
                                <Form.Control
                                    as="textarea"
                                    name="infoDescription"
                                    rows={10}
                                    value={form_Data.infoDescription}
                                    onChange={chngFn}
                                    className='w-100 legal-form-1'
                                    placeholder='Введите краткое описание своего производства, специфику и предложение'
                                    maxLength={maxLength}
                                />
                                <div className="text-counter">{textAreaCount} / {maxLength}</div>
                                <Form.Control.Feedback type="invalid">
                                    Введите корректные данные
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3" controlId="validationReprDistric">
                                <FormLabel>Регион деятельности</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="infoDistrict"
                                    value={form_Data.infoDistrict}
                                    onChange={chngFn}
                                    className='w-100 legal-form-1'
                                    placeholder='Введите регион деятельности'
                                />
                                <Form.Control.Feedback type="invalid">
                                    Введите корректные данные
                                </Form.Control.Feedback>
                            </Form.Group>

                            <FormLabel>Тип зерна</FormLabel>
                            <Accordion alwaysOpen>
                                <Row>
                                    <Col lg={4}>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Злаковые культуры</Accordion.Header>
                                        <Accordion.Body>
                                        <Form.Check
                                            type="checkbox"
                                            name="zlak1"
                                            label="01.11.11 Пшеница твердая"
                                            id="zlak1"
                                            className="radio"
                                            onChange={e => handleCheckboxMoney(!form_DataChecbox.zlak1, 'zlak1')}
                                            checked={form_DataChecbox.zlak1}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            name="zlak2"
                                            label="01.11.12 Злаковые культуры"
                                            id="zlak2"
                                            className="radio"
                                            onChange={e => handleCheckboxMoney(!form_DataChecbox.zlak2, 'zlak2')}
                                            checked={form_DataChecbox.zlak2}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            name="zlak3"
                                            label="01.11.20 Кукуруза"
                                            id="zlak3"
                                            className="radio"
                                            onChange={e => handleCheckboxMoney(!form_DataChecbox.zlak3, 'zlak3')}
                                            checked={form_DataChecbox.zlak3}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            name="zlak4"
                                            label="01.11.31 Ячмень"
                                            id="zlak4"
                                            className="radio"
                                            onChange={e => handleCheckboxMoney(!form_DataChecbox.zlak4, 'zlak4')}
                                            checked={form_DataChecbox.zlak4}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            name="zlak5"
                                            label="01.11.32 Рожь"
                                            id="zlak5"
                                            className="radio"
                                            onChange={e => handleCheckboxMoney(!form_DataChecbox.zlak5, 'zlak5')}
                                            checked={form_DataChecbox.zlak5}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            name="zlak6"
                                            label="01.11.33 Овес"
                                            id="zlak6"
                                            className="radio"
                                            onChange={e => handleCheckboxMoney(!form_DataChecbox.zlak6, 'zlak6')}
                                            checked={form_DataChecbox.zlak6}
                                        />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    </Col>
                                    <Col lg={4}>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Зернобобовые культуры</Accordion.Header>
                                        <Accordion.Body>
                                        <Form.Check
                                            type="checkbox"
                                            name="zerno1"
                                            label="01.11.71.110 Зерно фасоли"
                                            id="zerno1"
                                            className="radio"
                                            onChange={e => handleCheckboxMoney(!form_DataChecbox.zerno1, 'zerno1')}
                                            checked={form_DataChecbox.zerno1}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            name="zerno2"
                                            label="01.11.72.110 Зерно кормовых бобов"
                                            id="zerno2"
                                            className="radio"
                                            onChange={e => handleCheckboxMoney(!form_DataChecbox.zerno2, 'zerno2')}
                                            checked={form_DataChecbox.zerno2}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            name="zerno3"
                                            label="01.11.73.110 Зерно нута"
                                            id="zerno3"
                                            className="radio"
                                            onChange={e => handleCheckboxMoney(!form_DataChecbox.zerno3, 'zerno3')}
                                            checked={form_DataChecbox.zerno3}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            name="zerno4"
                                            label="01.11.74.110 Зерно чечевицы"
                                            id="zerno4"
                                            className="radio"
                                            onChange={e => handleCheckboxMoney(!form_DataChecbox.zerno4, 'zerno4')}
                                            checked={form_DataChecbox.zerno4}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            name="zerno5"
                                            label="01.11.75.110 Зерно гороха"
                                            id="zerno5"
                                            className="radio"
                                            onChange={e => handleCheckboxMoney(!form_DataChecbox.zerno5, 'zerno5')}
                                            checked={form_DataChecbox.zerno5}
                                        />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    </Col>
                                    <Col lg={4}>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Масличные культуры</Accordion.Header>
                                        <Accordion.Body>
                                        <Form.Check
                                            type="checkbox"
                                            name="maslo1"
                                            label="01.11.81.120 Бобы соевые для переработки"
                                            id="maslo1"
                                            className="radio"
                                            onChange={e => handleCheckboxMoney(!form_DataChecbox.maslo1, 'maslo1')}
                                            checked={form_DataChecbox.maslo1}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            name="maslo2"
                                            label="01.11.82 Арахис"
                                            id="maslo2"
                                            className="radio"
                                            onChange={e => handleCheckboxMoney(!form_DataChecbox.maslo2, 'maslo2')}
                                            checked={form_DataChecbox.maslo2}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            name="maslo3"
                                            label="01.11.91.120 Семена льна для переработки"
                                            id="maslo3"
                                            className="radio"
                                            onChange={e => handleCheckboxMoney(!form_DataChecbox.maslo3, 'maslo3')}
                                            checked={form_DataChecbox.maslo3}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            name="maslo4"
                                            label="01.11.92.120 Семена горчицы для переработки"
                                            id="maslo4"
                                            className="radio"
                                            onChange={e => handleCheckboxMoney(!form_DataChecbox.maslo4, 'maslo4')}
                                            checked={form_DataChecbox.maslo4}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            name="maslo5"
                                            label="01.11.94.120 Семена кунжута для переработки"
                                            id="maslo5"
                                            className="radio"
                                            onChange={e => handleCheckboxMoney(!form_DataChecbox.maslo5, 'maslo5')}
                                            checked={form_DataChecbox.maslo5}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            name="maslo6"
                                            label="01.11.95.120 Семена подсолнечника для переработки"
                                            id="maslo6"
                                            className="radio"
                                            onChange={e => handleCheckboxMoney(!form_DataChecbox.maslo6, 'maslo6')}
                                            checked={form_DataChecbox.maslo6}
                                        />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    </Col>
                                </Row>
                            </Accordion>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={4}>
                    <CardTitle>Открытые документы</CardTitle>
                    <Card className="mb-3">
                        <CardBody className="card-document">
                                {store.infoTrader.doc !== undefined && store.infoTrader.doc.length > 0 ? (<>
                                <div className="card-document-links filled">
                                    <Row>
                                    {store.infoTrader.doc.map(function(data, idx){
                                        const uploadTraderMainPathDBelemnt = data.uploadTraderMainPath;
                                        const fileNameCopyScaDB = uploadTraderMainPathDBelemnt ? uploadTraderMainPathDBelemnt.split('-f-n-').pop() : '';
                                        return (
                                                <Col lg={6} key={idx} className="select-document mb-4">
                                                    <div className="select-document-wrap">
                                                        {fileNameCopyScaDB}

                                                        <div className="align-self-end mt-5 d-flex justify-content-between">
                                                            <div>
                                                                <ModalLink action="success" />
                                                                <ModalLink action="download" />
                                                                <ModalLink action="edit" />
                                                            </div>
                                                            <div>
                                                                <ModalLink action="info" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        }) }
                                        <Col lg={6} className={store.infoTrader.doc !== undefined && store.infoTrader.doc.length < 4 ? 'select-document' : 'hidden'}>
                                            <div className="select-document-wrap">
                                                <div className="gray-text mb-5">Добавить другие документы</div>
                                                <div className="align-self-end d-flex justify-space-beetwen">
                                                    <Form.Group as={Col} controlId="validationLicenseInformationDoc">
                                                        <Form.Control 
                                                            type="file"
                                                            className="hidden"
                                                            onChange={uploadHandleChnageInformationDoc}
                                                            accept=".pdf, .jpg, .jpeg, .png, .heic"
                                                            name="uploadInfoMain"
                                                            ref={filePickerLicenseInformationDoc}
                                                        />
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <div className=" ">
                                                                <div className="me-4 fileIcon" onClick={handlePickInformationDoc}><img src={selectFile} alt="Select file"/></div>
                                                            </div>
                                                            
                                                            <div className="card-document-link">
                                                                <Link to="/"><img src={infoDocument} alt="File Information"/></Link>
                                                            </div>
                                                        </div>
                                                        <div className="fileLable">{filenameInformationDoc}</div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                        {errorFileSizeInformationDoc  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                                        {store.infoTrader.doc !== undefined && store.infoTrader.doc.length === 4 ? (
                                            <Alert className="d-flex w-100 m-0 py-0 text-small" variant="warning">Максимальное количество файлов добавлено</Alert>
                                        ) : (<div className="small-txt small-size mt-2">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>)}
                                        </Col>
                                        
                                    </Row>
                                    </div>
                                    </>) : (
                                        <>
                                        <span className="card-document-title">Добавить документ</span>
                                        <div className="card-document-links">
                                            <div className="card-document-link">
                                                <Form.Group as={Col} controlId="validationLicenseInformationDoc">
                                                    <Form.Control 
                                                        type="file"
                                                        className="hidden"
                                                        onChange={uploadHandleChnageInformationDoc}
                                                        accept=".pdf, .jpg, .jpeg, .png, .heic"
                                                        name="uploadInfoMain"
                                                        ref={filePickerLicenseInformationDoc}
                                                    />
                                                    
                                                    <div className="filePicker d-flex align-items-center">
                                                        <div className="me-4 fileIcon" onClick={handlePickInformationDoc}><img src={selectFile} alt="Select file"/></div>
                                                        <div className="fileLable">{filenameInformationDoc}
                                                            <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                                        </div>
                                                        
                                                        {errorFileSizeInformationDoc  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                                                    </div>
                                                </Form.Group>
                                            </div>
                                            <div className="card-document-link">
                                                <Link to="/"><img src={infoDocument} alt="File Information"/></Link>
                                            </div>
                                        </div>
                                        </>
                                    )}
                            
                        </CardBody>
                    </Card>
                </Col>
                <Col><Button variant="secondary" id="form-submit" type="submit">Сохранить</Button></Col>
            </Row>
            <Link to="/" onClick={handleShow} ref={handleModal}></Link>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                <h4>Даные добавлены</h4>
                <button className='btn btn-primary d-flex align-item-center mt-4' onClick={handleClose}>Закрыть</button>
                </Modal.Body>
                <Modal.Footer>
                <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                <div className='btn-outline-wrap-green mt-3 mb-4'>
                <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </Form>
        <CardTitle className="mt-5">Инфраструктура</CardTitle>
        <Card>
            <CardBody>
                <Row className="align-items-center">
                    {store.storageInfo.email && store.storageInfo.storage && store.storageInfo.storage.length > 0 ? (
                        <Col lg={12}>
                            <div className="card-link-container mb-4">
                                <StorageDetail />
                            </div>
                        </Col>) : (<></>)}
                    {store.productionInfo.email && store.productionInfo.production && store.productionInfo.production.length > 0 ? (
                    <Col lg={12}>
                        <div className="card-link-container mb-4">
                            <ProductionDetail />
                        </div>
                    </Col>) : (<></>)}
                    {store.storageInfo.email && store.productionInfo.email && store.storageInfo.storage && store.storageInfo.storage.length > 0 && store.productionInfo.production && store.productionInfo.production.length > 0 ? (<></>) : (<>
                        <Col lg={8}>
                            {store.storageInfo.email && store.storageInfo.storage.length > 0  ? (<></>):(
                                <div className="card-link-container mb-4">
                                    <Link to="/storage"><img className="me-3" src={selectFile} alt="Добавить хранилище"/>Добавить хранилище</Link>
                                    </div>
                            )}
                            {store.productionInfo.email && store.productionInfo.production && store.productionInfo.production.length > 0 ? (<></>) : (
                                <div className="card-link-container">
                                    <Link to="/production"> <img className="me-3" src={selectFile} alt="Добавить производство"/>Добавить производство</Link>
                                </div>
                            )}
                        </Col>
                        <Col lg={4}>
                            <LoadData/>
                        </Col>
                    </>)}
                </Row>
            </CardBody>
        </Card>
        <CardTitle className="mt-5">Логистика</CardTitle>
        <Card>
            <CardBody>
                <Row className="align-items-center">
                    {store.receiveInfo.email && store.receiveInfo.receive && store.receiveInfo.receive.length > 0 ? (
                    <Col className="align-self-start" lg={store.unloadingInfo && store.unloadingInfo.unloading && store.unloadingInfo.unloading.length > 0 ? 6 : 12} >
                        <div className="card-link-container mb-4">
                            <ReceiveDetail />
                        </div>
                    </Col>) : (<></>)}
                    {store.unloadingInfo.email && store.unloadingInfo.unloading && store.unloadingInfo.unloading.length > 0 ? (
                    <Col className="align-self-start" lg={store.receiveInfo && store.receiveInfo.receive && store.receiveInfo.receive.length > 0 ? 6 : 12} >
                        <div className="card-link-container mb-4">
                            <UnloadingDetail />
                        </div>
                    </Col>) : (<></>)}
                    {store.suppliesInfo.email && store.suppliesInfo.supplies && store.suppliesInfo.supplies.length > 0  ? (
                    <Col lg={12}>
                        <div className="card-link-container mb-4">
                            <SuppliesDetail />
                        </div>
                    </Col>) : (<></>)}
                    

                    <Col lg={8}>
                        {store.receiveInfo.email && store.receiveInfo.receive && store.receiveInfo.receive.length > 0 ? (<></>) : (<div className="card-link-container mb-4">
                            <Link to="/receive"><img className="me-3" src={selectFile} alt="Добавить мощности по приемке"/> Добавить мощности по приемке</Link>
                        </div>)}
                        {store.unloadingInfo.email && store.unloadingInfo.unloading && store.unloadingInfo.unloading.length > 0 ? (<></>) : (<div className="card-link-container mb-4">
                            <Link to="/unloading"><img className="me-3" src={selectFile} alt="Добавить мощности по отгрузке"/> Добавить мощности по отгрузке</Link>
                            </div>)}
                        {store.suppliesInfo.email && store.suppliesInfo.supplies && store.suppliesInfo.supplies.length > 0 ? (<></>) : (<div className="card-link-container">
                            <Link to="/supplies"> <img className="me-3" src={selectFile} alt="Добавить складские запасы"/> Добавить складские запасы</Link>
                        </div>)}
                    </Col>
                    {store.receiveInfo.email && store.receiveInfo.receive && store.receiveInfo.receive.length > 0 && store.unloadingInfo.email && store.unloadingInfo.unloading && store.unloadingInfo.unloading.length > 0 && store.suppliesInfo.email && store.suppliesInfo.supplies && store.suppliesInfo.supplies.length > 0 ? (<></>) : (
                        <Col lg={4}>
                            <LoadData/>
                        </Col>
                    )}
                    
                </Row>
            </CardBody>
        </Card>
    </> );
}
 
export default observer(AccountTraderInformation);