import React, { FC, useContext, useRef, useState } from "react";
import AccountMenu from "../parts/AccountMenu";
import {Context} from "../../../index";
import { observer } from "mobx-react-lite";
import { Form, Card, CardBody, CardTitle, Container, FormLabel, Col, Button, Row, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModalDefault from "../../modals/ModalDefault";
import modalImage from "../../../img/icons/Info-circle.svg"
import remove from "../../../img/icons/Trash.svg";
import selectFile from "../../../img/icons/fileUpload.svg";

import Modal from 'react-bootstrap/Modal';
import envelope from "../../../img/icons/Envelope.svg";
import OrgAccountSubMenu from "../parts/OrgAccountSubMenu";
import ModalGos from "../../modals/ModalGos";

import URL from "../../../config/default.json";
export const API_URL = URL.SERVER_URL_BASE;


const StorageForm: FC = () => {
    const {store} = useContext(Context);
    const maxLength = 7000;
    const [validated, set_Validated] = useState(false);
    const filePickerStorage = useRef<HTMLInputElement>(null);
    const handleModal = useRef<HTMLAnchorElement>(null);

    // Confirm modal
    const [deleteId, setDeleteId] = useState('');
    const [deletePath, setDeletePath] = useState('');
    const [showDeleteModal, setDeleteModal] = useState(false);
    const handleCloseDeleteModal = () => setDeleteModal(false);

    const [textAreaCount, setCounterData] = useState<number>(0);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e: any) => {e.preventDefault(); setShow(true)};

    const [form_Data, set_Form_Data] = useState({
        volumenOfStorage: '',
        storageConditions: '',
        volumenOfCorn: '',
        elevatorAddress: ''
    })

    const submitForm = (e: any) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            const typeOfStorage = form.elements.typeOfStorage.value;
            const volumenOfStorage = form.elements.volumenOfStorage.value;
            const volumenOfCorn = form.elements.volumenOfCorn.value;
            const storageConditions = form.elements.storageConditions.value;
            const elevatorAddress = form.elements.elevatorAddress.value;
            const dateAdded = form.elements.dateAdded.value;
            const uploadStoragePhotos = form.elements.uploadStoragePhotos.files;

            store.saveStorageInfo(store.user.email, store.user.id, typeOfStorage, volumenOfStorage, volumenOfCorn, storageConditions, elevatorAddress, dateAdded, uploadStoragePhotos);
            set_Validated(false);
            set_Form_Data({
                volumenOfStorage: '',
                storageConditions: '',
                volumenOfCorn: '',
                elevatorAddress: ''
            });

            setFilenameConfirmDoc(['Загрузить фотографию']);
            setImageStorageURL('');

            if (filePickerStorage.current) {
                filePickerStorage.current.value = "";
            }
            if (handleModal.current) {
                handleModal.current.click();
            }
        } else {
            set_Validated(true);
        }
        
    };

    const removeItem = () => {
        store.removeStorageItem(store.user.email, deleteId, deletePath);
        setDeleteModal(false);
    }

    const removeItemSetData = (id:any, path:string) => {
        setDeleteId(id);
        setDeletePath(path);
        setDeleteModal(true);
    }

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
        if (name === 'qualityDescription') {
            setCounterData(e.target.value.length);
        }
    };

    const [classHide, setClassHide] = useState<string>('d-none');

    const [form_Raio, set_Form_Radio] = useState({
        isTypeOfStorage1: true,
        isTypeOfStorage2: false,
        isTypeOfStorage3: false,
    });

    const radioChange = function(par1:boolean, par2:boolean, par3:boolean) {
        set_Form_Radio({
            isTypeOfStorage1: par1,
            isTypeOfStorage2: par2,
            isTypeOfStorage3: par3,
        })
        if (par2 === true) {
            setClassHide('');
        } else {
            setClassHide('d-none');
            set_Form_Data({
                ...form_Data,
                elevatorAddress: ''
            });
        }
    }

    const fileLimitPhotoConfig = URL.FILE_LIMIT_PHOTO;
    const fileLimitPhoto = fileLimitPhotoConfig;
    const photoAmountLimit = 5;
    const filePickerStoragePhoto = useRef<HTMLInputElement>(null);
    const [filenameStoragePhoto, setFilenameConfirmDoc] = useState<any>(['Загрузить фотографию']);
    const [errorFileSizeConfirmDoc, setErrorConfirmDoc] = useState<string>('');
    const [errorLimitCount, setErrorLimitCount] = useState<boolean>(false);
    const [imageStorageURL, setImageStorageURL] = useState<any>([]);

    function readFileAsText(file:any){
        return new Promise(function(resolve,reject){
            let fr = new FileReader();
            fr.onload = function(){
                resolve(fr.result);
            };
            fr.onerror = function(){
                reject(fr);
            };
            fr.readAsDataURL(file);
        });
    }

    const uploadHandleChnageStoragePhoto = (e: any) => {
        let photos = e.target.files;
        var photoArr: any[] = [];
        var photoName: any[] = [];
        var errOneOfFile:string = '';
        if (photos.length > photoAmountLimit) {
            setErrorLimitCount(true);
            if (filePickerStoragePhoto.current) {
                filePickerStoragePhoto.current.value = "";
            }
        } else {
            setErrorLimitCount(false);
            Array.from(photos).forEach((photo: any) => {
                if (photo.size > fileLimitPhoto) {
                    errOneOfFile = photo.name;
                } else {
                    photoName.push(photo.name);
                    photoArr.push(readFileAsText(photo));

                    setFilenameConfirmDoc(photoName);
                    Promise.all(photoArr).then((values) => {
                        setImageStorageURL(values);
                    });
                }
            });
            setErrorConfirmDoc(errOneOfFile);
        }
    }

    const handlePickStoragePhoto = () => {
        if (filePickerStoragePhoto.current) {
            filePickerStoragePhoto.current.click();
        }
    }

    return ( <>
     {store.isAuth ? (<>
        <OrgAccountSubMenu class1="organization-link px-4" class2="organization-link px-4" class3="organization-link px-4 active"/>
        <Container className='mb-5'>
            <AccountMenu/>
            <Form noValidate validated={validated} onSubmit={submitForm}>
                <div className="load-harvest-doc my-5 text-center d-flex justify-content-center align-items-baseline">
                    <ModalDefault classButton="text-center" name="Заполнить автоматически через Росреестр" variant="primary"/>
                    <ModalGos classImg="btn btn-link p-2 border-link ms-3" image={modalImage}/>
                </div>
                <CardTitle>Добавить хранилище</CardTitle>
                <Card className="mb-3">
                    <CardBody>
                        <Form.Group as={Col} className="mb-3" controlId="validationplantasName">
                            <FormLabel className='required'>Тип хранилища</FormLabel>
                            <Form.Check
                                type="radio"
                                name="typeOfStorage"
                                value="sklad"
                                label="Напольный склад"
                                id="isTypeOfStorage1"
                                className="radio"
                                checked={form_Raio.isTypeOfStorage1}
                                onChange={e => radioChange(true, false, false)}
                                required
                            />
                            <Form.Check
                                type="radio"
                                name="typeOfStorage"
                                value="elevator"
                                label="Элеватор"
                                id="isTypeOfStorage2"
                                className="radio"
                                checked={form_Raio.isTypeOfStorage2}
                                onChange={e => radioChange(false, true, false)}
                                required
                            />
                            <Form.Check
                                type="radio"
                                name="typeOfStorage"
                                value="bigsklad"
                                label="Склад для бигбегов/мешкотары"
                                id="isTypeOfStorage3"
                                className="radio"
                                checked={form_Raio.isTypeOfStorage3}
                                onChange={e => radioChange(false, false, true)}
                                required
                            />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" controlId="validationreprvolumen">
                            <FormLabel className='required'>Объем хранилища</FormLabel>
                            <Form.Control
                                type="number"
                                step="0.01"
                                name="volumenOfStorage"
                                className='w-100 legal-form-1'
                                value={form_Data.volumenOfStorage}
                                onChange={chngFn}
                                required
                                placeholder='Укажите объем хранилища'
                                onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3 position-relative" controlId="validationqualityDescription">
                            <FormLabel>Объем зерна на дату</FormLabel>
                            <Form.Control
                                type="number"
                                step="0.01"
                                name="volumenOfCorn"
                                className='w-100 legal-form-1'
                                value={form_Data.volumenOfCorn}
                                onChange={chngFn}
                                placeholder='Укажите объем зерна, хранимого на текущий момент'
                                onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className={`mb-3 position-relative ${classHide}`} controlId="validationqualityDescription">
                            <FormLabel>Местоположение элеватора</FormLabel>
                            <Form.Control
                                type="text"
                                name="elevatorAddress"
                                className='w-100 legal-form-1'
                                value={form_Data.elevatorAddress}
                                onChange={chngFn}
                                placeholder='Начните ввод адреса'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3 position-relative" controlId="validationqualityDescription">
                            <FormLabel className='required'>Условия хранения</FormLabel>
                            <Form.Control
                                as="textarea"
                                name="storageConditions"
                                rows={6}
                                className='w-100 legal-form-1'
                                required
                                value={form_Data.storageConditions}
                                onChange={chngFn}
                                placeholder='Укажите условия хранения урожая в выбранном хранилище'
                                maxLength={maxLength}
                            />
                            <div className="text-counter">{textAreaCount} / {maxLength}</div>
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" controlId="validationLicenseConfirmDoc">
                            <Form.Control 
                                type="file"
                                className="hidden"
                                multiple
                                onChange={uploadHandleChnageStoragePhoto}
                                accept=".jpg, .jpeg, .png, .heic"
                                name="uploadStoragePhotos"
                                ref={filePickerStoragePhoto}
                            />
                            
                            <div className="filePicker d-flex align-items-center">
                                <div className="me-4 fileIcon" onClick={handlePickStoragePhoto}><img src={selectFile} alt="Select file"/></div>
                                <div className="fileLable">{filenameStoragePhoto.map(function (data:string, idx:any){return (<div key={idx} className="me-4">{data}</div>)})}
                                    <div className="small-txt">Формат файла должен соответствовать [.jpg, .jpeg, .png, .heic]</div>
                                </div>
                                
                                {errorFileSizeConfirmDoc !== '' ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла {errorFileSizeConfirmDoc} больше лимита в {fileLimitPhoto / URL.FILE_LIMIT_PHOTO} Mb</Alert> : ''}
                                {errorLimitCount ? <Alert className="d-flex w-100 mt-3" variant="danger">Количество файлов больше лимита в {photoAmountLimit} штук</Alert> : ''}
                            </div>
                            {imageStorageURL ? (
                                imageStorageURL.map(function(data:any){
                                    return (<>
                                        <div className="d-inline-block me-4"><div className="photo-land-img"><img src={data} alt=''/></div></div>
                                    </>);
                                })
                            ) : (<></>)}
                        </Form.Group>
                        <Form.Control
                            type="text"
                            name="dateAdded"
                            value={new Date().toISOString().slice(0, 10).split('-').reverse().join('/')}
                            className='hidden'
                            readOnly
                        />
                        <Button className="mt-2" variant="secondary" id="form-submit" type="submit">Сохранить</Button>
                    </CardBody>
                </Card>
                <Link to="/" onClick={handleShow} ref={handleModal}></Link>
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                    <h4>Даные добавлены</h4>
                    <button className='btn btn-primary d-flex align-item-center mt-4' onClick={handleClose}>Закрыть</button>
                    </Modal.Body>
                    <Modal.Footer>
                    <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                    <div className='btn-outline-wrap-green mt-3 mb-4'>
                    <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Form>
            {store.storageInfo && store.storageInfo.storage && store.storageInfo.storage.length > 0 ? (<>
                <CardTitle className="mt-5">Добавленные хранилища</CardTitle>
                <Card className="mb-3">
                    <CardBody>
                        {store.storageInfo.storage.map(function(data, idx){

                            let typeOfStorage = 'Напольный склад';
                            if (data.typeOfStorage === 'elevator') 
                                typeOfStorage = 'Элеватор';
                            else if (data.typeOfStorage === 'bigsklad')
                                typeOfStorage = 'Склад для бигбегов/мешкотары';

                            return (<div className="harvest-data" key={idx}>
                                    <div className="harvest-data-top mb-3 d-flex justify-content-between align-items-center">{typeOfStorage} <Link to="" onClick={e => removeItemSetData(data._id, data.uploadStoragePhotoPath)}><img src={remove} alt="remove"/></Link></div>
                                    <Row className="harvest-data-bottom">
                                        <Col lg={2} ><div className="harvest-data-bottom-item"><span>{data.volumenOfStorage} л</span><span>полезный объем</span></div></Col>
                                        <Col lg={3}><div className="harvest-data-bottom-item"><span>{data.volumenOfCorn} л</span><span>объем хранимого зерна</span></div></Col>
                                        <Col lg={data.elevatorAddress ? 2 : 4}><div className="harvest-data-bottom-item"><span>Условия хранения</span><span>{data.storageConditions}</span></div></Col>
                                        {data.elevatorAddress ? <Col lg={2}><div className="harvest-data-bottom-item"><span>{data.elevatorAddress}</span><span>местоположение</span></div></Col> : <></>}
                                        
                                        <Col lg={3}><div className="photo-storage-img loaded d-flex">
                                            {data.uploadStoragePhotoPath.map(function (photo:any){
                                                const uploadTechniquePhotoPath = photo ? photo.split('uploads/').pop() : '';
                                                return (<img src={API_URL + uploadTechniquePhotoPath} alt={data.typeOfStorage} />);
                                            })}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>)
                        }) }
                    </CardBody>
                    <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} backdrop="static" keyboard={false} >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                        <h4>Подвердите удаление данных</h4>
                        </Modal.Body>
                        <Modal.Footer>
                        <p className="mb-4">Данные будут удалены и востановлению не подлежат</p>
                        <Row className="w-100">
                            <Col className="ps-0" lg={3}>
                                <button className='btn btn-primary mt-3 mb-4 w-100 text-center' onClick={removeItem}>Да</button>
                            </Col>
                            <Col className="ps-0" lg={3}>
                            <div className='btn-outline-wrap-green mt-3 mb-4 w-100 text-center'> 
                                <button className='btn btn-outline' onClick={handleCloseDeleteModal}>Нет</button>
                            </div>
                            </Col>
                        </Row>
                        </Modal.Footer>
                    </Modal>
                </Card>
            </>) : <></>}
        </Container>
    </>) : <></>}
    </> );
}
 
export default observer(StorageForm);