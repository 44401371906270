import React, { FC, useContext} from "react";
import {Context} from "../../../../index";
import { observer } from "mobx-react-lite";
import { Col, Row} from "react-bootstrap";
import { Link } from "react-router-dom";

import selectFile from "../../../../img/icons/fileUpload.svg";
import infoCount from "../../../../img/icons/infoCount.svg";
import mark from "../../../../img/icons/mark.svg";
import markGray from "../../../../img/icons/mark-gray.svg";

import URL from "../../../../config/default.json";

export const API_URL = URL.SERVER_URL_BASE;

const UnloadingDetail: FC = () => {
    const {store} = useContext(Context);
    function showUnloadingItem(count:any, variant = '') {
        var unloading:any = store.unloadingInfo.unloading;
        return (<>
            <div className="harvest-data p-0 pt-2 w-100">
                <Row className="harvest-data-bottom">
                    <Col xs={{ order: 2 }} lg={12}><Row>
                        {unloading.map(function(data:any, idx:any){
                            let typeOfUnloading = 'Автомобильный транспорт';
                            if (data.typeOfUnloading === 'train') 
                                typeOfUnloading = 'Железнодорожный транспорт';
                            else if (data.typeOfUnloading === 'port')
                                typeOfUnloading = 'Портовый комплекс';

                            let typeOfContainer = '';
                            if (data.typeOfContainer1) typeOfContainer = typeOfContainer + 'Отгрузка насыпью, ';
                            if (data.typeOfContainer2) typeOfContainer = typeOfContainer + 'Огрузка мешкотарой, ';
                            if (data.typeOfContainer3) typeOfContainer = typeOfContainer + 'Отгрузка биг-бегами, ';
                            if (data.typeOfContainer4) typeOfContainer = typeOfContainer + 'Отгрузка в контейнеры, ';
                            if (data.typeOfContainer5) typeOfContainer = typeOfContainer + 'Наличие КМПВ, ';
                            if (typeOfContainer !== '') {
                                typeOfContainer = typeOfContainer.slice(0, -2);
                            }
                            return (
                                <div className="harvest-data p-0 pt-2" key={data._id + '-' + idx}>
                                    <Row className="harvest-data-bottom align-items-center">
                                        <Col lg={4} ><div className="harvest-data-bottom-item info"><span><img className="me-2" src={infoCount} alt="="/>{data.powerOfUnloading} мощность</span><span>{typeOfUnloading}</span></div></Col>
                                        <Col lg={6} ><div className="harvest-data-bottom-item-info"><span>Тип тары для отгрузки</span></div>
                                        <span className="harvest-data-bottom-item-info"><span></span><span>{typeOfContainer}</span></span></Col>
                                        <Col lg={2}><div className="harvest-data-bottom-item-info text-center">
                                            {data.scalesOfUpload === 'yes' ? <>
                                                <span><img className="me-2" src={mark} alt="лаборатории"/></span>
                                                <span>Есть весы</span>
                                            </> : <>
                                                <span><img className="me-2" src={markGray} alt="лаборатории"/></span>
                                                <span>Нет весов</span>
                                            </>}
                                        </div></Col>
                                    </Row>
                            </div>)
                        }) 
                    }
                    </Row></Col>
                </Row>
            </div>
        </>);
    }
    return ( <>
     {store.isAuth && store.unloadingInfo && store.unloadingInfo.email ? (<>
        <Col className="mb-3" lg={12} ><Link className="card-link-container-title" to="/unloading"><img className="me-3" src={selectFile} alt="Земля"/>Мощности по отгрузке</Link></Col>
        {showUnloadingItem(3)}
        
    </>) : <></>}
    </> );
}
 
export default observer(UnloadingDetail);