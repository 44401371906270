import React, { FC, useContext, useRef, useState } from "react";
import AccountMenu from "../parts/AccountMenu";
import {Context} from "../../../index";
import { observer } from "mobx-react-lite";
import { Form, Card, CardBody, CardTitle, Container, FormLabel, Col, Button, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModalDefault from "../../modals/ModalDefault";
import modalImage from "../../../img/icons/Info-circle.svg"
import remove from "../../../img/icons/Trash.svg";

import Modal from 'react-bootstrap/Modal';
import envelope from "../../../img/icons/Envelope.svg";
import OrgAccountSubMenu from "../parts/OrgAccountSubMenu";
import ModalGos from "../../modals/ModalGos";

import URL from "../../../config/default.json";
export const API_URL = URL.SERVER_URL_BASE;


const UnloadingForm: FC = () => {
    const {store} = useContext(Context);
    const [validated, set_Validated] = useState(false);
    const handleModal = useRef<HTMLAnchorElement>(null);

    // Confirm modal
    const [deleteId, setDeleteId] = useState('');
    const [showDeleteModal, setDeleteModal] = useState(false);
    const handleCloseDeleteModal = () => setDeleteModal(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e: any) => {e.preventDefault(); setShow(true)};

    const [form_Data, set_Form_Data] = useState({
        powerOfUnloading: '',
        unloadingConditions: '',
        scalesOfUpload: '',
        scalesOfUploadDescription: ''
    })

    const submitForm = (e: any) => {
        const form = e.currentTarget;
        e.preventDefault();
        // Проверяем, есть ли хотя бы один выбранный чекбокс
        const isAnyChecked = Object.values(form_DataChecbox).some((value) => value);
        
        if (!isAnyChecked && classHide !== 'd-none') {
            setError(true);
            return;
        }
        if (form.checkValidity() === true) {
            const typeOfUnloading = form.elements.typeOfUnloading.value;
            const powerOfUnloading = form.elements.powerOfUnloading.value;
            const scalesOfUpload = form.elements.scalesOfUpload.value;
            const dateAdded = form.elements.dateAdded.value;
            const typeOfContainer1 = form.elements.typeOfContainer1.checked;
            const typeOfContainer2 = form.elements.typeOfContainer2.checked;
            const typeOfContainer3 = form.elements.typeOfContainer3.checked;
            const typeOfContainer4 = form.elements.typeOfContainer4.checked;
            const typeOfContainer5 = form.elements.typeOfContainer5.checked;

            store.saveUnloadingInfo(store.user.email, store.user.id, typeOfUnloading, powerOfUnloading, scalesOfUpload, dateAdded, typeOfContainer1, typeOfContainer2, typeOfContainer3, typeOfContainer4, typeOfContainer5);
            set_Validated(false);
            set_Form_Data({
                powerOfUnloading: '',
                unloadingConditions: '',
                scalesOfUpload: '',
                scalesOfUploadDescription: ''
            });
            if (handleModal.current) {
                handleModal.current.click();
            }
        } else {
            set_Validated(true);
        }
        
    };

    const removeItem = () => {
        store.removeUnloadingItem(store.user.email, deleteId);
        setDeleteModal(false);
    }

    const removeItemSetData = (id:any) => {
        setDeleteId(id);
        setDeleteModal(true);
    }

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };

    const [classHide, setClassHide] = useState<string>('d-none');
    const [classHideChecbox, setClassHideChecbox] = useState<string>('d-block');

    const [form_Raio, set_Form_Radio] = useState({
        isTypeOfUnloading1: false,
        isTypeOfUnloading2: false,
        isTypeOfUnloading3: false,
    });

    const [form_Lab, set_Form_Lab] = useState({
        typeOfLab1: false,
        typeOfLab2: true
    });

    const radioChange = function(par1:boolean, par2:boolean, par3:boolean) {
        set_Form_Radio({
            isTypeOfUnloading1: par1,
            isTypeOfUnloading2: par2,
            isTypeOfUnloading3: par3,
        });
        setClassHide('');
        if (par3) {
            setClassHideChecbox('d-none');
        } else {
            setClassHideChecbox('d-block');
        }
    }

    const labChange = function(par1:boolean, par2:boolean) {
        set_Form_Lab({
            typeOfLab1: par1,
            typeOfLab2: par2,
        })
    }

    const [form_DataChecbox, setForm_DataChecbox] = useState({
        typeOfContainer1: false,
        typeOfContainer2: false,
        typeOfContainer3: false,
        typeOfContainer4: false,
        typeOfContainer5: false,
    });

    const [error, setError] = useState(false); // Ошибка обязательного выбора

    const handleCheckboxMoney = (checked: boolean, name: string) => {
        setForm_DataChecbox((prev) => ({ ...prev, [name]: checked }));
        setError(false); // Сброс ошибки при изменении чекбокса
    };

    return ( <>
     {store.isAuth ? (<>
        <OrgAccountSubMenu class1="organization-link px-4" class2="organization-link px-4" class3="organization-link px-4 active"/>
        <Container className='mb-5'>
            <AccountMenu/>
            <Form noValidate validated={validated} onSubmit={submitForm}>
                <div className="load-harvest-doc my-5 text-center d-flex justify-content-center align-items-baseline">
                    <ModalDefault classButton="text-center" name="Заполнить автоматически через Росреестр" variant="primary"/>
                    <ModalGos classImg="btn btn-link p-2 border-link ms-3" image={modalImage}/>
                </div>
                <CardTitle>Добавить мощность по отгрузке</CardTitle>
                <Card className="mb-3">
                    <CardBody>
                        <Form.Group as={Col} className="mb-3" controlId="validationplantasName">
                            <FormLabel className='required'>Способ отгрузки</FormLabel>
                            <Form.Check
                                type="radio"
                                name="typeOfUnloading"
                                value="auto"
                                label="Автомобильный транспорт"
                                id="isTypeOfUnloading1"
                                className="radio"
                                checked={form_Raio.isTypeOfUnloading1}
                                onChange={e => radioChange(true, false, false)}
                                required
                            />
                            <Form.Check
                                type="radio"
                                name="typeOfUnloading"
                                value="train"
                                label="Железнодорожный транспорт"
                                id="isTypeOfUnloading2"
                                className="radio"
                                checked={form_Raio.isTypeOfUnloading2}
                                onChange={e => radioChange(false, true, false)}
                                required
                            />
                            <Form.Check
                                type="radio"
                                name="typeOfUnloading"
                                value="port"
                                label="Портовый комплекс"
                                id="isTypeOfUnloading3"
                                className="radio"
                                checked={form_Raio.isTypeOfUnloading3}
                                onChange={e => radioChange(false, false, true)}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Выберите один из вариантов
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" controlId="validationreprvolumen">
                            <FormLabel className='required'>Мощность отгрузки</FormLabel>
                            <Form.Control
                                type="number"
                                step="1"
                                name="powerOfUnloading"
                                className='w-100 legal-form-1'
                                value={form_Data.powerOfUnloading}
                                onChange={chngFn}
                                required
                                placeholder='Укажите мощность отгрузки'
                                onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3 position-relative" controlId="validationqualityDescription">
                            <FormLabel className='required'>Наличие весов</FormLabel>
                            <Form.Check
                                type="radio"
                                name="scalesOfUpload"
                                value="yes"
                                label="Присутствует"
                                id="typeOfLab1"
                                className="radio"
                                checked={form_Lab.typeOfLab1}
                                onChange={e => labChange(true, false)}
                                required
                            />
                            <Form.Check
                                type="radio"
                                name="scalesOfUpload"
                                value="no"
                                label="Отсутствует"
                                id="typeOfLab2"
                                className="radio"
                                checked={form_Lab.typeOfLab2}
                                onChange={e => labChange(false, true)}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className={`mb-3 ${classHide}`}>
                            <FormLabel className="required">Тип тары для отгрузки</FormLabel>
                            {Object.keys(form_DataChecbox).map((key, index) => (
                                <Form.Check
                                    key={key}
                                    type="checkbox"
                                    name={key}
                                    label={[
                                        "Отгрузка насыпью",
                                        "Отгрузка мешкотарой",
                                        "Отгрузка биг-бегами",
                                        "Отгрузка в контейнеры",
                                        "Наличие КМПВ",
                                    ][index]}
                                    id={key}
                                    className={index === 3 ? `radio ${classHideChecbox}` : 'radio'}
                                    
                                    onChange={(e) => handleCheckboxMoney(e.target.checked, key)}
                                    checked={form_DataChecbox[key as keyof typeof form_DataChecbox]}
                                />
                            ))}
                            {error && <div className="text-danger mt-2">Выберите хотя бы один вариант</div>}
                        </Form.Group>
                        <Form.Control
                            type="text"
                            name="dateAdded"
                            value={new Date().toISOString().slice(0, 10).split('-').reverse().join('/')}
                            className='hidden'
                            readOnly
                        />
                        <Button className="mt-2" variant="secondary" id="form-submit" type="submit">Сохранить</Button>
                    </CardBody>
                </Card>
                <Link to="/" onClick={handleShow} ref={handleModal}></Link>
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                    <h4>Даные добавлены</h4>
                    <button className='btn btn-primary d-flex align-item-center mt-4' onClick={handleClose}>Закрыть</button>
                    </Modal.Body>
                    <Modal.Footer>
                    <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                    <div className='btn-outline-wrap-green mt-3 mb-4'>
                    <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Form>
            {store.unloadingInfo && store.unloadingInfo.unloading && store.unloadingInfo.unloading.length > 0 ? (<>
                <CardTitle className="mt-5">Добавленные хранилища</CardTitle>
                <Card className="mb-3">
                    <CardBody><Row>
                        {store.unloadingInfo.unloading.map(function(data, idx){

                            let typeOfUnloading = 'Автомобильный транспорт';
                            if (data.typeOfUnloading === 'train') 
                                typeOfUnloading = 'Железнодорожный транспорт';
                            else if (data.typeOfUnloading === 'port')
                                typeOfUnloading = 'Портовый комплекс';

                            let typeOfContainer = '';
                            if (data.typeOfContainer1) typeOfContainer = typeOfContainer + 'Отгрузка насыпью, ';
                            if (data.typeOfContainer2) typeOfContainer = typeOfContainer + 'Огрузка мешкотарой, ';
                            if (data.typeOfContainer3) typeOfContainer = typeOfContainer + 'Отгрузка биг-бегами, ';
                            if (data.typeOfContainer4) typeOfContainer = typeOfContainer + 'Отгрузка в контейнеры, ';
                            if (data.typeOfContainer5) typeOfContainer = typeOfContainer + 'Наличие КМПВ, ';
                            if (typeOfContainer !== '') {
                                typeOfContainer = typeOfContainer.slice(0, -2);
                            }
                            
                            return (<Col lg={12}><div className="harvest-data" key={idx}>
                                    <div className="harvest-data-top mb-3 d-flex justify-content-between align-items-center">{typeOfUnloading} <Link to="" onClick={e => removeItemSetData(data._id)}><img src={remove} alt="remove"/></Link></div>
                                    <Row className="harvest-data-bottom">
                                        <Col lg={2}><div className="harvest-data-bottom-item"><span>{data.powerOfUnloading} </span><span> мощность</span></div></Col>
                                        <Col lg={8}><div className="harvest-data-bottom-item"><span>
                                            {typeOfContainer}
                                            </span><span>тип тары для отгрузки</span></div></Col>
                                        <Col lg={2}><div className={data.scalesOfUpload === 'yes' ? 'd-flex align-items-center h-100 harvest-data-bottom-item success' : 'd-flex align-items-center h-100 harvest-data-bottom-item '}>
                                            <span>{data.scalesOfUpload === 'yes' ? 'Есть весы' : 'Нет весов'}</span></div></Col>
                                    </Row>
                                </div></Col>)
                        }) }</Row>
                    </CardBody>
                    <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} backdrop="static" keyboard={false} >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                        <h4>Подвердите удаление данных</h4>
                        </Modal.Body>
                        <Modal.Footer>
                        <p className="mb-4">Данные будут удалены и востановлению не подлежат</p>
                        <Row className="w-100">
                            <Col className="ps-0" lg={3}>
                                <button className='btn btn-primary mt-3 mb-4 w-100 text-center' onClick={removeItem}>Да</button>
                            </Col>
                            <Col className="ps-0" lg={3}>
                            <div className='btn-outline-wrap-green mt-3 mb-4 w-100 text-center'> 
                                <button className='btn btn-outline' onClick={handleCloseDeleteModal}>Нет</button>
                            </div>
                            </Col>
                        </Row>
                        </Modal.Footer>
                    </Modal>
                </Card>
            </>) : <></>}
        </Container>
    </>) : <></>}
    </> );
}
 
export default observer(UnloadingForm);