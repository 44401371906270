import $api from "../http";
import {AxiosResponse} from 'axios';
import {ConfirmInfoResponse} from '../models/response/ConfirmInfoResponse';

export default class ConfirmInfoService {
    static async saveConfirmInfo(
        email: string,
        user: string,
        confirmName: string,
        confirmNumber: string,
        dateAccessStart: string,
        dateAccessEnd: string,
        uploadConfirmDoc: File,

        currentBankAccount: string,
        bankName: string,
        bankBIK: string,
        corrAccount: string,
        bankInnCode: string,
        bankKPP: string,
        platformRole: string,
        uploadFinSituation: File,
        uploadFinReputation: File,
        financialInfo: string,
        businessReputation: string
        ): Promise<AxiosResponse<ConfirmInfoResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('confirmName', confirmName);
        formData.append('confirmNumber', confirmNumber);
        formData.append('dateAccessStart', dateAccessStart);
        formData.append('dateAccessEnd', dateAccessEnd);
        formData.append('uploadConfirmDoc', uploadConfirmDoc);

        formData.append('currentBankAccount', currentBankAccount);
        formData.append('bankName', bankName);
        formData.append('bankBIK', bankBIK);
        formData.append('corrAccount', corrAccount);
        formData.append('bankInnCode', bankInnCode);
        formData.append('bankKPP', bankKPP);
        formData.append('platformRole', platformRole);
        formData.append('uploadFinSituation', uploadFinSituation);
        formData.append('uploadFinReputation', uploadFinReputation);
        formData.append('financialInfo', financialInfo);
        formData.append('businessReputation', businessReputation);

        return $api.post<ConfirmInfoResponse>('/confirmInfoForm', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    }
}