import { FC, useState, useContext, useRef } from "react";
import { Alert, Card, CardBody, CardText, Col, Form, FormLabel, Row } from "react-bootstrap";
import {Context} from "../../../index";
import selectFile from "../../../img/icons/fileUpload.svg";


const RepresentativeForm3: FC = () => {

    const {store} = useContext(Context);
    const [radioPlatformRoleStatus, setPlatformRoleStatus] = useState<string>(store.confirmInfo.platformRole ? store.confirmInfo.platformRole : '');
    const validated = false;
    const fileLimit = 10000000;

    // Data for Сведения о деловой репутации/репутация file
    const filePickerLicenseFinReputation = useRef<HTMLInputElement>(null);
    const [errorFileSizeFinReputation, setErrorFinReputation] = useState<boolean>(false);
    const [filenameFinReputation, setFilenameFinReputation] = useState<string>('Загрузить скан-копию лицензии');
    const uploadFinReputationDBelemnt = store.confirmInfo.uploadFinReputationPath;

    // Data for Сведения о деловой репутации/положение file
    const filePickerLicenseFinSituation = useRef<HTMLInputElement>(null);
    const [errorFileSizeFinSituation, setErrorFinSituation] = useState<boolean>(false);
    const [filenameFinSituation, setFilenameFinSituation] = useState<string>('Загрузить скан-копию лицензии');
    const uploadFinSituationDBelemnt = store.confirmInfo.uploadFinSituationPath;
    
    const [form_Data, set_Form_Data] = useState({
        email: store.confirmInfo.email,
        currentBankAccount: store.confirmInfo.currentBankAccount,
        bankName: store.confirmInfo.bankName,
        bankBIK: store.confirmInfo.bankBIK,
        corrAccount: store.confirmInfo.corrAccount,
        bankInnCode: store.confirmInfo.bankInnCode,
        bankKPP: store.confirmInfo.bankKPP,
        platformRole: store.confirmInfo.platformRole,
        uploadFinSituation: store.confirmInfo.uploadFinSituation,
        uploadFinSituationPath: uploadFinSituationDBelemnt,
        uploadFinReputation: store.confirmInfo.uploadFinReputation,
        uploadFinReputationPath: uploadFinReputationDBelemnt,
        financialInfo: store.confirmInfo.financialInfo,
        businessReputation: store.confirmInfo.businessReputation
    });


    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };

    const handleCheckboxChange = (score: any, inputName: string) => {
        setPlatformRoleStatus(score);

        set_Form_Data({
            ...form_Data,
            [inputName]: score,
        });
    };

    const fileNameFinSituationDB = uploadFinSituationDBelemnt ? uploadFinSituationDBelemnt.split('-f-n-').pop() : '';
    const requiredFinSituation:boolean = uploadFinSituationDBelemnt ? false : true;

    const fileNameFinReputationDB = uploadFinReputationDBelemnt ? uploadFinReputationDBelemnt.split('-f-n-').pop() : '';
    const requiredFinReputation:boolean = uploadFinReputationDBelemnt ? false : true;

    const uploadHandleChnageFinSituation = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorFinSituation(true);
            
            if (filePickerLicenseFinSituation.current) {
                filePickerLicenseFinSituation.current.value = "";
            }
        } else {
            setFilenameFinSituation(e.target.files[0].name);
            setErrorFinSituation(false);
        }
    }

    const handlePickFinSituation = () => {
        if (filePickerLicenseFinSituation.current) {
            filePickerLicenseFinSituation.current.click();
        }
    }

    const uploadHandleChnageFinReputation = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorFinReputation(true);
            
            if (filePickerLicenseFinReputation.current) {
                filePickerLicenseFinReputation.current.value = "";
            }
        } else {
            setFilenameFinReputation(e.target.files[0].name);
            setErrorFinReputation(false);
        }
    }

    const handlePickFinReputation = () => {
        if (filePickerLicenseFinReputation.current) {
            filePickerLicenseFinReputation.current.click();
        }
    }

    return ( <>
        <CardText className="mt-5">Банковские реквизиты</CardText>
        <Card>
            <CardBody>
                <Form.Group as={Col} className="mb-3" controlId="validationCurrentBankAccount">
                    <FormLabel className='required'>Расчетный счет</FormLabel>
                    <Form.Control
                        type="text"
                        name="currentBankAccount"
                        value={form_Data.currentBankAccount}
                        onChange={chngFn}
                        pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                        className='w-100'
                        required
                        placeholder='Расчетный счет'
                        isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.currentBankAccount)}
                    />
                    <Form.Control.Feedback type="invalid">
                        Введите корректные данные
                    </Form.Control.Feedback>
                </Form.Group>
                <Row>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationBankName">
                        <FormLabel className='required'>Наименование банка</FormLabel>
                        <Form.Control
                            type="text"
                            name="bankName"
                            value={form_Data.bankName}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                            className='w-100'
                            required
                            placeholder='Наименование банка'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.bankName)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationCurrentBankAccount">
                        <FormLabel className='required'>БИК банка</FormLabel>
                        <Form.Control
                            type="text"
                            name="bankBIK"
                            value={form_Data.bankBIK}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                            className='w-100'
                            required
                            placeholder='БИК банка'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.bankBIK)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationCorrAccount">
                        <FormLabel className='required'>Корреспондентский счет</FormLabel>
                        <Form.Control
                            type="text"
                            name="corrAccount"
                            value={form_Data.corrAccount}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                            className='w-100'
                            required
                            placeholder='Корреспондентский счет'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.corrAccount)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationBankName">
                        <FormLabel className='required'>ИНН Банка</FormLabel>
                        <Form.Control
                            type="text"
                            name="bankInnCode"
                            value={form_Data.bankInnCode}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                            className='w-100'
                            required
                            placeholder='ИНН Банка'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.bankInnCode)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationCurrentBankAccount">
                        <FormLabel className='required'>КПП Банка</FormLabel>
                        <Form.Control
                            type="text"
                            name="bankKPP"
                            value={form_Data.bankKPP}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                            className='w-100'
                            required
                            placeholder='КПП Банка'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.bankKPP)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
            </CardBody>
        </Card>
        <CardText className="mt-5">Дополнительная информация о юридическом лице</CardText>
        <Card>
            <CardBody>
                <Form.Group as={Col} className="mb-3">
                    <FormLabel>Роль на платформе</FormLabel>
                    <Form.Check
                        type="radio"
                        name="platformRole"
                        value="developRole"
                        checked={(radioPlatformRoleStatus === 'developRole' || radioPlatformRoleStatus === '') ? true : false}
                        onChange={e => handleCheckboxChange('developRole', 'platformRole')}
                        label="Производитель"
                        id="platformRoleDevelop"
                        className="radio"
                    />
                    <Form.Check
                        type="radio"
                        name="platformRole"
                        value="tradeRole"
                        checked={radioPlatformRoleStatus === 'tradeRole' ? true : false}
                        onChange={e => handleCheckboxChange('tradeRole', 'platformRole')}
                        label="Трейдер"
                        id="platformRoleTrade"
                        className="radio"
                    />
                    <Form.Check
                        type="radio"
                        name="platformRole"
                        value="investRole"
                        checked={radioPlatformRoleStatus === 'investRole' ? true : false}
                        onChange={e => handleCheckboxChange('investRole', 'platformRole')}
                        label="Инвестор"
                        id="platformRoleInvest"
                        className="radio"
                    />
                </Form.Group>
                <Row>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationFinancialInfo">
                        <FormLabel className='required'>Сведения о финансовом положении</FormLabel>
                        <Form.Control
                            type="text"
                            name="financialInfo"
                            value={form_Data.financialInfo}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9 ']+$"
                            className='w-100'
                            placeholder='Сведения о финансовом положении'
                            required
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9 ]+$/.test(form_Data.financialInfo)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationBusinessReputation">
                        <FormLabel className='required'>Сведения о деловой репутации</FormLabel>
                        <Form.Control
                            type="text"
                            name="businessReputation"
                            value={form_Data.businessReputation}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9 ']+$"
                            className='w-100'
                            placeholder='Сведения о деловой репутации'
                            required
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9 ]+$/.test(form_Data.businessReputation)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationLicenseFinSituation">
                        <Form.Control 
                            type="file"
                            className="hidden"
                            onChange={uploadHandleChnageFinSituation}
                            accept=".pdf, .jpg, .jpeg, .png, .heic"
                            required={requiredFinSituation}
                            name="uploadFinSituation"
                            ref={filePickerLicenseFinSituation}
                            disabled={!requiredFinSituation}
                        />
                        
                        <div className="filePicker d-flex align-items-center">
                            <div className="me-4 fileIcon" onClick={handlePickFinSituation}><img src={selectFile} alt="Select file"/></div>
                            <div className="fileLable">{filenameFinSituation}
                                <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                            </div>
                            
                            {fileNameFinSituationDB  ? <Alert className="d-flex w-100 mt-3" variant="warning">Файл {fileNameFinSituationDB} находится в обработке</Alert> : ''}
                            
                            {errorFileSizeFinSituation  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                        </div>
                        <Form.Control.Feedback type="invalid">
                            Файл обязателен к загрузке
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationLicenseFinReputation">
                        <Form.Control 
                            type="file"
                            className="hidden"
                            onChange={uploadHandleChnageFinReputation}
                            accept=".pdf, .jpg, .jpeg, .png, .heic"
                            required={requiredFinReputation}
                            name="uploadFinReputation"
                            ref={filePickerLicenseFinReputation}
                            disabled={!requiredFinReputation}
                        />
                        
                        <div className="filePicker d-flex align-items-center">
                            <div className="me-4 fileIcon" onClick={handlePickFinReputation}><img src={selectFile} alt="Select file"/></div>
                            <div className="fileLable">{filenameFinReputation}
                                <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                            </div>
                            
                            {fileNameFinReputationDB  ? <Alert className="d-flex w-100 mt-3" variant="warning">Файл {fileNameFinReputationDB} находится в обработке</Alert> : ''}
                            
                            {errorFileSizeFinReputation  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                        </div>
                        <Form.Control.Feedback type="invalid">
                            Файл обязателен к загрузке
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
            </CardBody>
        </Card>
    </> );
}
 
export default RepresentativeForm3;