import $api from "../http";
import {AxiosResponse} from 'axios';
import {SecondaryCfaResponse} from '../models/response/SecondaryCfaResponse';
import {MyCfaInfoResponse} from '../models/response/MyCfaResponse';

export default class SecondaryCfaService {
    static async saveSecondaryCfaService(
        email: string,
        user: string,
        numberAccountTo: string,
        freeAmount: string,
        countCfa: string,
        countCfaOrigin: string,
        dateAdded: string,
        totalAmount: string,
        emitID: string,
        cfaID: string,
        commisionPrice: string,
        priceSellCfa: string,
        myCfaID: string
        ): Promise<AxiosResponse<MyCfaInfoResponse>> {
        return $api.post<MyCfaInfoResponse>('/saveSecondaryCfa',{email, user, numberAccountTo, freeAmount, countCfa, countCfaOrigin, dateAdded, totalAmount, emitID, cfaID, commisionPrice, priceSellCfa, myCfaID})
    }

    static fetchSecondaryCfa(): Promise<AxiosResponse<SecondaryCfaResponse[]>> {
        return $api.get<SecondaryCfaResponse[]>('/fetchSecondaryCfa');
    }
}