import React, { FC, useContext, useState} from "react";
import {Context} from "../../../../index";
import { observer } from "mobx-react-lite";
import { Col, Modal, Row, Tab, Tabs, ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import selectFile from "../../../../img/icons/fileUpload.svg";
import infoCount from "../../../../img/icons/infoCount.svg";
import modalImage from "../../../../img/icons/settings-alt.svg";
import defaultImage from "../../../../img/main/card-non.svg";

import URL from "../../../../config/default.json"
import ModalGos from "../../../modals/ModalGos";

export const API_URL = URL.SERVER_URL_BASE;

const ProductionDetail: FC = () => {
    const {store} = useContext(Context);
    const [show, setShow] = useState(false);
        const handleClose = () => setShow(false);
        const [itemNumber, setItemNumber] = useState(0);
    
        const showDetailModal = (id: number) => {
            setItemNumber(id);
            setShow(true);
        }
        const productionReverse = store.productionInfo.production.slice(-store.productionInfo.production.length).reverse();
        const images = productionReverse[itemNumber]?.uploadProductionPhotoPath || [];
    function showProductionItem(count:any, variant = '') {
        var production:any = store.productionInfo.production;
        var index = 1;
        const lastItems = production.slice(-production.length).reverse();
        return (<>
            <div className="harvest-data p-0 pt-2">
                <Row className="harvest-data-bottom">
                    <Col xs={{ order: 2 }} lg={10}><Row>
                        {lastItems.map(function(data:any, idx:any){
                            if (index > count) {
                                return false;
                            }
                            const uploadProductionPhotoPathElem = data.uploadProductionPhotoPath[0];
                            const uploadProductionPhotoPath = uploadProductionPhotoPathElem ? uploadProductionPhotoPathElem.split('uploads/').pop() : '';

                            const imgUrl = uploadProductionPhotoPath ? API_URL + uploadProductionPhotoPath : defaultImage;

                            let typeOfProduction = 'Крупяное производство';
                            if (data.typeOfProduction === 'maslo') 
                                typeOfProduction = 'Масличное производство';
                            else if (data.typeOfProduction === 'myka')
                                typeOfProduction = 'Мукомольное производство';
                            else if (data.typeOfProduction === 'korm')
                                typeOfProduction = 'Кормовое производство';

                            if (data.typeOfProduction === variant || variant === '') {
                                index++;
                                return (<Col lg={4}>
                                    <div className="harvest-data-bottom-item-info">
                                    {data.uploadProductionPhotoPath ? (<>
                                        <div className="photo-production-img loaded info d-flex position-relative"> <img src={imgUrl} alt={typeOfProduction} />
                                            <div className="develop-technique px-3 cursor-pointer" onClick={e => showDetailModal(idx)}>
                                                <div className="develop-technique-title">{typeOfProduction}</div>
                                                <div className="develop-technique-year mb-2">{data.powerOfProduction} мощность</div>
                                            </div>
                                            <ModalGos classImg="img" class="develop-settings" name="" image={modalImage}/>
                                        </div>
                                    </>): (<></>)}
                                    </div>
                                    </Col>)
                            } else {
                                return (<></>)
                            }
                        }) 
                    }
                    </Row></Col>
                    <Col order="1" lg={2} ><div className="harvest-data-bottom-item info"><span><img className="me-2" src={infoCount} alt="="/>{variant === '' ? production.length : index - 1}</span><span>eдиниц</span></div></Col>
                </Row>
            </div>
        </>);
    }
    return ( <>
     {store.isAuth && store.productionInfo && store.productionInfo.email ? (<>
        <Col className="mb-3" lg={12} ><Link className="card-link-container-title" to="/production"><img className="me-3" src={selectFile} alt="Земля"/>Производство</Link></Col>
        <Tabs defaultActiveKey="step1" variant="tabs" id="info-tab-example" className="mb-3" >
            <Tab eventKey="step1" title="Все">
                {showProductionItem(3)}
            </Tab>
            <Tab eventKey="step2" title="Крупяное производство">
                {showProductionItem(3, 'krupa')}
            </Tab>
            <Tab eventKey="step3" title="Масличное производство">
                {showProductionItem(3, 'maslo')}
            </Tab>
            <Tab eventKey="step4" title="Мукомольное производство">
                {showProductionItem(3, 'myka')}
            </Tab>
            <Tab eventKey="step5" title="Кормовое производство">
                {showProductionItem(3, 'korm')}
            </Tab>
        </Tabs>
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} className="cfa-modal">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <h4 className="mb-4">
                    {productionReverse[itemNumber].typeOfProduction === 'krupa' ? 'Крупяное производство' : ''}
                    {productionReverse[itemNumber].typeOfProduction === 'maslo' ? 'Масличное производство' : ''}
                    {productionReverse[itemNumber].typeOfProduction === 'myka' ? 'Мукомольное производство' : ''}
                    {productionReverse[itemNumber].typeOfProduction === 'korm' ? 'Кормовое производство' : ''}

                </h4>
                <Col lg={12}>
                    <Carousel additionalTransfrom={0} arrows autoPlaySpeed={3000} centerMode={false} containerClass="container-with-dots" draggable focusOnSelect={false} infinite keyBoardControl
                        responsive={{
                            desktop: {
                                breakpoint: {
                                    max: 3000,
                                    min: 1024
                                },
                                items: 3,
                                partialVisibilityGutter: 40
                            },
                            tablet: {
                                breakpoint: {
                                    max: 1024,
                                    min: 464
                                },
                                items: 2,
                                partialVisibilityGutter: 30
                            },
                            mobile: {
                                breakpoint: {
                                    max: 464,
                                    min: 0
                                },
                                items: 1,
                                partialVisibilityGutter: 30
                            }
                        }}
                        slidesToSlide={1}
                        swipeable
                        >
                            {images.map((photo: any, idx: number) => {
                                const uploadTechniquePhotoPath = photo ? photo.split("uploads/").pop() : "";
                                return (
                                    <div className="carousel-images mx-3"><img
                                    key={idx}
                                    src={API_URL + uploadTechniquePhotoPath}
                                    alt={productionReverse[itemNumber].typeOfProduction}
                                    className="img-fluid mx-1"
                                /></div>
                                );
                            })}
                    </Carousel>
                </Col>
                <div className="harvest-data px-0">
                    <Row className="harvest-data-bottom">
                        <Col lg={4} ><div className="harvest-data-bottom-item info"><span>{productionReverse[itemNumber].powerOfProduction} л</span><span>мощность</span></div></Col>
                        {productionReverse[itemNumber].sortOfProduction ? <>
                            <Col lg={4}><div className="harvest-data-bottom-item info"><span>{productionReverse[itemNumber].sortOfProduction} л</span><span>сорт</span></div></Col>
                        </> : <></>}
                        <Col lg={4}>
                            <span>75% <br/>готовой продукции</span>
                            <span className="d-block mt-3"><ProgressBar variant="secondary" now={75} /></span>
                        </Col>
                        {productionReverse[itemNumber].productionDescription ? <>
                            <Col lg={12}>
                                <div className="harvest-data-bottom-item detail-carousel-height mt-4">
                                    <span>Описание производства</span>
                                    {productionReverse[itemNumber].productionDescription}
                                </div>
                            </Col>
                        </> : <></>}
                        
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    </>) : <></>}
    </> );
}
 
export default observer(ProductionDetail);