import {useContext, useState} from 'react';
import {observer} from "mobx-react-lite";

import {Context} from "../../../index";
import {Alert, Card, CardBody, CardTitle, Col, Form, Row, FormLabel, Button} from 'react-bootstrap';

import Modal from 'react-bootstrap/Modal';
import emitImage from "../../../img/icons/emiter-default.svg"

const BuyCfa = (props) => {
    const {store} = useContext(Context);
    let emitID = props.emitID
    let cfaID = props.cfaID;
    const cfaItem = store.cfaItem;
    const customerAmount = 10000000;

    if (cfaItem && cfaItem.ticker === undefined && props.show) {
        store.getDetailCFA(emitID, cfaID);
    }

    const platformTax = 0.01;

    const [validatedBuyCFA, set_validatedBuyCFA] = useState(false);
    const [confirmationModal, set_confirmationModal] = useState(false);
    const [disabled, set_disabled] = useState(false);
    const [alertclass, set_alertclass] = useState('d-none');
    const priceCfa = parseInt(cfaItem.priceCfa);
    const countCfa = parseInt(cfaItem.countCfa || 0);
    const submitBuyCfa = (e) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            const numberAccountFrom = form.elements.numberAccountFrom.value;
            const freeAmount = form.elements.freeAmount.value;
            const countCfa = form.elements.countCfa.value;
            const dateAdded = form.elements.dateAdded.value;
            const totalAmount = form_Data_Transaction.totalAmount;
            const commisionPrice = form_Data_Transaction.totalTax;

            store.buyCfaInfo(store.user.email, store.user.id, numberAccountFrom, freeAmount, countCfa, dateAdded, totalAmount, emitID, cfaID, commisionPrice);
            
            set_validatedBuyCFA(false);
            if (store.errorMessage === '') {
                // set_confirmationModal(true);
            }
            setTimeout(() => {
              if (store.errorMessage === '') {
                    set_confirmationModal(true);
              }
          }, 500);
        } else {
          set_validatedBuyCFA(true);
        }
    }
    const [form_Data_Transaction, set_Form_Data_Transaction] = useState({
        numberAccountFrom: '',
        freeAmount: '',
        countCfa: '',
        totalAmount: 0,
        totalTax: 0
    });

    const chngFnTransaction = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let amout = parseInt(value);
        if (amout > countCfa) {
          amout = countCfa
        }
        let priceTilComision = amout * priceCfa;
        let totalTax = value ? (priceTilComision * platformTax * 0.01) : 0;
        let totalAmountNew = priceTilComision + totalTax;
        if (totalAmountNew > customerAmount) {
            set_disabled(true);
            set_alertclass('d-block');
        } else {
            set_disabled(false);
            set_alertclass('d-none');
        }
        
        
        set_Form_Data_Transaction({
            ...form_Data_Transaction,
            totalAmount: Math.round(totalAmountNew),
            totalTax:  Math.round(totalTax),
            [name]: amout,
            
        });
    };
    return (<>
      {store.isAuth && store.user.isActivated ? (<>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                  {confirmationModal ? (<>
                    <Modal.Body>
                    <p className="congrats-small">Поздравляем c покупкой!</p>
                    <h4 className="congrats-header">Заявка на покупку отправлена и будет обработана в ближайшее время</h4>
                    <p className="congrats-text">При изменении статуса придет уведомление, а также все можно отслеживать в личном кабинете</p>
                    </Modal.Body>
                    <Modal.Footer className="d-block text-center">
                        <a href="/dashboard-cfa" className='btn btn-primary my-3'>Перейти к витрине ЦФА</a>
                    </Modal.Footer>
                  </>) :(<>
                    <Row className="buy-cfa-modal align-items-stretch cfa-table-body me-0 ms-0">
                        <Col className="ps-0" lg={5}>
                            <div className='cfa-table-title h-100'>
                                <div className='d-flex align-items-center h-100'>
                                    <div>
                                      <div className='cfa-table-sub-title'>Digital-Grain_{cfaItem.ticker}</div>
                                      <div className='cfa-table-name'>{cfaItem.releaseName}</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className='align-items-center'>
                          <div className='d-flex align-items-center h-100'>
                              <div>
                                  <img className="me-3" src={emitImage} alt="Эмитент"/><span>{cfaItem.emitName}</span>
                              </div>
                          </div>
                        </Col>
                        <Col className="text-center pe-5" lg={3}>
                            <div className='cfa-table-title gray-light mb-3 p-1 text-start ps-4'>
                                <div className='cfa-table-sub-title'>Цена погашения</div>
                                <div className='cfa-table-sub-title--new'>{cfaItem.priceCompleateCfa ? cfaItem.priceCompleateCfa.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0} &#8381;</div>
                            </div>
                            <div className='cfa-table-title gray-light mb-2 p-1 text-start ps-4'>
                                <div className='cfa-table-sub-title'>Дата погашения</div>
                                <div className='cfa-table-sub-title--new'>{cfaItem.dateEmissionCfa}</div>
                            </div>
                        </Col>
                    </Row>
                    <CardTitle className='mt-5'>Заявка на первичное приобретение ЦФА</CardTitle>
                    <Form noValidate validated={validatedBuyCFA} onSubmit={submitBuyCfa}>
                        <Card className="mb-3">
                            <CardBody>
                                <Row>
                                    <Col lg={3}>
                                        <div className='price-for-cfa-label'>Цена за ЦФА</div>
                                        <div className='price-for-cfa mt-2'>{parseInt(cfaItem.priceCfa).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} &#8381;</div>
                                    </Col>
                                    <Col lg={3}></Col>
                                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationbnumberAccountFrom">
                                        <FormLabel className='fixed-label'>Счет списания</FormLabel>
                                        <Form.Control
                                            type="number"
                                            name="numberAccountFrom"
                                            value="4111111111111111"
                                            className='w-100 legal-form-1'
                                            disabled
                                            placeholder='Номер лицевого счета'
                                            onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationfreeAmount">
                                        <FormLabel className='fixed-label'>Свободных средств, ₽</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="freeAmount"
                                            value={customerAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                                            className='w-100 legal-form-1'
                                            disabled
                                            placeholder='Сумма свободных д/с на счёте'
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Form.Group lg={12} as={Col} className="mb-3 sendAmount" controlId="validationfcountCfa">
                                    <Form.Control
                                        type="number"
                                        max={countCfa}
                                        min={1}
                                        name="countCfa"
                                        value={form_Data_Transaction.countCfa}
                                        onChange={chngFnTransaction}
                                        className='w-100 legal-form-1'
                                        required
                                        placeholder='Введите количество приобретаемых ЦФА'
                                        onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Введите корректные данные
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <p className="form-text mb-3">Доступно <b>{countCfa}</b> ЦФА</p>
                                <p className="text-green-dark mb-0">Стоимость: <span className='amountTax'>{(parseInt(form_Data_Transaction.countCfa ? form_Data_Transaction.countCfa : 0) * parseInt(cfaItem.priceCfa)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} руб.</span></p>
                                <p className="text-green-dark mb-0">Комиссия за приобретение ЦФА {platformTax} %: {form_Data_Transaction.totalTax} руб.</p>
                                </CardBody>
                            </Card>
                        <Form.Group lg={12} as={Col} className="mb-3 totalAmount" controlId="validationftotalAmount">
                            <FormLabel className='fw-normal'>Итого</FormLabel>
                            <Form.Control
                                type="number"
                                name="totalAmount"
                                value={form_Data_Transaction.totalAmount}
                                className='w-100 legal-form-1'
                                required
                                disabled
                                placeholder='XXXXXXX'
                                onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        {store.errorMessage !== '' ? <Alert variant="danger">{store.errorMessage}</Alert> : ''}
                        <Alert className={alertclass} variant="danger">Недостаточно cвободных средств на балансе</Alert>
                        <Form.Control
                            type="text"
                            name="dateAdded"
                            value={new Date().toISOString()}
                            className='hidden'
                        />
                        <div className='d-flex'>
                            <Button disabled={disabled} className="mt-2 me-3" variant="primary" id="form-submit" type="submit">Купить ЦФА</Button>
                        </div>
                    </Form>
                  </>)}
              </Modal.Body>
      </>) : (<></>)}
      </>);
}
 
export default observer(BuyCfa);