import React, { FC, useContext, useRef, useState } from "react";
import AccountMenu from "../parts/AccountMenu";
import {Context} from "../../../index";
import { observer } from "mobx-react-lite";
import { Form, Card, CardBody, CardTitle, Container, FormLabel, Col, Button, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModalDefault from "../../modals/ModalDefault";
import modalImage from "../../../img/icons/Info-circle.svg"
import remove from "../../../img/icons/Trash.svg";

import Modal from 'react-bootstrap/Modal';
import envelope from "../../../img/icons/Envelope.svg";
import OrgAccountSubMenu from "../parts/OrgAccountSubMenu";
import ModalGos from "../../modals/ModalGos";

import URL from "../../../config/default.json";
export const API_URL = URL.SERVER_URL_BASE;


const ReceiveForm: FC = () => {
    const {store} = useContext(Context);
    const maxLength = 7000;
    const [validated, set_Validated] = useState(false);
    const handleModal = useRef<HTMLAnchorElement>(null);

    // Confirm modal
    const [deleteId, setDeleteId] = useState('');
    const [showDeleteModal, setDeleteModal] = useState(false);
    const handleCloseDeleteModal = () => setDeleteModal(false);

    const [textAreaCount, setCounterData] = useState<number>(0);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e: any) => {e.preventDefault(); setShow(true)};

    const [form_Data, set_Form_Data] = useState({
        powerOfReceive: '',
        receiveConditions: '',
        laboratories: '',
        laboratoriesDescription: ''
    })

    const submitForm = (e: any) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            const typeOfReceive = form.elements.typeOfReceive.value;
            const powerOfReceive = form.elements.powerOfReceive.value;
            const laboratories = form.elements.laboratories.value;
            const laboratoriesDescription = form.elements.laboratoriesDescription.value;
            const dateAdded = form.elements.dateAdded.value;

            store.saveReceiveInfo(store.user.email, store.user.id, typeOfReceive, powerOfReceive, laboratories, laboratoriesDescription, dateAdded);
            set_Validated(false);
            set_Form_Data({
                powerOfReceive: '',
                receiveConditions: '',
                laboratories: '',
                laboratoriesDescription: ''
            });
            if (handleModal.current) {
                handleModal.current.click();
            }
        } else {
            set_Validated(true);
        }
        
    };

    const removeItem = () => {
        store.removeReceiveItem(store.user.email, deleteId);
        setDeleteModal(false);
    }

    const removeItemSetData = (id:any) => {
        setDeleteId(id);
        setDeleteModal(true);
    }

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
        if (name === 'qualityDescription') {
            setCounterData(e.target.value.length);
        }
    };

    const [classHide, setClassHide] = useState<string>('d-none');

    const [form_Raio, set_Form_Radio] = useState({
        isTypeOfReceive1: true,
        isTypeOfReceive2: false,
        isTypeOfReceive3: false,
    });

    const [form_Lab, set_Form_Lab] = useState({
        typeOfLab1: false,
        typeOfLab2: false
    });

    const radioChange = function(par1:boolean, par2:boolean, par3:boolean) {
        set_Form_Radio({
            isTypeOfReceive1: par1,
            isTypeOfReceive2: par2,
            isTypeOfReceive3: par3,
        })
    }

    const labChange = function(par1:boolean, par2:boolean) {
        set_Form_Lab({
            typeOfLab1: par1,
            typeOfLab2: par2,
        })
        if (par1 === true) {
            setClassHide('');
        } else {
            setClassHide('d-none');
            set_Form_Data({
                ...form_Data,
                laboratoriesDescription: ''
            });
        }
    }

    return ( <>
     {store.isAuth ? (<>
        <OrgAccountSubMenu class1="organization-link px-4" class2="organization-link px-4" class3="organization-link px-4 active"/>
        <Container className='mb-5'>
            <AccountMenu/>
            <Form noValidate validated={validated} onSubmit={submitForm}>
                <div className="load-harvest-doc my-5 text-center d-flex justify-content-center align-items-baseline">
                    <ModalDefault classButton="text-center" name="Заполнить автоматически через Росреестр" variant="primary"/>
                    <ModalGos classImg="btn btn-link p-2 border-link ms-3" image={modalImage}/>
                </div>
                <CardTitle>Добавить мощность по приемке</CardTitle>
                <Card className="mb-3">
                    <CardBody>
                        <Form.Group as={Col} className="mb-3" controlId="validationplantasName">
                            <FormLabel className='required'>Способ приемки</FormLabel>
                            <Form.Check
                                type="radio"
                                name="typeOfReceive"
                                value="auto"
                                label="Автомобильный транспорт"
                                id="isTypeOfReceive1"
                                className="radio"
                                checked={form_Raio.isTypeOfReceive1}
                                onChange={e => radioChange(true, false, false)}
                                required
                            />
                            <Form.Check
                                type="radio"
                                name="typeOfReceive"
                                value="train"
                                label="Железнодорожный транспорт"
                                id="isTypeOfReceive2"
                                className="radio"
                                checked={form_Raio.isTypeOfReceive2}
                                onChange={e => radioChange(false, true, false)}
                                required
                            />
                            <Form.Check
                                type="radio"
                                name="typeOfReceive"
                                value="port"
                                label="Портовый комплекс"
                                id="isTypeOfReceive3"
                                className="radio"
                                checked={form_Raio.isTypeOfReceive3}
                                onChange={e => radioChange(false, false, true)}
                                required
                            />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" controlId="validationreprvolumen">
                            <FormLabel className='required'>Мощность приемки</FormLabel>
                            <Form.Control
                                type="number"
                                step="1"
                                name="powerOfReceive"
                                className='w-100 legal-form-1'
                                value={form_Data.powerOfReceive}
                                onChange={chngFn}
                                required
                                placeholder='Укажите мощность приемки'
                                onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3 position-relative" controlId="validationqualityDescription">
                            <FormLabel className='required'>Наличие лаборатории</FormLabel>
                            <Form.Check
                                type="radio"
                                name="laboratories"
                                value="yes"
                                label="Присутствует"
                                id="typeOfLab1"
                                className="radio"
                                checked={form_Lab.typeOfLab1}
                                onChange={e => labChange(true, false)}
                                required
                            />
                            <Form.Check
                                type="radio"
                                name="laboratories"
                                value="no"
                                label="Отсутствует"
                                id="typeOfLab2"
                                className="radio"
                                checked={form_Lab.typeOfLab2}
                                onChange={e => labChange(false, true)}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className={`mb-3 position-relative ${classHide}`} controlId="validationqualityDescription">
                            <FormLabel className='required'>Описание лаборатории</FormLabel>
                            <Form.Control
                                as="textarea"
                                name="laboratoriesDescription"
                                rows={6}
                                className='w-100 legal-form-1'
                                value={form_Data.laboratoriesDescription}
                                onChange={chngFn}
                                placeholder='Описание лаборатории'
                                maxLength={maxLength}
                                required={classHide === 'd-none' ? false : true}
                            />
                            <div className="text-counter">{textAreaCount} / {maxLength}</div>
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Control
                            type="text"
                            name="dateAdded"
                            value={new Date().toISOString().slice(0, 10).split('-').reverse().join('/')}
                            className='hidden'
                            readOnly
                        />
                        <Button className="mt-2" variant="secondary" id="form-submit" type="submit">Сохранить</Button>
                    </CardBody>
                </Card>
                <Link to="/" onClick={handleShow} ref={handleModal}></Link>
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                    <h4>Даные добавлены</h4>
                    <button className='btn btn-primary d-flex align-item-center mt-4' onClick={handleClose}>Закрыть</button>
                    </Modal.Body>
                    <Modal.Footer>
                    <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                    <div className='btn-outline-wrap-green mt-3 mb-4'>
                    <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Form>
            {store.receiveInfo && store.receiveInfo.receive && store.receiveInfo.receive.length > 0 ? (<>
                <CardTitle className="mt-5">Добавленные хранилища</CardTitle>
                <Card className="mb-3">
                    <CardBody><Row>
                        {store.receiveInfo.receive.map(function(data, idx){

                            let typeOfReceive = 'Автомобильный транспорт';
                            if (data.typeOfReceive === 'train') 
                                typeOfReceive = 'Железнодорожный транспорт';
                            else if (data.typeOfReceive === 'port')
                                typeOfReceive = 'Портовый комплекс';

                            let countItems = store.receiveInfo.receive.length

                            return (<Col lg={countItems > 1 ? 6 : 12}><div className="harvest-data" key={idx}>
                                    <div className="harvest-data-top mb-3 d-flex justify-content-between align-items-center">{typeOfReceive} <Link to="" onClick={e => removeItemSetData(data._id)}><img src={remove} alt="remove"/></Link></div>
                                    <Row className="harvest-data-bottom">
                                        <Col lg={3} ><div className="harvest-data-bottom-item"><span>{data.powerOfReceive} </span><span> мощность</span></div></Col>
                                        {data.laboratories === 'yes' ? <>
                                            <Col lg={9}><div className="harvest-data-bottom-item success"><span>Есть лаборатория</span><span>{data.laboratoriesDescription}</span></div></Col>
                                        </> : <>
                                            <Col lg={9}><div className="harvest-data-bottom-item"><span>Нет лаборатории</span></div></Col>
                                        </>}
                                    </Row>
                                </div></Col>)
                        }) }</Row>
                    </CardBody>
                    <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} backdrop="static" keyboard={false} >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                        <h4>Подвердите удаление данных</h4>
                        </Modal.Body>
                        <Modal.Footer>
                        <p className="mb-4">Данные будут удалены и востановлению не подлежат</p>
                        <Row className="w-100">
                            <Col className="ps-0" lg={3}>
                                <button className='btn btn-primary mt-3 mb-4 w-100 text-center' onClick={removeItem}>Да</button>
                            </Col>
                            <Col className="ps-0" lg={3}>
                            <div className='btn-outline-wrap-green mt-3 mb-4 w-100 text-center'> 
                                <button className='btn btn-outline' onClick={handleCloseDeleteModal}>Нет</button>
                            </div>
                            </Col>
                        </Row>
                        </Modal.Footer>
                    </Modal>
                </Card>
            </>) : <></>}
        </Container>
    </>) : <></>}
    </> );
}
 
export default observer(ReceiveForm);