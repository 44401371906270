
module.exports = function getBlockedMoney(cfa, st, stOfTr = 'redeem') {
    let activeList = 0;
    const cfalength = cfa?.length || 0;
    for (let i = 0; i < cfalength; i++) {
        if (stOfTr === 'redeem') {
            if (cfa[i].status === st) {
                activeList = activeList + (parseInt(cfa[i].countCfaOrigin)*parseInt(cfa[i].priceCompleateCfa));
                console.log(parseInt(cfa[i].countCfaOrigin)*parseInt(cfa[i].priceCompleateCfa));
            }
        } else {
            if (cfa[i].status === st && cfa[i].statusOfTransaction === stOfTr) {
                activeList = activeList+parseInt(cfa[i].totalAmount);
            }
        }
    }
    return activeList;
}
