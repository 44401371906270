import {FC, useContext, useState} from 'react';
import {observer} from "mobx-react-lite";

import {Context} from "../../index";
import CfaAccountSubMenu from '../account/parts/CfaAccountSubMenu';
import { Col, Container, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import emitImage from "../../img/icons/emiter-default.svg"
import activeCfaList from '../../helpers/activeCfaList';
import { IReleaseCfa } from '../../models/IReleaseCfa';
import BuyCFA from './modal/BuyCFA';

const DashboardCfa:FC = () => {
    const {store} = useContext(Context);
    const countEl2 = 2;
    let cfaList = store.cfaList;
    store.cfaItem = {} as IReleaseCfa;

    if (cfaList.length > 0) {
        cfaList = activeCfaList(cfaList);
    } else {
        store.getCfa();
        store.getSecondaryCfa();
    }

    const [show, setShow] = useState (false);
    const handleClose = () => setShow(false);
    const [emitID, setEmitID] = useState ('');
    const [cfaID, setCfaID] = useState ('');

    const buyCFA = (emitID:any, cfaId:any) => {
        setEmitID(emitID);
        setCfaID(cfaId);
        setShow(true);
        store.errorMessage = '';
    }

    return (<>
            {store.isAuth && store.user.isActivated ? (<>
                <CfaAccountSubMenu class1="organization-link px-4" class2="organization-link px-4" class3="organization-link px-4 active"/>
                <Container className='mb-5'>
                <h2 className='mt-5'>Витрина ЦФА</h2>
                <div className='dashboard-cfa mt-5'>
                    <Tabs defaultActiveKey="step1" variant="tabs" id="info-tab-example" className="mb-3" >
                        <Tab eventKey="step1" title="Первичное приобретение ЦФА">
                            <Row className="cfa-table-header mt-5 me-0 ms-0">
                                <Col lg={4}>Наименование</Col>
                                <Col lg={2}>Эмитент</Col>
                                <Col className="text-center" lg={1}>Количество</Col>
                                <Col className="text-end text-center" lg={1}>Дата погашения</Col>
                                <Col className="text-center" lg={1}>Цена</Col>
                                <Col className="text-center" lg={2}>Стоимость</Col>
                                <Col className="text-center" lg={1}></Col>
                            </Row>
                            {cfaList ? cfaList.map(function(data:any, idx:any){
                                return (<>
                                    <Row key={idx} className="align-items-center cfa-table-body mt-4 me-0 ms-0">
                                        <Col className="ps-0" lg={4}>
                                            <a className="detail-cfa-link" href={`/cfa/`+ data.emitID + `/` + data._id}>
                                                <div className='cfa-table-title'>
                                                    <div className='cfa-table-sub-title'>Digital-Grain_{data.ticker}</div>
                                                    <div className='cfa-table-name'>{data.releaseName}</div>
                                                </div>
                                            </a>
                                        </Col>
                                        <Col lg={2} className='align-items-center'><img className="me-3" src={emitImage} alt="Эмитент"/><span>{data.emitName}</span></Col>
                                        <Col className="text-center" lg={1}>{data.countCfa}</Col>
                                        <Col className="text-end text-center" lg={1}>{data.dateCompleateCfa}</Col>
                                        <Col className="text-center" lg={1}>{data.priceCfa.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} &#8381;</Col>
                                        <Col className="text-center cmpleate-price-cfa" lg={2}>{(data.countCfa * data.priceCfa).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} &#8381;</Col>
                                        <Col className="text-center" lg={1}>
                                            <div className='btn-outline-wrap-gold mt-3 mb-4'>
                                                <button onClick={() => buyCFA(data.emitID, data._id)} className='btn btn-outline'>Купить</button>
                                            </div>
                                        </Col>
                                    </Row>
                                </>);
                            }) : (<></>)
                        }
                        </Tab>
                        <Tab eventKey="step2" title="Вторичное обращение ЦФА">
                            <Row className="cfa-table-header mt-5 me-0 ms-0">
                                <Col lg={4}>Наименование</Col>
                                <Col lg={3}>Эмитент</Col>
                                <Col className="text-center" lg={1}>Количество</Col>
                                <Col className="text-center" lg={1}>Цена</Col>
                                <Col className="text-center" lg={2}>Стоимость</Col>
                                <Col className="text-center" lg={1}></Col>
                            </Row>
                            {store.secondaryCFA[0] ? store.secondaryCFA[0].secondaryCfa.map(function(data:any, idx:any){
                                     return (<>
                                        <Row key={idx} className="align-items-center cfa-table-body mt-4 me-0 ms-0">
                                            <Col className="ps-0" lg={4}>
                                                <a className="detail-cfa-link" href={`/cfa/`+ data.emitID + `/` + data._id}>
                                                    <div className='cfa-table-title'>
                                                        <div className='cfa-table-sub-title'>Digital-Grain_data.ticker</div>
                                                        <div className='cfa-table-name'>data.releaseName</div>
                                                    </div>
                                                </a>
                                            </Col>
                                            <Col lg={3} className='align-items-center'><img className="me-3" src={emitImage} alt="Эмитент"/><span>data.emitName</span></Col>
                                            <Col className="text-center" lg={1}>{data.countCfa}</Col>
                                            <Col className="text-center" lg={1}>{data.priceSellCfa.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} &#8381;</Col>
                                            <Col className="text-center cmpleate-price-cfa" lg={2}>{data.totalAmount} &#8381;</Col>
                                            <Col className="text-center" lg={1}>
                                                <div className='btn-outline-wrap-gold mt-3 mb-4'>
                                                    <button onClick={() => buyCFA(data.emitID, data._id)} className='btn btn-outline'>Купить</button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>);
                                }) : (<></>)
                            }
                        </Tab>
                    </Tabs>
                </div>
                <Modal className='cfa-modal' show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                    <BuyCFA emitID={emitID} cfaID={cfaID} show={show} />
                </Modal>
                </Container>
            </>) : (<>
                <h3 className='my-5'>{store.user.name}, добро пожаловать на платформу. <br/><br/>
                Для продолжения регистрации перейдите, пожалуйста, по ссылке из письма, отправленного на указанный Вами адрес электронной почты.</h3>
            </>)}
        </>
    );
}
 
export default observer(DashboardCfa);