import {FC, useContext } from 'react';
import {observer} from "mobx-react-lite";

import {Context} from "../index";
import { Container } from 'react-bootstrap';
import AccountTraderInformation from '../components/account/AccountTraderInformation';
import OrgAccountSubMenu from '../components/account/parts/OrgAccountSubMenu';
import URL from "../config/default.json";
export const env = URL.ENVIRONMENT;

const Trader: FC = () => {
    const {store} = useContext(Context);
    const traderPlatformRole = store.bank && store.bank.traderPlatformRole ? store.bank.traderPlatformRole : false;

    return (<>
            {store.isAuth ? (
            <OrgAccountSubMenu class1="organization-link px-4" class2="organization-link px-4" class3="organization-link px-4 active"/>) : <></>}
            <Container className='mb-5'>
                {(store.user.isActivated && store.isAuth && traderPlatformRole) || env !== 'local' ? <AccountTraderInformation/> : <>
                    <h3 className='my-5'>{store.user.name}, выбранная роль на платформе не позволяет Вам использовать данный функционал. <br/><br/>
                        Пожалуйста, обновите роль на платформе в Анкете юридического лица, спасибо</h3>
                </>}
                
                <div className="text-center">
                    <h2 className='my-5'>{store.isAuth ? '' : 'АВТОРИЗУЙТЕСЬ'}</h2>
                    {store.isAuth && !store.user.isActivated ? (<>
                        <h3 className='my-5'>{store.user.name}, добро пожаловать на платформу. <br/><br/>
                        Для продолжения регистрации перейдите, пожалуйста, по ссылке из письма, отправленного на указанный Вами адрес электронной почты.</h3>
                    </>) : (<>
                    </>)}
                </div>
            </Container>
        </>
    );
};
export default observer(Trader);