import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Context } from "../../..";

const CfaAccountSubMenu = (props: { class1: string | undefined; class2: string | undefined; class3: string | undefined;}) => {
    const {store} = useContext(Context);
    let isFromValid = store.bank.isFormValid || store.user.isTokeniqueUserApprove;
    return (<>{isFromValid ? (
        <div className='organization-links'>
        <Container>
            <div className='organization-link-box'>
                <Link className={props.class1} to="/my-cfa" >Мои ЦФА</Link>
                <Link className={props.class2} to="/history-transaction" >История транзакций</Link>
                <Link className={props.class3} to="/dashboard-cfa" >Витрина ЦФА</Link>
            </div>
        </Container>
    </div>
    ) : (<></>)} </>);
}
 
export default CfaAccountSubMenu;