import $api from "../http";
import {AxiosResponse} from 'axios';
import {ProductionResponse} from '../models/response/ProductionResponse';

export default class ProductionInfoService {
    static async saveProductionInfoService(
        email: string,
        user: string,
        typeOfProduction: string,
        powerOfProduction: string,
        sortOfProduction: string,
        productionDescription: string,
        dateAdded: string,
        uploadProductionPhoto: any
        ): Promise<AxiosResponse<ProductionResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('typeOfProduction', typeOfProduction);
        formData.append('powerOfProduction', powerOfProduction);
        formData.append('sortOfProduction', sortOfProduction);
        formData.append('productionDescription', productionDescription);
        formData.append('dateAdded', dateAdded);

        for (let index = 0; index < uploadProductionPhoto.length; index++) {
            const photo = uploadProductionPhoto[index];
            formData.append('uploadProductionPhoto', photo);
        }
        

        return $api.post<ProductionResponse>('/saveProduction', formData, {
            headers: {
                'enctype': 'multipart/form-data'
            }
        })
    }
    static async removeProductionItem(
        email: string,
        itemId: string,
        uploadProductionPhotoPath: [],
        ): Promise<AxiosResponse<ProductionResponse>> {

        return $api.post<ProductionResponse>('/removeProductionItem', {email, itemId, uploadProductionPhotoPath})
    }
}