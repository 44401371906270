import $api from "../http";
import {AxiosResponse} from 'axios';
import {SuppliesResponse} from '../models/response/SuppliesResponse';

export default class SuppliesInfoService {
    static async saveSuppliesInfoService(
        email: string,
        user: string,
        typeOfCorn: string,
        volumenOfSupplies: string,
        suppliesConditions: string,
        readyToSell: boolean,
        dateAdded: string
        ): Promise<AxiosResponse<SuppliesResponse>> {

        return $api.post<SuppliesResponse>('/saveSupplies',{email, user, typeOfCorn, volumenOfSupplies, suppliesConditions, readyToSell, dateAdded})
    }
    static async removeSuppliesItem(
        email: string,
        itemId: string,
        ): Promise<AxiosResponse<SuppliesResponse>> {

        return $api.post<SuppliesResponse>('/removeSuppliesItem', {email, itemId})
    }
}