import { FC, useContext, useRef, useState } from "react";
import {Button, Col, Container, Row, Form, Tab, Tabs, FormLabel, Alert, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ru } from 'date-fns/locale/ru';

import {Context} from "../../index";
import URL from "../../config/default.json"

import selectFile from "../../img/icons/fileUpload.svg";
import envelope from "../../img/icons/Envelope.svg";


const BaseTemplate: FC = () => {
    const {store} = useContext(Context);

    let currDate;
    let dateStartShowCfaDB = store.releaseCfaInfo ? store.releaseCfaInfo.dateStartShowCfa : '';
    if (dateStartShowCfaDB && dateStartShowCfaDB !== '') {
        currDate = new Date(dateStartShowCfaDB.split('.')[1]+'/'+dateStartShowCfaDB.split('.')[0]+'/'+dateStartShowCfaDB.split('.')[2]);
    }
    const [startDate, setStartDate] = useState<any>(currDate);
    const setMinStartDate = (date:any) => {
        setStartDate(date);
        setMinDateEndShowCfa(date);
        setMinDateCompleateCfa(date);
        setMinDateEmissionCfa(date);
        if (date > startDate2) {
            setStartDate2(date);
        }
        if (date > startDate3) {
            setStartDate3(date);
        }
        if (date > startDate4) {
            setStartDate4(date);
        }
    }

    let currDate2;
    let dateEndShowCfaDB = store.releaseCfaInfo ? store.releaseCfaInfo.dateEndShowCfa : '';
    if (dateStartShowCfaDB && dateStartShowCfaDB !== '') {
        currDate2 = new Date(dateEndShowCfaDB.split('.')[1]+'/'+dateEndShowCfaDB.split('.')[0]+'/'+dateEndShowCfaDB.split('.')[2]);
    }
    const [startDate2, setStartDate2] = useState<any>(currDate2);
    const setMinStartDate2 = (date:any) => {
        setStartDate2(date);
        setMinDateEmissionCfa(date);
        if (date > startDate3) {
            setStartDate3(date);
            setMinDateCompleateCfa(date)
        }
        if (date > startDate4) {
            setStartDate4(date);
        }
    }

    let currDate3;
    let dateEmissionCfaDB = store.releaseCfaInfo ? store.releaseCfaInfo.dateEmissionCfa : '';
    if (dateStartShowCfaDB && dateStartShowCfaDB !== '') {
        currDate3 = new Date(dateEmissionCfaDB.split('.')[1]+'/'+dateEmissionCfaDB.split('.')[0]+'/'+dateEmissionCfaDB.split('.')[2]);
    }
    const [startDate3, setStartDate3] = useState<any>(currDate3);
    const setMinStartDate3 = (date:any) => {
        setStartDate3(date);
        setMinDateCompleateCfa(date);
        if (date > startDate4) {
            setStartDate4(date);
        }
    }

    let currDate4;
    let dateDateCompleateCfa = store.releaseCfaInfo ? store.releaseCfaInfo.dateEmissionCfa : '';
    if (dateDateCompleateCfa && dateDateCompleateCfa !== '') {
        currDate4 = new Date(dateDateCompleateCfa.split('.')[1]+'/'+dateDateCompleateCfa.split('.')[0]+'/'+dateDateCompleateCfa.split('.')[2]);
    }
    const [startDate4, setStartDate4] = useState<any>(currDate4);
    const setMinStartDate4 = (date:any) => {
        setStartDate4(date);
    }

    let currDate5;
    let dateReleaseDecision = store.releaseCfaInfo ? store.releaseCfaInfo.dateReleaseDecision : '';
    if (dateReleaseDecision && dateReleaseDecision !== '') {
        currDate5 = new Date(dateReleaseDecision.split('.')[1]+'/'+dateReleaseDecision.split('.')[0]+'/'+dateReleaseDecision.split('.')[2]);
    }
    const [startDate5, setStartDate5] = useState<any>(currDate5);
    const setMinStartDate5 = (date:any) => {
        setStartDate5(date);
    }

    const [minDateEndShowCfa, setMinDateEndShowCfa] = useState<any>(currDate2 || new Date());
    const [minDateEmissionCfa, setMinDateEmissionCfa] = useState<any>(currDate3 || new Date());
    const [minDateCompleateCfa, setMinDateCompleateCfa] = useState<any>(currDate4 || new Date());


    let stepActiveBtn:boolean = true;
    let stepActiveItem:string = 'step1';

    const [validated, set_Validated] = useState<boolean>(false);
    const [key, setKey] = useState<any>(stepActiveItem);
    const [disableBtn, setActive] = useState<boolean>(stepActiveBtn);
    const [textAreaCount, setCounterData] = useState<number>(store.releaseCfaInfo && store.releaseCfaInfo.moreInfo ? store.releaseCfaInfo.moreInfo.length : 0);
    const fileLimit = URL.FILE_LIMIT;
    const maxLength = URL.TEXTAREA_LIMIT;

    // variables for uploadReleaseCfa input
    const uploadReleaseCFADBelemnt = store.releaseCfaInfo && store.releaseCfaInfo.uploadReleaseCfaPath ? store.releaseCfaInfo.uploadReleaseCfaPath : '';
    const fileNameReleaseCFADB = uploadReleaseCFADBelemnt !== '' ? uploadReleaseCFADBelemnt.split('-f-n-').pop() : '';
    const filePickerLicenseReleaseCFA = useRef<HTMLInputElement>(null);
    const [errorFileSizeReleaseCFA, setErrorReleaseCFA] = useState<boolean>(false);
    const [filenameReleaseCFA, setFilenameReleaseCFA] = useState<string>(fileNameReleaseCFADB ? fileNameReleaseCFADB : 'Решение о выпуске ЦФА');
    const uploadHandleChnageReleaseCFA = (e: any) => {
        if (e.target.files[0].size > fileLimit) {
            setErrorReleaseCFA(true);
            
            if (filePickerLicenseReleaseCFA.current) {
                filePickerLicenseReleaseCFA.current.value = "";
            }
        } else {
            setFilenameReleaseCFA(e.target.files[0].name);
            setErrorReleaseCFA(false);
        }
    }

    const handlePickReleaseCFA = () => {
        if (filePickerLicenseReleaseCFA.current) {
            filePickerLicenseReleaseCFA.current.click();
        }
    }

    // variables for uploadUNEP input
    const uploadUNEPDBelemnt = store.releaseCfaInfo && store.releaseCfaInfo.uploadUNEPPath ? store.releaseCfaInfo.uploadUNEPPath : '';
    const fileNameUNEPDB = uploadUNEPDBelemnt !== '' ? uploadUNEPDBelemnt.split('-f-n-').pop() : '';
    const filePickerLicenseUNEP = useRef<HTMLInputElement>(null);
    const [errorFileSizeUNEP, setErrorUNEP] = useState<boolean>(false);
    const [filenameUNEP, setFilenameUNEP] = useState<string>(fileNameUNEPDB ? fileNameUNEPDB : 'УНЭП к решению о выпуске');
    const uploadHandleChnageUNEP = (e: any) => {
        if (e.target.files[0].size > fileLimit) {
            setErrorUNEP(true);
            
            if (filePickerLicenseUNEP.current) {
                filePickerLicenseUNEP.current.value = "";
            }
        } else {
            setFilenameUNEP(e.target.files[0].name);
            setErrorUNEP(false);
        }
    }

    const handlePickUNEP = () => {
        if (filePickerLicenseUNEP.current) {
            filePickerLicenseUNEP.current.click();
        }
    }

    // variables for uploadRisks input
    const uploadRisksDBelemnt = store.releaseCfaInfo && store.releaseCfaInfo.uploadRisksPath ? store.releaseCfaInfo.uploadRisksPath : '';
    const fileNameRisksDB = uploadRisksDBelemnt !== '' ? uploadRisksDBelemnt.split('-f-n-').pop() : '';
    const filePickerLicenseRisks = useRef<HTMLInputElement>(null);
    const [errorFileSizeRisks, setErrorRisks] = useState<boolean>(false);
    const [filenameRisks, setFilenameRisks] = useState<string>(fileNameRisksDB ? fileNameRisksDB : 'Уведомление о рисках');
    const uploadHandleChnageRisks = (e: any) => {
        if (e.target.files[0].size > fileLimit) {
            setErrorRisks(true);
            
            if (filePickerLicenseRisks.current) {
                filePickerLicenseRisks.current.value = "";
            }
        } else {
            setFilenameRisks(e.target.files[0].name);
            setErrorRisks(false);
        }
    }

    const handlePickRisks = () => {
        if (filePickerLicenseRisks.current) {
            filePickerLicenseRisks.current.click();
        }
    }

    // variables for uploadSignature input
    const uploadSignatureDBelemnt = store.releaseCfaInfo && store.releaseCfaInfo.uploadSignaturePath ? store.releaseCfaInfo.uploadSignaturePath : '';
    const fileNameSignatureDB = uploadSignatureDBelemnt !== '' ? uploadSignatureDBelemnt.split('-f-n-').pop() : '';
    const filePickerLicenseSignature = useRef<HTMLInputElement>(null);
    const [errorFileSizeSignature, setErrorSignature] = useState<boolean>(false);
    const [filenameSignature, setFilenameSignature] = useState<string>(fileNameSignatureDB ? fileNameSignatureDB : 'Файл подписи к уведомлению о рисках');
    const uploadHandleChnageSignature = (e: any) => {
        if (e.target.files[0].size > fileLimit) {
            setErrorSignature(true);
            
            if (filePickerLicenseSignature.current) {
                filePickerLicenseSignature.current.value = "";
            }
        } else {
            setFilenameSignature(e.target.files[0].name);
            setErrorSignature(false);
        }
    }

    const handlePickSignature = () => {
        if (filePickerLicenseSignature.current) {
            filePickerLicenseSignature.current.click();
        }
    }

    // variables for uploadAdmissionCfa input
    const uploadAdmissionCfaDBelemnt = store.releaseCfaInfo && store.releaseCfaInfo.uploadAdmissionCfaPath ? store.releaseCfaInfo.uploadAdmissionCfaPath : '';
    const fileNameAdmissionCfaDB = uploadAdmissionCfaDBelemnt !== '' ? uploadAdmissionCfaDBelemnt.split('-f-n-').pop() : '';
    const filePickerLicenseAdmissionCfa = useRef<HTMLInputElement>(null);
    const [errorFileSizeAdmissionCfa, setErrorAdmissionCfa] = useState<boolean>(false);
    const [filenameAdmissionCfa, setFilenameAdmissionCfa] = useState<string>(fileNameAdmissionCfaDB ? fileNameAdmissionCfaDB : 'Загрузить Заявление о допуске ЦФА к выпуску');
    const uploadHandleChnageAdmissionCfa = (e: any) => {
        if (e.target.files[0].size > fileLimit) {
            setErrorAdmissionCfa(true);
            
            if (filePickerLicenseAdmissionCfa.current) {
                filePickerLicenseAdmissionCfa.current.value = "";
            }
        } else {
            setFilenameAdmissionCfa(e.target.files[0].name);
            setErrorAdmissionCfa(false);
        }
    }

    const handlePickAdmissionCfa = () => {
        if (filePickerLicenseAdmissionCfa.current) {
            filePickerLicenseAdmissionCfa.current.click();
        }
    }

    // variables for uploadAdmissionUNEP input
    const uploadAdmissionUNEPDBelemnt = store.releaseCfaInfo && store.releaseCfaInfo.uploadAdmissionUNEPPath ? store.releaseCfaInfo.uploadAdmissionUNEPPath : '';
    const fileNameAdmissionUNEPDB = uploadAdmissionUNEPDBelemnt !== '' ? uploadAdmissionUNEPDBelemnt.split('-f-n-').pop() : '';
    const filePickerLicenseAdmissionUNEP = useRef<HTMLInputElement>(null);
    const [errorFileSizeAdmissionUNEP, setErrorAdmissionUNEP] = useState<boolean>(false);
    const [filenameAdmissionUNEP, setFilenameAdmissionUNEP] = useState<string>(fileNameAdmissionUNEPDB ? fileNameAdmissionUNEPDB : 'Загрузить УНЭП к заявлению о допуска');
    const uploadHandleChnageAdmissionUNEP = (e: any) => {
        if (e.target.files[0].size > fileLimit) {
            setErrorAdmissionUNEP(true);
            
            if (filePickerLicenseAdmissionUNEP.current) {
                filePickerLicenseAdmissionUNEP.current.value = "";
            }
        } else {
            setFilenameAdmissionUNEP(e.target.files[0].name);
            setErrorAdmissionUNEP(false);
        }
    }

    const handlePickAdmissionUNEP = () => {
        if (filePickerLicenseAdmissionUNEP.current) {
            filePickerLicenseAdmissionUNEP.current.click();
        }
    }

    // variables for uploadDecisionDirectors input
    const uploadDecisionDirectorsDBelemnt = store.releaseCfaInfo && store.releaseCfaInfo.uploadDecisionDirectorsPath ? store.releaseCfaInfo.uploadDecisionDirectorsPath : '';
    const fileNameDecisionDirectorsDB = uploadDecisionDirectorsDBelemnt !== '' ? uploadDecisionDirectorsDBelemnt.split('-f-n-').pop() : '';
    const filePickerLicenseDecisionDirectors = useRef<HTMLInputElement>(null);
    const [errorFileSizeDecisionDirectors, setErrorDecisionDirectors] = useState<boolean>(false);
    const [filenameDecisionDirectors, setFilenameDecisionDirectors] = useState<string>(fileNameDecisionDirectorsDB ? fileNameDecisionDirectorsDB : 'Загрузить выписку из решения совета директоров');
    const uploadHandleChnageDecisionDirectors = (e: any) => {
        if (e.target.files[0].size > fileLimit) {
            setErrorDecisionDirectors(true);
            
            if (filePickerLicenseDecisionDirectors.current) {
                filePickerLicenseDecisionDirectors.current.value = "";
            }
        } else {
            setFilenameDecisionDirectors(e.target.files[0].name);
            setErrorDecisionDirectors(false);
        }
    }

    const handlePickDecisionDirectors = () => {
        if (filePickerLicenseDecisionDirectors.current) {
            filePickerLicenseDecisionDirectors.current.click();
        }
    }

    // variables for uploadExtractSignature input
    const uploadExtractSignatureDBelemnt = store.releaseCfaInfo && store.releaseCfaInfo.uploadExtractSignaturePath ? store.releaseCfaInfo.uploadExtractSignaturePath : '';
    const fileNameExtractSignatureDB = uploadExtractSignatureDBelemnt !== '' ? uploadExtractSignatureDBelemnt.split('-f-n-').pop() : '';
    const filePickerLicenseExtractSignature = useRef<HTMLInputElement>(null);
    const [errorFileSizeExtractSignature, setErrorExtractSignature] = useState<boolean>(false);
    const [filenameExtractSignature, setFilenameExtractSignature] = useState<string>(fileNameExtractSignatureDB ? fileNameExtractSignatureDB : 'Загрузить файл подписи к выписке');
    const uploadHandleChnageExtractSignature = (e: any) => {
        if (e.target.files[0].size > fileLimit) {
            setErrorExtractSignature(true);
            
            if (filePickerLicenseExtractSignature.current) {
                filePickerLicenseExtractSignature.current.value = "";
            }
        } else {
            setFilenameExtractSignature(e.target.files[0].name);
            setErrorExtractSignature(false);
        }
    }

    const handlePickExtractSignature = () => {
        if (filePickerLicenseExtractSignature.current) {
            filePickerLicenseExtractSignature.current.click();
        }
    }

    // variables for uploadAdditional input
    const uploadAdditionalDBelemnt = store.releaseCfaInfo && store.releaseCfaInfo.uploadAdditionalPath ? store.releaseCfaInfo.uploadAdditionalPath : '';
    const fileNameAdditionalDB = uploadAdditionalDBelemnt !== '' ? uploadAdditionalDBelemnt.split('-f-n-').pop() : '';
    const filePickerLicenseAdditional = useRef<HTMLInputElement>(null);
    const [errorFileSizeAdditional, setErrorAdditional] = useState<boolean>(false);
    const [filenameAdditional, setFilenameAdditional] = useState<string>(fileNameAdditionalDB ? fileNameAdditionalDB : 'Загрузить файл подписи к выписке');
    const uploadHandleChnageAdditional = (e: any) => {
        if (e.target.files[0].size > fileLimit) {
            setErrorAdditional(true);
            
            if (filePickerLicenseAdditional.current) {
                filePickerLicenseAdditional.current.value = "";
            }
        } else {
            setFilenameAdditional(e.target.files[0].name);
            setErrorAdditional(false);
        }
    }

    const handlePickAdditional = () => {
        if (filePickerLicenseAdditional.current) {
            filePickerLicenseAdditional.current.click();
        }
    }

    const [requiredReleaseCFA, setrequiredReleaseCFA] = useState<boolean>(false);

    const ownerTypeDB = store.releaseCfaInfo.ownerType;

    const [form_Data, set_Form_Data] = useState({
        emitName: store.company.organizationalLegalFor + ' ' + store.company.shortCompanyName,
        releaseName: store.releaseCfaInfo.releaseName,
        ticker: store.releaseCfaInfo.ticker,
        ownerType: store.releaseCfaInfo.ownerType,
        countCfa: store.releaseCfaInfo.countCfa,
        priceCfa: store.releaseCfaInfo.priceCfa,
        dateStartShowCfa: store.releaseCfaInfo.dateStartShowCfa,
        dateEndShowCfa: store.releaseCfaInfo.dateEndShowCfa,
        dateEmissionCfa: store.releaseCfaInfo.dateEmissionCfa,
        priceCompleateCfa: store.releaseCfaInfo.priceCompleateCfa,
        dateCompleateCfa: store.releaseCfaInfo.dateCompleateCfa,
        moreInfo: store.releaseCfaInfo.moreInfo,
        dateReleaseDecision: store.releaseCfaInfo.dateReleaseDecision,
        extractDirectors: store.releaseCfaInfo.extractDirectors,
        typeOfReleaseCfa: store.releaseCfaInfo.typeOfReleaseCfa,
        customersListFIO: store.releaseCfaInfo.customersListFIO,
        customersListINN: store.releaseCfaInfo.customersListINN,
        customersListLine: store.releaseCfaInfo.customersListLine
        
    });

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });

        if (name === 'moreInfo') {
            setCounterData(e.target.value.length);
        }
    };

    const NextStep = (e: any) => {
        const form = e.currentTarget.closest('form');
        e.preventDefault();
        if (form.checkValidity() === true) {
            const emitName = store.company.organizationalLegalFor + ' ' + store.company.shortCompanyName;
            const releaseName = form.elements.releaseName.value;
            const ticker = form.elements.ticker.value;
            const ownerType = form.elements.ownerType.value;
            const countCfa = form.elements.countCfa.value;
            const priceCfa = form.elements.priceCfa.value;
            const dateStartShowCfa = form.elements.dateStartShowCfa.value;
            const dateEndShowCfa = form.elements.dateEndShowCfa.value;
            const dateEmissionCfa = form.elements.dateEmissionCfa.value;
            const priceCompleateCfa = form.elements.priceCompleateCfa.value;
            const dateCompleateCfa = form.elements.dateCompleateCfa.value;
            const dateAdded = form.elements.dateAdded.value;
            const isFormCopleated = 'false';
            const uploadReleaseCfa = form.elements.uploadReleaseCfa.files[0];
            const removeuploadReleaseCfa = uploadReleaseCFADBelemnt;
            const uploadUNEP = form.elements.uploadUNEP.files[0];
            const removeuploadUNEP = uploadUNEPDBelemnt;
            const uploadRisks = form.elements.uploadRisks.files[0];
            const removeuploadRisks = uploadRisksDBelemnt;
            const uploadSignature = form.elements.uploadSignature.files[0];
            const removeuploadSignature = uploadSignatureDBelemnt;
            const uploadAdmissionCfa = form.elements?.uploadAdmissionCfa?.files[0];
            const removeuploadAdmissionCfa = uploadAdmissionCfaDBelemnt;
            const uploadAdmissionUNEP = form.elements?.uploadAdmissionUNEP?.files[0];
            const removeuploadAdmissionUNEP = uploadAdmissionUNEPDBelemnt;
            const uploadDecisionDirectors = form.elements?.uploadDecisionDirectors?.files[0];
            const removeuploadDecisionDirectors = uploadDecisionDirectorsDBelemnt;
            const uploadExtractSignature = form.elements?.uploadExtractSignature?.files[0];
            const removeuploadExtractSignature = uploadExtractSignatureDBelemnt;
            const uploadAdditional = form.elements?.uploadAdditional?.files[0];
            const removeuploadAdditional = uploadAdditionalDBelemnt;
            const moreInfo = form.elements.moreInfo.value;
            const dateReleaseDecision = form.elements.dateReleaseDecision.value;
            const customersListLine = form.elements.customersListLine?.value || '';
            const typeOfReleaseCfa = form.elements.typeOfReleaseCfa.value;
            const customersListFIO = form.elements.customersListFIO.value;
            const customersListINN = form.elements.customersListINN.value;

            store.saveReleaseCFA(store.user.email, store.user.id, emitName, releaseName, ticker, ownerType, countCfa, priceCfa, dateStartShowCfa, dateEndShowCfa, dateEmissionCfa, priceCompleateCfa, dateCompleateCfa, dateAdded, uploadReleaseCfa, isFormCopleated, removeuploadReleaseCfa, uploadUNEP, removeuploadUNEP, uploadRisks, removeuploadRisks, uploadSignature, removeuploadSignature, moreInfo, dateReleaseDecision, uploadAdmissionCfa, removeuploadAdmissionCfa, uploadAdmissionUNEP, removeuploadAdmissionUNEP, uploadDecisionDirectors, removeuploadDecisionDirectors, uploadExtractSignature, removeuploadExtractSignature, uploadAdditional, removeuploadAdditional, customersListLine, typeOfReleaseCfa, customersListFIO, customersListINN);
            set_Validated(false);

            setKey(e.target.id);
            setActive(false);
            window.scrollTo(0, 0);
            if (fileNameReleaseCFADB !== '') {
                setrequiredReleaseCFA(false);
            } else {
                setrequiredReleaseCFA(true);
            }
            
        } else {
            set_Validated(true);
        }
    }

    const saveReleaseCfa = (e: any, saveFormay:boolean) => {
        const form = e.currentTarget.closest('form');
        e.preventDefault();
        if (form.checkValidity() === true) {
            const emitName = store.user.companyName ? store.user.companyName : store.company?.organizationalLegalFor + ' ' + store.company?.shortCompanyName;
            const releaseName = form.elements.releaseName.value;
            const ticker = form.elements.ticker.value;
            const ownerType = form.elements.ownerType.value;
            const countCfa = form.elements.countCfa.value;
            const priceCfa = form.elements.priceCfa.value;
            const dateStartShowCfa = form.elements.dateStartShowCfa.value;
            const dateEndShowCfa = form.elements.dateEndShowCfa.value;
            const dateEmissionCfa = form.elements.dateEmissionCfa.value;
            const priceCompleateCfa = form.elements.priceCompleateCfa.value;
            const dateCompleateCfa = form.elements.dateCompleateCfa.value;
            const dateAdded = form.elements.dateAdded.value;
            const uploadReleaseCfa = form.elements.uploadReleaseCfa.files[0];
            const removeuploadReleaseCfa = uploadReleaseCFADBelemnt;
            const uploadUNEP = form.elements.uploadUNEP.files[0];
            const removeuploadUNEP = uploadUNEPDBelemnt;
            const uploadRisks = form.elements.uploadRisks.files[0];
            const removeuploadRisks = uploadRisksDBelemnt;
            const uploadSignature = form.elements.uploadSignature.files[0];
            const removeuploadSignature = uploadSignatureDBelemnt;
            const moreInfo = form.elements.moreInfo.value;
            const dateReleaseDecision = form.elements.dateReleaseDecision.value;

            const uploadAdmissionCfa = form.elements.uploadAdmissionCfa?.files[0];
            const removeuploadAdmissionCfa = uploadAdmissionCfaDBelemnt;
            const uploadAdmissionUNEP = form.elements.uploadAdmissionUNEP?.files[0];
            const removeuploadAdmissionUNEP = uploadAdmissionUNEPDBelemnt;
            const uploadDecisionDirectors = form.elements.uploadDecisionDirectors?.files[0];
            const removeuploadDecisionDirectors = uploadDecisionDirectorsDBelemnt;
            const uploadExtractSignature = form.elements.uploadExtractSignature?.files[0];
            const removeuploadExtractSignature = uploadExtractSignatureDBelemnt;

            const uploadAdditional = form.elements.uploadAdditional.files[0];
            const removeuploadAdditional = uploadAdditionalDBelemnt;
            const customersListLine = form.elements.customersListLine?.value || '';
            const typeOfReleaseCfa = form.elements.typeOfReleaseCfa.value;

            const customersListFIO = form.elements.customersListFIO.value;
            const customersListINN = form.elements.customersListINN.value;

            //const informationSystem = form.elements.informationSystem.value;
            //const typeOfCFA = form.elements.typeOfCFA.value;
            //const counterProvision = form.elements.counterProvision.value;
            

            let isFormCopleated = 'false';
            if (saveFormay) {
                isFormCopleated = 'true';
            }

            store.saveReleaseCFA(store.user.email, store.user.id, emitName, releaseName, ticker, ownerType, countCfa, priceCfa, dateStartShowCfa, dateEndShowCfa, dateEmissionCfa, priceCompleateCfa, dateCompleateCfa, dateAdded, uploadReleaseCfa, isFormCopleated, removeuploadReleaseCfa, uploadUNEP, removeuploadUNEP, uploadRisks, removeuploadRisks, uploadSignature, removeuploadSignature, moreInfo, dateReleaseDecision, uploadAdmissionCfa, removeuploadAdmissionCfa, uploadAdmissionUNEP, removeuploadAdmissionUNEP, uploadDecisionDirectors, removeuploadDecisionDirectors, uploadExtractSignature, removeuploadExtractSignature, uploadAdditional, removeuploadAdditional, customersListLine, typeOfReleaseCfa, customersListFIO, customersListINN)
            .then(
                () => {
                    if (saveFormay) {
                        setShowConfirmation(true);
                        const releaseCfaLength = store.releaseCfaInfo.releaseCfa.length;
                        const releaseCfaItem = store.releaseCfaInfo?.releaseCfa[releaseCfaLength - 1];
                        let customerList = releaseCfaItem.customersListINN;
                        if (customersListLineObject !== ''){
                            let groupLinefromBD = releaseCfaItem.customersListLine.split('/-/').slice(0, -1);
                            for (let i = 0; i < groupLinefromBD.length; i++) {
                                customerList = customerList + ',' + groupLinefromBD[i].split('/+/')[1];
                            }
                        }
                        set_external_Data({
                            ...external_Data,
                            newCfaID: String(releaseCfaItem._id),
                            countCfa: String(releaseCfaItem.countCfa),
                            priceCfa: releaseCfaItem.priceCfa,
                            priceCompleateCfa: releaseCfaItem.priceCompleateCfa,
                            dateStartShowCfa: releaseCfaItem.dateStartShowCfa,
                            dateEndShowCfa: releaseCfaItem.dateEndShowCfa,
                            dateEmissionCfa: releaseCfaItem.dateEmissionCfa,
                            dateCompleateCfa: releaseCfaItem.dateCompleateCfa,
                            ticker: releaseCfaItem.ticker,
                            dateReleaseDecision: releaseCfaItem.dateReleaseDecision,
                            releaseName: releaseCfaItem.releaseName,
                            customerList: customerList
                        })
                    }
                }
            ).catch(() => {console.log('error')});
            set_Validated(false);
            if (saveFormay) {
                set_Form_Data({
                    emitName: store.company.organizationalLegalFor + ' ' + store.company.shortCompanyName,
                    releaseName: '',
                    ticker: '',
                    ownerType: '',
                    countCfa: '',
                    priceCfa: '',
                    dateStartShowCfa: '',
                    dateEndShowCfa: '',
                    dateEmissionCfa: '',
                    priceCompleateCfa: '',
                    dateCompleateCfa: '',
                    moreInfo: '',
                    dateReleaseDecision: '',
                    extractDirectors: 'no',
                    typeOfReleaseCfa: 'public',
                    customersListFIO:'',
                    customersListINN: '',
                    customersListLine: ''
                });
                setRadioPrivatField('public');
                setPrivatField(false);
                setGroups([]);
                setCustomersListLineObject('');
                setFilenameReleaseCFA('Решение о выпуске ЦФА');
                setFilenameUNEP('УНЭП к решению о выпуске');
                setFilenameRisks('Уведомление о рисках');
                setFilenameSignature('Файл подписи к уведомлению о рисках');
                setFilenameAdmissionCfa('Загрузить Заявление о допуске ЦФА к выпуску');
                setFilenameAdmissionUNEP('Загрузить УНЭП к заявлению о допуска');
                setFilenameDecisionDirectors('Загрузить выписку из решения совета директоров');
                setFilenameExtractSignature('Загрузить файл подписи к выписке');
                setFilenameAdditional('Загрузить файл подписи к выписке');



                if (filePickerLicenseReleaseCFA.current) {
                    filePickerLicenseReleaseCFA.current.value = "";
                }
                if (filePickerLicenseSignature.current) {
                    filePickerLicenseSignature.current.value = "";
                }
                if (filePickerLicenseRisks.current) {
                    filePickerLicenseRisks.current.value = "";
                }
                if (filePickerLicenseUNEP.current) {
                    filePickerLicenseUNEP.current.value = "";
                }
                if (filePickerLicenseAdmissionCfa.current) {
                    filePickerLicenseAdmissionCfa.current.value = "";
                }
                if (filePickerLicenseAdmissionUNEP.current) {
                    filePickerLicenseAdmissionUNEP.current.value = "";
                }
                if (filePickerLicenseDecisionDirectors.current) {
                    filePickerLicenseDecisionDirectors.current.value = "";
                }
                if (filePickerLicenseExtractSignature.current) {
                    filePickerLicenseExtractSignature.current.value = "";
                }
                if (filePickerLicenseAdditional.current) {
                    filePickerLicenseAdditional.current.value = "";
                }

            } else {
                setShow(true);
            }
        } else {
            set_Validated(true);
            setShowError(true);
        }
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleCloseConfirmation = () => setShowConfirmation(false);
    const [showError, setShowError] = useState(false);
    const handleCloseError = () => setShowError(false);

    const [external_Data, set_external_Data] = useState({
        newCfaID: '',
        countCfa: '',
        priceCfa: '',
        priceCompleateCfa: '',
        dateStartShowCfa: '',
        dateEndShowCfa: '',
        dateEmissionCfa: '',
        dateCompleateCfa: '',
        ticker: '',
        dateReleaseDecision: '',
        releaseName: '',
        customerList: ''
    });
    const redirectToExternal = () => {
        const baseUrl = "https://client.dev-01.dev.phoenix.tokenique.app/issuances/requests/new";
        const params = new URLSearchParams({
            cfaId: external_Data.newCfaID,
            iscounteroffer: 'false',
            minamount: external_Data.countCfa+'00000000',
            price: external_Data.priceCfa+'00000000',
            hasnominal: 'false',
            nominal: external_Data.priceCompleateCfa+'00000000',
            redemptionOutOfNominalAccount: 'false',
            decimals: '0',
            allowearlyredemption: 'false',
            releasedecisionnumber: external_Data.releaseName,
            releasedecisiondate: external_Data.dateReleaseDecision,
            investmentstartdate: external_Data.dateStartShowCfa,
            investmentenddate: external_Data.dateEndShowCfa,
            deliverydate: external_Data.dateEmissionCfa,
            minchangeredemptiondateDisabled: 'true',
            minchangeredemptiondate: '',
            redemptiondate: external_Data.dateCompleateCfa,
            customerList: external_Data.customerList
        }).toString();
        window.open(`${baseUrl}?${params}`, "_blank");
        setShowConfirmation(false);
    };

    const [showOwnerDoc, extractDirectors] = useState<boolean>(false);
    const [radioProtector, setRadioProtector] = useState<string>(store.releaseCfaInfo.extractDirectors || 'no');

    const [showPrivatField, setPrivatField] = useState<boolean>(store.releaseCfaInfo.typeOfReleaseCfa === 'privat' ? true : false);
    const [radioPrivatField, setRadioPrivatField] = useState<string>(store.releaseCfaInfo.typeOfReleaseCfa || 'public');

    const handleCheckboxChange = (score: any, inputName: string) => {
        if (score === 'yes') {
            extractDirectors(true);
            setRadioProtector(score);
        } else if (score === 'no'){
            extractDirectors(false);
            setRadioProtector(score);
        }

        if (score === 'privat') {
            setPrivatField(true);
            setRadioPrivatField(score);
        } else if (score === 'public'){
            setPrivatField(false);
            setRadioPrivatField(score);
        }

        set_Form_Data({
            ...form_Data,
            [inputName]: score,
        });
    };
    const [customersListLineObject, setCustomersListLineObject]  = useState<string>(form_Data.customersListLine || '');
    let groupLinefromBD:any = [];
    if (form_Data.customersListLine) {
        groupLinefromBD = form_Data.customersListLine.split('/-/').slice(0, -1);
    }
    
    let groupLineFinal:string[][] = [];
    for (let i = 0; i < groupLinefromBD.length; i++) {
        groupLineFinal[i] = groupLinefromBD[i].split('/+/');
    }

    const [groups, setGroups] = useState<string[][]>(groupLineFinal || []);
    const updateCustomerList = (e:any): void => {
        e.preventDefault();
        const input1 = form_Data.customersListFIO;
        const input2 = form_Data.customersListINN;
        const newGroup = [input1.trim(), input2.trim()].filter(Boolean);
        if (newGroup.length > 0) {
            let newOwnerLine = customersListLineObject + input1 + '/+/' + input2 + '/+//-/';
            setCustomersListLineObject(newOwnerLine);
        
            setGroups((prevGroups) => [...prevGroups, newGroup]);
            set_Form_Data({
                ...form_Data,
                customersListFIO: '',
                customersListINN: '',
            });
        }
    };
    const removeGroup = (index: number): void => {
        setGroups((prevGroups) => prevGroups.filter((_, i) => i !== index));
        let newOwnerLine = '';
        for (let i = 0; i < groups.length; i++) {
            const element = groups[i];
            if (i !== index) {
                newOwnerLine = newOwnerLine + element[0] + '/+/' + element[1] + '/+/' + element[2] + '/-/';
            }
        }
        setCustomersListLineObject(newOwnerLine);
    };

    return ( <>
        {store.isAuth && store.user.isActivated ? (<>
            <div className='organization-links'>
                <Container>
                    <div className='d-flex justify-content-between align-items-center'>
                        <Link className="btn btn-secondary my-3" to="/release-cfa">Вернуться к списку ЦФА</Link>
                    </div>
                </Container>
            </div>
            <Container className="base-template">
                <Row>
                    <Col lg={6} className="pe-0">
                        <Form noValidate validated={validated} className="base-template-form">
                            <Tabs 
                                defaultActiveKey={stepActiveItem}
                                variant="pills"
                                id="pills-tab-example"
                                className="mb-3"
                                justify
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                >
                                <Tab eventKey="step1" title="Общая информация о ЦФА">
                                    <Form.Group as={Col} className="mb-3" controlId="validationInformationSystem">
                                        <FormLabel className='required'>Оператор информационной системы ЦФА</FormLabel>
                                        <Form.Select
                                            name="informationSystem"
                                            className='w-100 legal-form-1'
                                            required
                                            defaultValue="ОИС Токеник"
                                        >
                                            <option value="ОИС Токеник"> ОИС "Токеник"</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                        
                                    </Form.Group>
                                    
                                    <Form.Group as={Col} className="mb-3" controlId="validationremitName">
                                        <FormLabel className='required'>Наименование эмитента</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="emitName"
                                            value={form_Data.emitName}
                                            onChange={chngFn}
                                            className='w-100 legal-form-1'
                                            required
                                            placeholder='Наименование эмитента'
                                            disabled
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} className="mb-3" controlId="validationInformationSystem">
                                        <FormLabel className='required'>Тип ЦФА</FormLabel>
                                        <Form.Select
                                            name="typeOfCFA"
                                            className='w-100 legal-form-1'
                                            required
                                            defaultValue="ВЭД ЦФА"
                                        >
                                            <option>ВЭД ЦФА</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3">
                                        <FormLabel>Тип инвестора</FormLabel>
                                        <Form.Check
                                            type="radio"
                                            name="typeOfInvestorsCfa"
                                            value="yes"
                                            checked
                                            label="Все инвесторы"
                                            id="typeOfInvestorsCfaTrue"
                                            className="radio"
                                            readOnly
                                        />
                                        <Form.Check
                                            type="radio"
                                            name="typeOfInvestorsCfa"
                                            value="no"
                                            label="Выбрать инвесторов"
                                            id="typeOfInvestorsCfaFalse"
                                            className="radio"
                                            disabled
                                            readOnly
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3">
                                        <FormLabel>Встречное предоставление</FormLabel>
                                        <Form.Check
                                            type="radio"
                                            name="counterProvision"
                                            value="no"
                                            checked
                                            label="Не является"
                                            id="counterProvisionTrue"
                                            className="radio"
                                            readOnly
                                        />
                                        <Form.Check
                                            type="radio"
                                            name="counterProvision"
                                            value="yes"
                                            label="Является"
                                            id="counterProvisionFalse"
                                            className="radio"
                                            disabled
                                            readOnly
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationrfragmentation">
                                        <FormLabel className='required'>Степень дробления ЦФА</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="fragmentation"
                                            value={0}
                                            className='w-100 legal-form-1'
                                            required
                                            placeholder='Степень дробления ЦФА'
                                            disabled
                                            readOnly
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3">
                                        <FormLabel>Способ определения цены погашения ЦФА</FormLabel>
                                        <Form.Check
                                            type="radio"
                                            name="typeOfRedeemPrice"
                                            value="yes"
                                            checked
                                            label="Определить при формировании заявки на выпуск"
                                            id="typeOfRedeemPriceTrue"
                                            className="radio"
                                            readOnly
                                        />
                                        <Form.Check
                                            type="radio"
                                            name="typeOfRedeemPrice"
                                            value="no"
                                            label="Определить при погашении"
                                            id="typeOfRedeemPriceFalse"
                                            className="radio"
                                            disabled
                                            readOnly
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationtypeOfReleaseCfa">
                                        <FormLabel className='required'>Досрочное погашение ЦФА</FormLabel>
                                        <Form.Select
                                            name="redeemEarlier"
                                            className='w-100 legal-form-1'
                                            required
                                            defaultValue="Недоступно"
                                        >
                                            <option value="Недоступно">Недоступно</option>
                                            <option disabled value="Доступно по инициативе Инвестора">Доступно по инициативе Инвестора</option>
                                            <option disabled value="Доступно по инициативе Эмитента">Доступно по инициативе Эмитента</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} className="mb-3">
                                        <FormLabel>Возможность погашения ЦФА вне платформы</FormLabel>
                                        <Form.Check
                                            type="radio"
                                            name="typeOfRedeemPrice"
                                            value="no"
                                            checked
                                            label="Нет"
                                            id="typeOfRedeemPriceTrue"
                                            className="radio"
                                            readOnly
                                        />
                                        <Form.Check
                                            type="radio"
                                            name="typeOfRedeemPrice"
                                            value="yes"
                                            label="Да"
                                            id="typeOfRedeemPriceFalse"
                                            className="radio"
                                            disabled
                                            readOnly
                                        />
                                    </Form.Group>
                                    
                                    <Form.Group as={Col} className="mb-3" controlId="validationrreleaseName">
                                        <FormLabel className='required'>Наименование выпуска</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="releaseName"
                                            value={form_Data.releaseName}
                                            onChange={chngFn}
                                            className='w-100 legal-form-1'
                                            required
                                            placeholder='Укажите название выпуска ЦФА'
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationrreticker">
                                        <FormLabel className='required'>Тикер</FormLabel>
                                        <Form.Control
                                            type="text"
                                            minLength={3}
                                            maxLength={6}
                                            name="ticker"
                                            value={form_Data.ticker}
                                            onChange={chngFn}
                                            className='w-100 legal-form-1'
                                            required
                                            placeholder='Укажите короткое название из 3-6 символов'
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Длина поля должна быть от 3 до 6 символов
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3">
                                        <FormLabel>Тип выпуска</FormLabel>
                                        <Form.Check
                                            type="radio"
                                            name="typeOfReleaseCfa"
                                            value="public"
                                            checked={radioPrivatField === 'public' ? true : false}
                                            onChange={e => handleCheckboxChange('public', 'typeOfReleaseCfa')}
                                            label="Публичный выпуск ЦФА"
                                            id="isetypeOfReleaseCfaTrue"
                                            className="radio"
                                        />
                                        <Form.Check
                                            type="radio"
                                            name="typeOfReleaseCfa"
                                            value="privat"
                                            checked={radioPrivatField === 'public' ? false : true}
                                            onChange={e => handleCheckboxChange('privat', 'typeOfReleaseCfa')}
                                            label="Индивидуальный выпуск ЦФА"
                                            id="isextypeOfReleaseCfaFalse"
                                            className="radio"
                                        />
                                    </Form.Group>
                                    {showPrivatField ? <>
                                        <Form.Group as={Col} className="mb-3" controlId="validationCustomersListFIO">
                                            <FormLabel className='required'>Наименование Пользователя </FormLabel>
                                            <Form.Control
                                                type="text"
                                                name="customersListFIO"
                                                value={form_Data.customersListFIO}
                                                onChange={chngFn}
                                                className='w-100 legal-form-1'
                                                required={showPrivatField ? true : false}
                                                placeholder='Укажите Пользователя, которому будет доступно приобретение выпуска ЦФА'
                                            />
                                            <ul className="m-0 mt-2">
                                                <li className="text-small">Для ЮЛ - Краткое наименование (например, ООО “Цифровое зерно”);</li>
                                                <li className="text-small">Для ИП - проставляется текст “ИП” + ФИО Заявителя (например, “ИП Иванов Иван Иванович”);</li>
                                                <li className="text-small">Для ФЛ - ФИО Заявителя (например, “Иванов Иван Иванович”)</li>
                                            </ul>
                                            <Form.Control.Feedback type="invalid">
                                                Введите корректные данные
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} className="mb-3" controlId="validationCustomersListFIO">
                                            <FormLabel className='required'>ИНН Пользователя</FormLabel>
                                            <Form.Control
                                                type="number"
                                                name="customersListINN"
                                                value={form_Data.customersListINN}
                                                onChange={chngFn}
                                                className='w-100 legal-form-1'
                                                required={showPrivatField ? true : false}
                                                placeholder='ИНН Пользователя, которому будет направлена оферта'
                                                pattern="^[0-9-]+$"
                                                isInvalid={ validated && !/^[0-9-]+$/.test(form_Data.customersListINN)}
                                                onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Control
                                            type="text"
                                            name="customersListLine"
                                            value={customersListLineObject}
                                            className='hidden'
                                            readOnly
                                        />
                                        <div className="listOfOwners">
                                            {groups.map((group, index) => (
                                            <li key={index} className="row mb-3">
                                                <div className="col-5"><input className="w-100" type="text" value={group[0]} readOnly/></div>
                                                <div className="col-4"><input className="w-100" type="text" value={group[1]} readOnly/></div>
                                                <div className="col-3">
                                                    <Button onClick={() => removeGroup(index)} className="w-100 btn-sm mb-3" variant="secondary">Удалить</Button>
                                                </div>
                                            </li>
                                            ))}
                                        </div>
                                        <Button onClick={e => updateCustomerList(e)}>Добавить еще</Button>
                                        <hr className="my-4"/>
                                    </> : <></>}
                                    <Form.Group as={Col} className="mb-3" controlId="validationownerType">
                                        <FormLabel className="required">Вид прав</FormLabel>
                                        <Form.Select
                                            name="ownerType"
                                            className="w-100 legal-form-1"
                                            required
                                            defaultValue={ownerTypeDB || ""}

                                        >
                                            <option value="">Выберите из списка</option>
                                            <option 
                                                value="Денежное требования" 
                                                disabled={ownerTypeDB === "Гибридные цифровые права"}
                                            >
                                                Денежное требования
                                            </option>
                                            <option 
                                                value="Гибридные цифровые права" 
                                                disabled={ownerTypeDB === "Денежное требования"}
                                            >
                                                Гибридные цифровые права
                                            </option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationrrecountCfa">
                                        <FormLabel className='required'>Количество</FormLabel>
                                        <Form.Control
                                            type="number"
                                            name="countCfa"
                                            value={form_Data.countCfa}
                                            min={1}
                                            onChange={chngFn}
                                            pattern="^[0-9-]+$"
                                            className='w-100 legal-form-1'
                                            required
                                            placeholder='Укажите количество выпускаемых ЦФА в единицах'
                                            isInvalid={ validated && !/^[0-9-]+$/.test(form_Data.countCfa)}
                                            onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validatiopriceCfa">
                                        <FormLabel className='required'>Цена ЦФА</FormLabel>
                                        <Form.Control
                                            type="number"
                                            name="priceCfa"
                                            onChange={chngFn}
                                            value={form_Data.priceCfa}
                                            className='w-100 legal-form-1'
                                            required
                                            placeholder='Цена ЦФА'
                                            onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationrrecpriceCompleateCfa">
                                        <FormLabel className='required'>Цена погашения ЦФА</FormLabel>
                                        <Form.Control
                                            type="number"
                                            name="priceCompleateCfa"
                                            value={form_Data.priceCompleateCfa}
                                            onChange={chngFn}
                                            pattern="^[0-9-]+$"
                                            className='w-100 legal-form-1'
                                            required
                                            placeholder='Укажите цену погашения единицы ЦФА'
                                            isInvalid={ validated && !/^[0-9-]+$/.test(form_Data.priceCompleateCfa)}
                                            onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} className="mb-3" controlId="validatiodatedateReleaseDecision">
                                        <FormLabel className='required'>Дата формирования решения о выпуске</FormLabel>
                                        <DatePicker placeholderText="дд.мм.гггг" 
                                        wrapperClassName="w-100" 
                                        className="w-100 legal-form-1 form-control" 
                                        required 
                                        name="dateReleaseDecision"
                                        minDate={new Date()}
                                        dateFormat="dd.MM.yyyy" 
                                        locale={ru}
                                        onChange={setMinStartDate5}
                                        selected={startDate5}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validatiodatedateStartShowCfa">
                                        <FormLabel className='required'>Дата начала размещения ЦФА</FormLabel>
                                        <DatePicker placeholderText="дд.мм.гггг" 
                                        wrapperClassName="w-100" 
                                        className="w-100 legal-form-1 form-control" 
                                        required 
                                        name="dateStartShowCfa" 
                                        minDate={new Date()}
                                        locale={ru} 
                                        selected={startDate} 
                                        onChange={setMinStartDate}
                                        dateFormat="dd.MM.yyyy HH:mm"
                                        showTimeSelect
                                        />
                                    
                                        <Form.Control.Feedback id="aaaa" type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validatiodatedateEndShowCfa">
                                        <FormLabel className='required'>Дата окончания размещения ЦФА</FormLabel>
                                        <DatePicker placeholderText="дд.мм.гггг" 
                                        wrapperClassName="w-100" 
                                        className="w-100 legal-form-1 form-control" 
                                        required 
                                        name="dateEndShowCfa" 
                                        minDate={minDateEndShowCfa} 
                                        dateFormat="dd.MM.yyyy HH:mm" 
                                        locale={ru} 
                                        selected={startDate2} 
                                        onChange={setMinStartDate2} 
                                        showTimeSelect />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validatiodatedateEmissionCfa">
                                        <FormLabel className='required'>Дата выпуска ЦФА</FormLabel>
                                        <DatePicker placeholderText="дд.мм.гггг" wrapperClassName="w-100" className="w-100 legal-form-1 form-control" required name="dateEmissionCfa" minDate={minDateEmissionCfa} dateFormat="dd.MM.yyyy" locale={ru} selected={startDate3} onChange={setMinStartDate3} />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validatiodatedateCompleateCfa">
                                        <FormLabel className='required'>Дата погашения ЦФА</FormLabel>
                                        <DatePicker placeholderText="дд.мм.гггг" wrapperClassName="w-100" className="w-100 legal-form-1 form-control" required name="dateCompleateCfa" minDate={minDateCompleateCfa} dateFormat="dd.MM.yyyy" locale={ru} selected={startDate4} onChange={setMinStartDate4} />
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Control
                                        type="text"
                                        name="dateAdded"
                                        value={new Date().toISOString()}
                                        className='hidden'
                                        readOnly
                                    />
                                    <Button className='mt-3' variant="secondary" id="step2" onClick={NextStep}>Сохранить и продолжить</Button>
                                </Tab>
                                <Tab eventKey="step2" title="Загрузка документации" disabled={disableBtn}>
                                    <p>Для продолжения процесса выпуска ЦФА загрузите необходимые документы:</p>
                                    <Form.Group as={Col} className="mb-3" controlId="validationLicenseReleaseCFA">
                                        <Form.Control 
                                            type="file"
                                            className="hidden"
                                            onChange={uploadHandleChnageReleaseCFA}
                                            accept=".pdf, .jpg, .jpeg, .png, .heic"
                                            required={requiredReleaseCFA}
                                            name="uploadReleaseCfa"
                                            ref={filePickerLicenseReleaseCFA}
                                        />
                                        
                                        <div className="filePicker d-flex align-items-center">
                                            <div className="fileIcon col-2" onClick={handlePickReleaseCFA}><img src={selectFile} alt="Select file"/></div>
                                            <div className="fileLable col-10">
                                                {filenameReleaseCFA}
                                                <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                            </div>
                                            {errorFileSizeReleaseCFA  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Файл обязателен к загрузке
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationLicenseUNEP">
                                        <Form.Control 
                                            type="file"
                                            className="hidden"
                                            onChange={uploadHandleChnageUNEP}
                                            accept=".pdf, .jpg, .jpeg, .png, .heic"
                                            required={requiredReleaseCFA}
                                            name="uploadUNEP"
                                            ref={filePickerLicenseUNEP}
                                        />
                                        
                                        <div className="filePicker d-flex align-items-center">
                                            <div className="fileIcon col-2" onClick={handlePickUNEP}><img src={selectFile} alt="Select file"/></div>
                                            <div className="fileLable col-10">
                                                {filenameUNEP}
                                                <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                            </div>
                                            {errorFileSizeUNEP  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Файл обязателен к загрузке
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationLicenseRisks">
                                        <Form.Control 
                                            type="file"
                                            className="hidden"
                                            onChange={uploadHandleChnageRisks}
                                            accept=".pdf, .jpg, .jpeg, .png, .heic"
                                            required={requiredReleaseCFA}
                                            name="uploadRisks"
                                            ref={filePickerLicenseRisks}
                                        />
                                        
                                        <div className="filePicker d-flex align-items-center">
                                            <div className="fileIcon col-2" onClick={handlePickRisks}><img src={selectFile} alt="Select file"/></div>
                                            <div className="fileLable col-10">
                                                {filenameRisks}
                                                <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                            </div>
                                            {errorFileSizeRisks  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Файл обязателен к загрузке
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationLicenseSignature">
                                        <Form.Control 
                                            type="file"
                                            className="hidden"
                                            onChange={uploadHandleChnageSignature}
                                            accept=".pdf, .jpg, .jpeg, .png, .heic"
                                            required={requiredReleaseCFA}
                                            name="uploadSignature"
                                            ref={filePickerLicenseSignature}
                                        />
                                        
                                        <div className="filePicker d-flex align-items-center">
                                            <div className="fileIcon col-2" onClick={handlePickSignature}><img src={selectFile} alt="Select file"/></div>
                                            <div className="fileLable col-10">
                                                {filenameSignature}
                                                <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                            </div>
                                            {errorFileSizeSignature  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Файл обязателен к загрузке
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationLicenseAdmissionCfa">
                                        <Form.Control 
                                            type="file"
                                            className="hidden"
                                            onChange={uploadHandleChnageAdmissionCfa}
                                            accept=".pdf, .jpg, .jpeg, .png, .heic"
                                            required={requiredReleaseCFA}
                                            name="uploadAdmissionCfa"
                                            ref={filePickerLicenseAdmissionCfa}
                                        />
                                        
                                        <div className="filePicker d-flex align-items-center">
                                            <div className="fileIcon col-2" onClick={handlePickAdmissionCfa}><img src={selectFile} alt="Select file"/></div>
                                            <div className="fileLable col-10">
                                                {filenameAdmissionCfa}
                                                <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                            </div>
                                            {errorFileSizeAdmissionCfa  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Файл обязателен к загрузке
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3" controlId="validationLicenseAdmissionUNEP">
                                        <Form.Control 
                                            type="file"
                                            className="hidden"
                                            onChange={uploadHandleChnageAdmissionUNEP}
                                            accept=".pdf, .jpg, .jpeg, .png, .heic"
                                            required={requiredReleaseCFA}
                                            name="uploadAdmissionUNEP"
                                            ref={filePickerLicenseAdmissionUNEP}
                                        />
                                        
                                        <div className="filePicker d-flex align-items-center">
                                            <div className="fileIcon col-2" onClick={handlePickAdmissionUNEP}><img src={selectFile} alt="Select file"/></div>
                                            <div className="fileLable col-10">
                                                {filenameAdmissionUNEP}
                                                <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                            </div>
                                            {errorFileSizeAdmissionUNEP  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Файл обязателен к загрузке
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3">
                                        <FormLabel>Выписка из решения совета директоров</FormLabel>
                                        <Form.Check
                                            type="radio"
                                            name="extractDirectors"
                                            value="yes"
                                            checked={radioProtector === 'yes' ? true : false}
                                            onChange={e => handleCheckboxChange('yes', 'extractDirectors')}
                                            label="Требуется"
                                            id="isextractDirectorsTrue"
                                            className="radio"
                                        />
                                        <Form.Check
                                            type="radio"
                                            name="extractDirectors"
                                            value="no"
                                            checked={radioProtector === 'yes' ? false : true}
                                            onChange={e => handleCheckboxChange('no', 'extractDirectors')}
                                            label="Не требуется"
                                            id="isextractDirectorsFalse"
                                            className="radio"
                                        />
                                    </Form.Group>
                                    {showOwnerDoc ? <>
                                        <Form.Group as={Col} className="mb-3" controlId="validationLicenseDecisionDirectors">
                                            <Form.Control 
                                                type="file"
                                                className="hidden"
                                                onChange={uploadHandleChnageDecisionDirectors}
                                                accept=".pdf, .jpg, .jpeg, .png, .heic"
                                                required={showOwnerDoc ? true : false}
                                                name="uploadDecisionDirectors"
                                                ref={filePickerLicenseDecisionDirectors}
                                            />
                                            
                                            <div className="filePicker d-flex align-items-center">
                                                <div className="fileIcon col-2" onClick={handlePickDecisionDirectors}><img src={selectFile} alt="Select file"/></div>
                                                <div className="fileLable col-10">
                                                    {filenameDecisionDirectors}
                                                    <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                                </div>
                                                {errorFileSizeDecisionDirectors  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                                            </div>
                                            <Form.Control.Feedback type="invalid">
                                                Файл обязателен к загрузке
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} className="mb-3" controlId="validationLicenseExtractSignature">
                                            <Form.Control 
                                                type="file"
                                                className="hidden"
                                                onChange={uploadHandleChnageExtractSignature}
                                                accept=".pdf, .jpg, .jpeg, .png, .heic"
                                                required={showOwnerDoc ? true : false}
                                                name="uploadExtractSignature"
                                                ref={filePickerLicenseExtractSignature}
                                            />
                                            
                                            <div className="filePicker d-flex align-items-center">
                                                <div className="fileIcon col-2" onClick={handlePickExtractSignature}><img src={selectFile} alt="Select file"/></div>
                                                <div className="fileLable col-10">
                                                    {filenameExtractSignature}
                                                    <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                                </div>
                                                {errorFileSizeExtractSignature  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                                            </div>
                                            <Form.Control.Feedback type="invalid">
                                                Файл обязателен к загрузке
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </> : <></>}
                                    <Form.Group as={Col} className="mb-3" controlId="validationLicenseAdditional">
                                        <Form.Control 
                                            type="file"
                                            className="hidden"
                                            onChange={uploadHandleChnageAdditional}
                                            accept=".pdf, .jpg, .jpeg, .png, .heic"
                                            name="uploadAdditional"
                                            ref={filePickerLicenseAdditional}
                                        />
                                        
                                        <div className="filePicker d-flex align-items-center">
                                            <div className="fileIcon col-2" onClick={handlePickAdditional}><img src={selectFile} alt="Select file"/></div>
                                            <div className="fileLable col-10">
                                                {filenameAdditional}
                                                <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                            </div>
                                            {errorFileSizeAdditional  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Файл обязателен к загрузке
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3 position-relative" controlId="validationReprRegRegion">
                                        <FormLabel>Дополнительные сведения о выпуске</FormLabel>
                                        <Form.Control
                                            as="textarea"
                                            name="moreInfo"
                                            rows={8}
                                            value={form_Data.moreInfo}
                                            onChange={chngFn}
                                            className='w-100 legal-form-1'
                                            placeholder='Укажитe дополнительную информацию о выпуске ЦФА при необходимости'
                                            maxLength={maxLength}
                                        />
                                        <div className="text-counter">{textAreaCount} / {maxLength}</div>
                                        <Form.Control.Feedback type="invalid">
                                            Введите корректные данные
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button className='mt-3 me-3' variant="secondary" onClick={e => saveReleaseCfa(e, false)}>Сохранить</Button>
                                    <Button className='mt-3' variant="primary" onClick={e => saveReleaseCfa(e, true)}>Сохранить и отправить на согласование</Button>
                                </Tab>
                            </Tabs>
                        </Form>
                        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                            <h4>Даные сохранены</h4>
                            <h5>Для перехода на сайт партнера и подписания выпуска ЦФА,закройте данное окно и нажмите кнопку 
                                "Сохранить и отправить на согласование"
                            </h5>
                            <button className='btn btn-primary d-flex align-item-center mt-4' onClick={handleClose}>Закрыть</button>
                            </Modal.Body>
                            <Modal.Footer>
                            <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                            <div className='btn-outline-wrap-green mt-3 mb-4'>
                            <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={showConfirmation} onHide={handleCloseConfirmation} backdrop="static" keyboard={false} >
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                            <p className="congrats-small">Поздравляем!</p>
                            <h4 className="congrats-header">Документация ЦФА успешно загружена и отправлена на проверку</h4>
                            <p className="congrats-text">Статус текущей заявки на выпуск Вы можете отслеживать на вкладке «Выпуск ЦФА»</p>
                            </Modal.Body>
                            <Modal.Footer className="d-block text-center">
                                <Button onClick={redirectToExternal}>Перейти на сайт партнера</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={showError} onHide={handleCloseError} backdrop="static" keyboard={false} >
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                            <h4 className="danger">Ошибка валидации данных</h4>
                            <p className="danger">Пожалуйста проверьте все валидность всех полей в форме, спасибо</p>
                            
                            <button className='btn btn-primary d-flex align-item-center mt-4' onClick={handleCloseError}>Закрыть</button>
                            </Modal.Body>
                            <Modal.Footer>
                            <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                            <div className='btn-outline-wrap-green mt-3 mb-4'>
                            <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                    <Col lg={6} className="ps-0">
                        <div className="dark-bg"></div>
                    </Col>
                </Row>
            </Container>
    </>) : (<></>)}
    </>)
}
 
export default BaseTemplate;