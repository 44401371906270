import React, { FC, useContext, useRef, useState } from "react";
import AccountMenu from "../parts/AccountMenu";
import {Context} from "../../../index";
import { observer } from "mobx-react-lite";
import { Form, Card, CardBody, CardTitle, Container, FormLabel, Col, Button, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModalDefault from "../../modals/ModalDefault";
import modalImage from "../../../img/icons/Info-circle.svg"
import remove from "../../../img/icons/Trash.svg";

import Modal from 'react-bootstrap/Modal';
import envelope from "../../../img/icons/Envelope.svg";
import OrgAccountSubMenu from "../parts/OrgAccountSubMenu";
import ModalGos from "../../modals/ModalGos";

import URL from "../../../config/default.json";
import TypeOfCornSelect from "../../../helpers/TypeOfCornSelect";
export const API_URL = URL.SERVER_URL_BASE;


const SuppliesForm: FC = () => {
    const {store} = useContext(Context);
    const [validated, set_Validated] = useState(false);
    const handleModal = useRef<HTMLAnchorElement>(null);
    const maxLength = 7000;
    // Confirm modal
    const [deleteId, setDeleteId] = useState('');
    const [showDeleteModal, setDeleteModal] = useState(false);
    const handleCloseDeleteModal = () => setDeleteModal(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e: any) => {e.preventDefault(); setShow(true)};

    const [form_Data, set_Form_Data] = useState({
        volumenOfSupplies: '',
        suppliesConditions: '',
    })

    const [selectedValue, setSelectedValue] = useState("");
    const [resetTrigger, setResetTrigger] = useState(false); // Переключатель сброса

    const submitForm = (e: any) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            const typeOfCorn = form.elements.typeOfCorn.value;
            const volumenOfSupplies = form.elements.volumenOfSupplies.value;
            const suppliesConditions = form.elements.suppliesConditions.value;
            const readyToSell = form.elements.readyToSell.checked;
            const dateAdded = form.elements.dateAdded.value;

            store.saveSuppliesInfo(store.user.email, store.user.id, typeOfCorn, volumenOfSupplies, suppliesConditions, readyToSell, dateAdded);
            set_Validated(false);
            set_Form_Data({
                volumenOfSupplies: '',
                suppliesConditions: '',
            });
            if (handleModal.current) {
                handleModal.current.click();
            }
            form_DataChecbox.readyToSell = false;
            setResetTrigger((prev) => !prev)
        } else {
            set_Validated(true);
        }
        
    };

    const removeItem = () => {
        store.removeSuppliesItem(store.user.email, deleteId);
        setDeleteModal(false);
    }

    const removeItemSetData = (id:any) => {
        setDeleteId(id);
        setDeleteModal(true);
    }

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };

    const [form_DataChecbox, set_Form_DataChecbox] = useState({
        readyToSell: false
    });

    const handleCheckboxMoney = (score: any, inputName: string) => {
        set_Form_DataChecbox({
            ...form_DataChecbox,
            [inputName]: score,
        });
    };

    const allItems = {
        zlak1: store.infoTrader.zlak1 || false,
        zlak2: store.infoTrader.zlak2 || false,
        zlak3: store.infoTrader.zlak3 || false,
        zlak4: store.infoTrader.zlak4 || false,
        zlak5: store.infoTrader.zlak5 || false,
        zlak6: store.infoTrader.zlak6 || false,

        zerno1: store.infoTrader.zerno1 || false,
        zerno2: store.infoTrader.zerno2 || false,
        zerno3: store.infoTrader.zerno3 || false,
        zerno4: store.infoTrader.zerno4 || false,
        zerno5: store.infoTrader.zerno5 || false,

        maslo1: store.infoTrader.maslo1 || false,
        maslo2: store.infoTrader.maslo2 || false,
        maslo3: store.infoTrader.maslo3 || false,
        maslo4: store.infoTrader.maslo4 || false,
        maslo5: store.infoTrader.maslo5 || false,
        maslo6: store.infoTrader.maslo6 || false
    };

    const allNames = {
        zlak1: '01.11.11 Пшеница твердая',
        zlak2: '01.11.12 Злаковые культуры',
        zlak3: '01.11.20 Кукуруза',
        zlak4: '01.11.31 Ячмень',
        zlak5: '01.11.32 Рожь',
        zlak6: '01.11.33 Овес',

        zerno1: '01.11.71.110 Зерно фасоли',
        zerno2: '01.11.72.110 Зерно кормовых бобов',
        zerno3: '01.11.73.110 Зерно нута',
        zerno4: '01.11.74.110 Зерно чечевицы',
        zerno5: '01.11.75.110 Зерно гороха',

        maslo1: '01.11.81.120 Бобы соевые для переработки',
        maslo2: '01.11.82 Арахис',
        maslo3: '01.11.91.120 Семена льна для переработки',
        maslo4: '01.11.92.120 Семена горчицы для переработки',
        maslo5: '01.11.94.120 Семена кунжута для переработки',
        maslo6: '01.11.95.120 Семена подсолнечника для переработки'
    };

    return ( <>
     {store.isAuth ? (<>
        <OrgAccountSubMenu class1="organization-link px-4" class2="organization-link px-4" class3="organization-link px-4 active"/>
        <Container className='mb-5'>
            <AccountMenu/>
            <Form noValidate validated={validated} onSubmit={submitForm}>
                <div className="load-harvest-doc my-5 text-center d-flex justify-content-center align-items-baseline">
                    <ModalDefault classButton="text-center" name="Заполнить автоматически через Росреестр" variant="primary"/>
                    <ModalGos classImg="btn btn-link p-2 border-link ms-3" image={modalImage}/>
                </div>
                <CardTitle>Добавить запасы</CardTitle>
                <Card className="mb-3">
                    <CardBody>
                        <TypeOfCornSelect 
                        allItems={allItems}
                        allNames={allNames} 
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        resetTrigger={resetTrigger}
                        />
                        <Form.Group as={Col} className="mb-3" controlId="validationreprvolumen">
                            <FormLabel className='required'>Объем</FormLabel>
                            <Form.Control
                                type="number"
                                step="1"
                                name="volumenOfSupplies"
                                className='w-100 legal-form-1'
                                value={form_Data.volumenOfSupplies}
                                onChange={chngFn}
                                required
                                placeholder='Укажите объем урожая'
                                onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3 position-relative" controlId="validationqualityDescription">
                            <FormLabel  className='required'>Описание качества урожая / сорта</FormLabel>
                            <Form.Control
                                as="textarea"
                                name="suppliesConditions"
                                rows={6}
                                className='w-100 legal-form-1'
                                value={form_Data.suppliesConditions}
                                onChange={chngFn}
                                placeholder='Введите краткое описание урожая / сорта'
                                maxLength={maxLength}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3 position-relative" controlId="validationqualityDescription">
                            <Form.Check
                                type="checkbox"
                                name="readyToSell"
                                label="Готово к реализации"
                                id="readyToSell"
                                className="radio"
                                onChange={e => handleCheckboxMoney(!form_DataChecbox.readyToSell, 'readyToSell')}
                                checked={form_DataChecbox.readyToSell}
                            />
                            <p className="text-small">Выберите данный чек-бокс, если готовы продать зерно через платформу</p>
                        </Form.Group>

                        <Form.Control
                            type="text"
                            name="dateAdded"
                            value={new Date().toISOString().slice(0, 10).split('-').reverse().join('/')}
                            className='hidden'
                            readOnly
                        />
                        <Button className="mt-2" variant="secondary" id="form-submit" type="submit">Сохранить</Button>
                    </CardBody>
                </Card>
                <Link to="/" onClick={handleShow} ref={handleModal}></Link>
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                    <h4>Даные добавлены</h4>
                    <button className='btn btn-primary d-flex align-item-center mt-4' onClick={handleClose}>Закрыть</button>
                    </Modal.Body>
                    <Modal.Footer>
                    <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                    <div className='btn-outline-wrap-green mt-3 mb-4'>
                    <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Form>
            {store.suppliesInfo && store.suppliesInfo.supplies && store.suppliesInfo.supplies.length > 0 ? (<>
                <CardTitle className="mt-5">Добавленные запасы</CardTitle>
                <Card className="mb-3">
                    <CardBody><Row>
                        {store.suppliesInfo.supplies.map(function(data, idx){
                            
                            return (<Col lg={12}><div className="harvest-data" key={idx}>
                                    <div className="harvest-data-top mb-3 d-flex justify-content-between align-items-center">{data.typeOfCorn} <Link to="" onClick={e => removeItemSetData(data._id)}><img src={remove} alt="remove"/></Link></div>
                                    <Row className="harvest-data-bottom">
                                        <Col lg={2}><div className="harvest-data-bottom-item h-100"><span>{data.volumenOfSupplies} л</span><span> объем зерна</span></div></Col>
                                        <Col lg={6}><div className="harvest-data-bottom-item"><span>Качества</span><span>{data.suppliesConditions}</span></div></Col>
                                        <Col lg={2}><div className="harvest-data-bottom-item date-bg h-100"><span>{data.dateAdded}</span><span>дата добавления</span></div></Col>
                                        <Col lg={2}>
                                            <div className={data.readyToSell ? 'd-flex align-items-center h-100 harvest-data-bottom-item success' : 'd-flex align-items-center h-100 harvest-data-bottom-item '}>
                                                <span>{data.readyToSell ? 'Готово к реализации' : 'Не готово к реализации'}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div></Col>)
                        }) }</Row>
                    </CardBody>
                    <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} backdrop="static" keyboard={false} >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                        <h4>Подвердите удаление данных</h4>
                        </Modal.Body>
                        <Modal.Footer>
                        <p className="mb-4">Данные будут удалены и востановлению не подлежат</p>
                        <Row className="w-100">
                            <Col className="ps-0" lg={3}>
                                <button className='btn btn-primary mt-3 mb-4 w-100 text-center' onClick={removeItem}>Да</button>
                            </Col>
                            <Col className="ps-0" lg={3}>
                            <div className='btn-outline-wrap-green mt-3 mb-4 w-100 text-center'> 
                                <button className='btn btn-outline' onClick={handleCloseDeleteModal}>Нет</button>
                            </div>
                            </Col>
                        </Row>
                        </Modal.Footer>
                    </Modal>
                </Card>
            </>) : <></>}
        </Container>
    </>) : <></>}
    </> );
}
 
export default observer(SuppliesForm);