import {FC, useContext, useState} from 'react';
import {observer} from "mobx-react-lite";

import {Context} from "../../index";
import CfaAccountSubMenu from '../account/parts/CfaAccountSubMenu';
import { Alert, Button, CardTitle, Col, Container, Modal, Row } from 'react-bootstrap';
import emitImage from "../../img/icons/emiter-default.svg"
import {useParams } from 'react-router-dom';
import download from "../../img/icons/download.svg";
import lock from "../../img/icons/lock.svg";
import ReleaseCfaService from '../../services/ReleaseCfaService';
import { useQuery } from '@tanstack/react-query';
import SellCFA from './modal/SellCFA';
import URL from "../../config/default.json";
export const env = URL.ENVIRONMENT;

const MyCfaDetail:FC = () => {
    const {store} = useContext(Context);
    const params = useParams();
    let cfaID:any = params.cfaID;
    const cfaItem = store.myCfa?.myCfaInfo;
    let myCfa:any = {}
    if (cfaItem) {
        for (let index = 0; index < cfaItem.length; index++) {
            const element = cfaItem[index];
            if (element._id === cfaID) {
                myCfa = element;
            }
        }
    }

    const getMyCfaList = async () => {
        try {
            const response = await ReleaseCfaService.getDetailCFA(myCfa.emitID, myCfa.cfaID);
            if (response.data && response.data.ticker === undefined) {
                console.log('Response error');
                return false;
            } else {
                return response.data;
            }
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
        }
    }

    const {data} = useQuery({
        queryKey: ['myCfaListDeatil'],
        queryFn: getMyCfaList,
    })

    const cfaItemFromRelease = data;
    const [show, setShow] = useState (false);
    const handleClickModal = () => (setShow(true));
    const handleClose = () => setShow(false);

    return (<>
            {store.isAuth && store.user.isActivated ? (<>
                <CfaAccountSubMenu class1="organization-link px-4 active" class2="organization-link px-4" class3="organization-link px-4"/>
                <Container className='mb-5'>
                    {store.errorMessage !== 'empty_cfa' ? (<>
                        <div className='dashboard-cfa mt-5'>
                            <Row className="cfa-table-header mt-5 me-0 ms-0">
                                <Col lg={4}>Наименование</Col>
                                <Col lg={3}>Эмитент</Col>
                                <Col className="text-center" lg={1}>Количество</Col>
                                <Col className="text-center" lg={1}>Дата погашения</Col>
                                <Col className="text-center" lg={1}>Цена погашения</Col>
                                <Col className="text-center" lg={2}>Сумма</Col>
                                
                            </Row>
                            <Row className="align-items-center cfa-table-body mt-4 me-0 ms-0">
                                <Col className="ps-0" lg={4}>
                                        <div className='cfa-table-title'>
                                            <div className='cfa-table-sub-title'>Digital-Grain_{myCfa.ticker}</div>
                                            <div className='cfa-table-name'>{myCfa.releaseName}</div>
                                        </div>
                                </Col>
                                <Col lg={3} className='align-items-center'><img className="me-3" src={emitImage} alt="Эмитент"/><span>{myCfa.emitName}</span></Col>
                                <Col className="text-center" lg={1}>{myCfa.sendAmount} 
                                    {myCfa.secondarySellAmount ? <>
                                        <img className="ms-2" src={lock} alt="amount"/> <span className='color-gray'>{myCfa.secondarySellAmount}</span>
                                    </> : <></>}
                                </Col>
                                <Col className="text-center" lg={1}>{myCfa.dateOfEmition}</Col>
                                <Col className="text-center" lg={1}>{myCfa.emitPrice} &#8381;</Col>
                                <Col className="text-center cmpleate-price-cfa" lg={2}>{myCfa.totalEmitPrice} &#8381;</Col>
                            </Row>
                        </div>
                        <div className='detail-cfa-info mb-3'>
                            <CardTitle className='mt-5'>Детали выпуска</CardTitle>
                        </div>
                        <div className='d-flex justify-content-between cfa-table-detail'>
                            <div className='col-12 col-lg-8'>
                            <div className='row'>
                                    <div className='col-lg-4 mb-4'>
                                        <div className='cfa-table-title white'>
                                            <div className='cfa-table-name'>{cfaItemFromRelease ? cfaItemFromRelease.countCfaOrigin : ''}</div>
                                            <div className='cfa-table-sub-title'>объем выпуска ЦФА</div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-4'>
                                        <div className='cfa-table-title white'>
                                            <div className='cfa-table-name'>{cfaItemFromRelease ? cfaItemFromRelease.priceCfa : ''} &#8381;</div>
                                            <div className='cfa-table-sub-title'>цена ЦФА при эмиссии</div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-4'>
                                        <div className='cfa-table-title white'>
                                            <div className='cfa-table-name'>{cfaItemFromRelease ? cfaItemFromRelease.ownerType : ''}</div>
                                            <div className='cfa-table-sub-title'>вид прав по ЦФА</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <div className='cfa-table-title grey'>
                                            <div className='cfa-table-name'>{cfaItemFromRelease ? cfaItemFromRelease.dateEmissionCfa : ''}</div>
                                            <div className='cfa-table-sub-title'>дата выпуска ЦФА</div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div className='cfa-table-title grey'>
                                            <div className='cfa-table-name'>{cfaItemFromRelease ? cfaItemFromRelease.dateStartShowCfa : ''} <span className='ms-2'></span></div>
                                            <div className='cfa-table-sub-title'>дата начала размещения ЦФА</div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div className='cfa-table-title grey'>
                                            <div className='cfa-table-name'>{cfaItemFromRelease ? cfaItemFromRelease.dateEndShowCfa : ''} <span className='ms-2'></span></div>
                                            <div className='cfa-table-sub-title'>дата окончания размещения ЦФА</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-4 text-end'>
                                <Button variant="secondary"><img src={download} alt="Скачать"/> Скачать эмиссионную документацию</Button>
                            </div>
                        </div>
                        {env === 'local' ? <>
                            {myCfa.sendAmount === myCfa.secondarySellAmount ? <>
                                {myCfa.secondarySellStatus === 'confirmation' ? <Alert variant='warning' className='mt-5'>Все ЦФА заблокированы на вторичное обращение, ожидают подверждения</Alert> : <></>}
                                {myCfa.secondarySellStatus === 'active' ? <Alert variant='info' className='mt-5'>Все ЦФА заблокированы на вторичное обращение, выставлены на вторичное обращение</Alert> : <></>}
                            </> : <>
                                <Button className="my-3" variant="primary" onClick={handleClickModal}>Продать</Button>
                            </>}
                        </> : <></>}
                    </>) : (<div className='text-center'>
                        <h3 className='my-5'>{store.user.name} {store.user.patronymic}, ЦФА не найден! <br/><br/></h3>
                        <p>Данный ЦФА был удален или погашен. Для подробной информации обратитесь в службу поддержки. Спасибо </p>
                    </div>)}
                    <Modal className='cfa-modal' show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                        <Modal.Body>
                            <SellCFA myCfaID={cfaID} emitID={myCfa?.emitID} cfaID={myCfa?.cfaID} show={show} count={myCfa?.sendAmount} secondarySellAmount={myCfa.secondarySellAmount}/>
                        </Modal.Body>
                    </Modal>
                </Container>
            </>) : (<></>)}
        </>
    );
}
 
export default observer(MyCfaDetail);